<template>
  <div class="header">
    <h1 class="title">{{ title }}</h1>
    <time class="time"><img class="time-icon" src="../../assets/images/park/time.png" />{{ time }}</time>
    <div class="hide-check-label" for="">
       <span>隐藏数据模块</span>
        <span
          :class="['checked-box', { 'is-checked': isChecked }]"
          @click="handleChecked"
        >
        </span>
    </div>
    <div class="notify">
      <img src="../../assets/images/weather/tip@2x.png" class="message-icon" />
      <div class="notify-wrap">
        <div class='notify-txt'>
            {{tip}}
        </div>
      </div>
    </div>
    <div class="close" @click="logout">
      <img src="../../assets/images/close.png" class="close-icon" />
      <div class="logout">退出登录</div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      time: moment().format('YYYY-MM-DD HH:mm:ss'),
    }
  },
  props: ['isChecked', 'title', 'refreshNum', 'tip'],
  mounted() {
    setInterval(() => {
      this.time = moment().format('YYYY-MM-DD HH:mm:ss')
    }, 1000)
  },
  methods: {
    handleChecked() {
      this.$emit("changeChecked", !this.isChecked)
    },
    logout() {
      this.$api.get('/jeecg-boot/sys/logout')
    }
  },
}
</script>
<style lang="scss" scoped>
.header {
  width: 1920px;
  height: 81px;
  position: absolute;
  z-index: 9999;
  background: {
    image: url('../../assets/images/header_bg.png');
    size: contain;
    repeat: no-repeat;
  };
}
.title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 288px;
  font-size: 32px;
  margin-top: 6px;
  letter-spacing: 2px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  background: linear-gradient(0deg, #A7D9FF 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-line {
  height: 149px;
  width: 1920px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: {
    image: url('../../assets/images/park/menu-line.png');
    repeat: no-repeat;
    size: contain;
    position: center;
  }
}

.hide-check-label {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B8D0F5;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  left: 246px;
  top: 10px;
  z-index: 90;
}

.checked-box {
  cursor: pointer;
   display: inline-block;
   width: 20px;
   height: 20px;
   background: rgba(0, 0, 0, 0.3);
   border: 1px solid #b8d0f5;
   border-radius: 3px;
   cursor: pointer;
   margin-left: 7px;
   &.is-checked {
     background: url("../../assets/images/park/gou.png") no-repeat;
     background-size: contain;
     background-position: center;
   }
 }

.time {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #B8D0F5;
  display: inline-flex;
  height: 20px;
  align-items: center;
  position: absolute;
  left: 20px;
  top: 10px;
}
.time-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.notify {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 135px;
  top: -3px;
}

.close {
  position: absolute;
  right: 20px;
  top: 10px;
  line-height: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.close-icon {
  width: 15px;
  height: 15px;
}

.logout {
  color: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-left: 7px;
}
.notify-wrap {
  overflow: hidden;
  display: inline-block;
}

.notify-txt {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFD584;
  display: inline-block;
  white-space: nowrap;
  @keyframes div {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  };
  animation: 10s div linear infinite normal;
}
.message-icon {
  width: 43px;
  height: 44px;
}

.menu-list {
  position: absolute;
  left: 506px;
  top: 20px;
  z-index: 99;
  list-style: none;
  padding: 0;
  width: 1000px;
  margin: 30px auto;
}
.menu-item {
  position: absolute;
  width: 134px;
  height: 134px;
  border-radius: 50%;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFF;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 2px 6px rgba(46, 28, 10, 0.36);
  background: {
    image: url('../../assets/images/home/menu_bg.png');
    size: cover;
    repeat: no-repeat;
  };
  &.router-link-active {
    background-image: url('../../assets/images/home/menu_bg_active.png');
  }
  span {
    width: 60px;
  }
}
.menu-item-two {
  top: 20px;
  left: 16.66%;
}
.menu-item-three {
  top: 30px;
  left: 33.32%;
}
.menu-item-four {
  top: 30px;
  left: 49.98%;
}
.menu-item-five {
  top: 20px;
  left: 66.64%;
}
.menu-item-six {
  left: 83.3%;
}

.content-left {
  display: inline-flex;
  align-items: center;
}


</style>
