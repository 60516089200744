<template>
  <div class="container">
    <div class="card-title" style="font-weight:blod">
      <router-link to="/car-monitor">停车监控</router-link>
    </div>
    <div class="content">
      <div class="progress-wrap">
        <div class="flex progress-item-row">
          <div class="progress-item">
            <div class="rect"></div>
            <div class="txt-content">
              <div class="txt-row">
                车辆总数 <span class="unit">323辆</span>
              </div>
              <div class="progress">
                <div
                  class="progress-inner"
                  style="  background: linear-gradient(90deg, rgba(251, 199, 112, 0.1) 0%, #fbc770 100%); width: 40%"
                ></div>
              </div>
            </div>
          </div>
          <div class="progress-item">
            <div class="rect"></div>
            <div class="txt-content">
              <div class="txt-row">
                饱和率 <span class="unit">63%</span>
              </div>
              <div class="progress">
                <div
                  class="progress-inner"
                  style="background: linear-gradient(90deg, rgba(255, 77, 77, 0.1) 0%, #FF4D4D 100%); width: 80%"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="progress-item">
            <div class="rect"></div>
            <div class="txt-content">
              <div class="txt-row">
                空闲车位 <span class="unit">120辆</span>
              </div>
              <div class="progress">
                <div
                  class="progress-inner"
                  style="background: linear-gradient(90deg, rgba(132, 120, 255, 0.1) 0%, #8478FF 100%); width: 60%"
                ></div>
              </div>
            </div>
          </div>
          <div class="progress-item">
            <div class="rect"></div>
            <div class="txt-content">
              <div class="txt-row">
                空闲率 <span class="unit">37%</span>
              </div>
              <div class="progress">
                <div
                  class="progress-inner"
                  style="background: linear-gradient(90deg, rgba(0, 226, 131, 0.1) 0%, #00E283 100%);width: 70%"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stay-time">
        <div class="time">
          <div class="stay-time-txt">2</div>
          <div class="stay-time-unit">时</div>
        </div>
        <div class="time-tip">平均停车时长</div>
      </div>
    </div>
    <CarEnter class="car-enter" :mData="mData" />
  </div>
</template>

<script>
import CarEnter from "./car-enter";

export default {
  components: {
    CarEnter,
  },
  props: ['mData'],

};
</script>

<style lang="scss" scoped>
.container {
  width: 408px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 549px;
  padding: 40px 10px 36px 10px;
  background: {
    /*image: url("../../assets/images/home/car-monitor.png");*/
    repeat: no-repeat;
    size: cover;
    position: center;
  }
}
.content {
  display: flex;
  height: 110px;
  align-self: flex-start;
  position: relative;
}
.rect {
  position: relative;
  width: 6px;
  height: 30px;
  background-color: #2052a1;
  &:before {
    content: "";
    position: absolute;
    left: -8px;
    top: 0;
    display: block;
    border-style: solid;
    border-width: 15px 4px;
    border-color: transparent #2052a1 transparent transparent;
  }
}
.progress-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 6px;
  padding-top: 14px;
  justify-content: space-around;
}
.progress-item-row {
  margin-bottom: 24px;
}
.progress-item {
  display: flex;
  flex: 1;
  height: 30px;
  padding: 0 10px;
}
.time {
  width: 88px;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  background: {
    image: url("../../assets/images/home/fgdz.png");
    size: cover;
    repeat: no-repat;
    position: center;
  }
}
.time-tip {
  margin-top: -8px;
}
.txt-content {
  margin-left: 4px;
  height: 20px;
  line-height: 20px;
}
.progress {
  position: relative;
  width: 120px;
  height: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #2052a1;
}
.progress-inner {
  position: absolute;
  left: 1px;
  top: 1px;
  height: 6px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.txt-row {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b8d0f5;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.unit {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00deff;
}
.stay-time {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b8d0f5;
}
.stay-time-txt {
  font-size: 20px;
  font-family: Microsoft YaHei;
  color: #ffffff;
}
.stay-time-unit {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.car-enter {
  flex: 1;
}
</style>
