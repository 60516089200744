<template>
  <div class="container">
    <Menu :isChecked="isChecked" title="停车监控" @changeChecked="handleChecked" />
    <Left :isChecked="isChecked" :parkInfo="allDatas.parkInfo" :refreshNum="refreshNum"/>
    <Monitor :isChecked="isChecked" :monitorInfo="allDatas.monitorInfo" :refreshNum="refreshNum" />
    <div :isChecked="isChecked" :class="['right', { 'hide': isChecked }]">
      <Trend :isChecked="isChecked" :refreshNum="refreshNum" />
      <Source :refreshNum="refreshNum" />
    </div>
    <!-- <Parks /> -->
  </div>
</template>

<script>
import moment from "moment";
import Menu from '@/components/park/menu';
import Left from '@/components/park/left';
import Monitor from '@/components/park/monitor';
import Trend from '@/components/park/trend';
import Source from '@/components/park/source';
// import Parks from '@/components/park/parks';

export default {
  components: {
    Menu,
    Trend,
    Left,
    Source,
    Monitor,
    // Parks
  },
  data() {
    return {
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
      isChecked: false,
      refreshNum: 0,
      allDatas: {
        parkInfo: {
          today: 189,
          yesterday: 169,
          month: 36890,
          lastMonth: 36890,
          total: 16.9,
          monthRate: 80,
          carNum: 234,
          carNumRate: 20,
          busNum: 689,
          busNumRate: 30,
          bBusNum: 1368,
          bBusNumRate: 50,
          list: [
            { name: '入场车辆', num: 800, unit: '辆' },
            { name: '出场车辆', num: 378, unit: '辆' },
            { name: '入场速度', num: 35, unit: '辆/秒' },
            { name: '离场车辆', num: 23, unit: '辆/秒' },
          ],
          inNum: 2890,
          lastInNum: 189,
          inType: 'add',
          outNum: 1890,
          lastOutNum: 289,
          outType: 'minus',
        },
        monitorInfo: {
          parks: [
            { name: '芦茨村停车场', remainRate: 68, remain: 368, total: 234, rate: 80, speed: 6.5 },
            { name: '时光码头停车场', remainRate: 68, remain: 368, total: 234, rate: 80, speed: 6.5 },
            { name: '建设中' }
          ],
        }
      }
    };
  },
  mounted() {
    setTimeout(() => {
      // 跳转到停车场
      // window.iframe.contentWindow.postMessage("Main Camera", "SetSence", "view01, -224.1969,-191.7131,-295.022");
    }, 1000)

    // this.getData()
    setInterval(() => {
      this.time = moment().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
    // setInterval(() => {
    //   setTimeout(() => {
    //     this.getData()
    //   }, 0)
    // }, 15000)
  },
  methods: {
    getData() {
      // this.$mockApi.get('/admin/mock/json/anchu_1_park')
      // .then(({ data }) => {
      //   this.allDatas = data
      //   this.refreshNum = this.refreshNum + 1
      // })
    },
    handleChecked() {
      this.isChecked = !this.isChecked;
    }
  },
};
</script>

<style lang="scss" scoped>

 .container {
   width: 1920px;
   height: 100%;
   background: #011326;
   margin: 0 auto;
   padding: 0;
   position: relative;
   /*background: url("../assets/images/park/bg.png") no-repeat;*/
   background-size: contain;
}
.right {
  position: absolute;
  right: 10px;
  top: 70px;
  z-index: 9999;
  @keyframes bounceIn {
    0% {
      right: -400px
    }
    100% {
      right: 10px
    }
  }
  @keyframes bounceOut {
    0% {
      right: 10px
    }
    100% {
      right: -400px
    }
  }
  &.hide {
    animation: bounceOut 1s;
    right: -400px
  }
  animation: bounceIn 1s;
}

</style>
