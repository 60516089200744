<template>
  <div class="passenger_flow bgImg">
    <div class="title">客流量分析</div>
    <div id="topChart" style="height: 200px"/>
    <div class="legend legend_line_left" />
    <div class="legend legend_line_right" />
    <div id="bottomChart" style="height: 200px"/>
    <div class="legend legend_left" />
    <div class="legend legend_right" />
    <!-- <div style="display:none"
      v-for="(item, idx) in bubbleMaleList"
      :key="idx"
      class="item maleItem"
      :style="{
        width: `${item.symbolSize}px`,
        height: `${item.symbolSize}px`,
        top: `${item.value[1] === flagMap[4] ? '168px' : (item.value[1] === flagMap[3] ?  '128px' : (item.value[1] === flagMap[2] ? '138px' : '98px'))}`,
        left: `${(item.value[0] / Math.max(...bubbleMaxList)) * 230 + 90}px`
      }"
    /> -->
    <!-- <div
      v-for="(item, idx) in bubbleFemaleList"
      :key="item.value[0]"
      class="femaleItem"
      :style="{
        width: `${item.symbolSize}px`,
        height: `${item.symbolSize}px`,
        top: `${item.value[1] === flagMap[4] ? '168px' : (item.value[1] === flagMap[3] ?  '128px' : (item.value[1] === flagMap[2] ? '138px' : '98px'))}`,
        left: `${(item.value[0] / Math.max(...bubbleMaxList)) * 230 + 78}px`
      }"
    /> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      chart: null,
      option: {},
      lineChart: null,
      lineOption: {},
      femaleList: [],
      maleList: [],
      maxList: [],
      bubbleList: [],
      bubbleMaleList: [],
      bubbleMaxList: [],
      bubbleFemaleList: [],
      dateMap: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dece: '12月',
      },
      timeFlagMap: {
        1: '2天~5天',
        2: '5天~10天',
        3: '10天~15天',
        4: '15天以上',
        7: '7:00-9:00',
        11: '11:00-3:00',
        18: '18:30-21:30'
      },
      flagMap: {
        1: '7:00-9:00',
        2: '11:00-3:00',
        3: '18:30-21:30',
        4: '其他'
      },
       flagMap1: ['7:00-9:00','11:00-3:00','18:30-21:30','其他'],
       mancount:[],
       womancount:[]
    };
  },
  props: ['isChecked', 'refreshNum'],
  mounted() {
    this.getDataLine();
    window.setInterval(() => {
      setTimeout(() => {
        this.restart()
      }, 0)
    }, 15000)
  },
  watch: {
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
      if (this.lineChart) {
        this.lineChart.clear()
        this.lineChart.setOption(this.lineOption, true)
      }
    },
    getDataLine() {
      this.$trueApi.post('/LuciHomestay/getBookRoomStaticData')
      .then(({ data }) => {
        this.femaleList = Object.keys(data).map(item => [data[+item].womancount, this.timeFlagMap[+item]])
        this.maleList = Object.keys(data).map(item => [data[+item].mancount, this.timeFlagMap[+item]])
        this.drawline();
      })
      const starttime = Date.parse( new Date() ).toString().substr(0,10)
      const endtime = Date.parse( new Date() ).toString().substr(0,10)
      this.$trueApi.post('/LuciHomestay/getCheckInTimeStatice')
      .then(({ data = [] }) => {
        if (data && data.length > 0) {
          this.mancount=data.map((item, index) => [this.flagMap1[index], item.mancount])
          this.womancount=data.map((item, index) => [this.flagMap1[index], item.womancount])
          this.bubbleMaxList = this.bubbleMaxList
          .concat(data.map(item => item.mancount))
          .concat(data.map(item => item.womancount))
          // this.bubbleMaleList = data.map(item => ({
          //   value: [item.mancount, this.flagMap[item.type]],
          //   symbolSize: item.mancount === 0 ? 0 : this.bubbleMaxList.indexOf(item.mancount) * 4 + 14
          // }))
          // this.bubbleFemaleList = data.map(item => ({
          //   value: [item.womancount, this.flagMap[item.type]],
          //   symbolSize: item.womancount === 0 ? 0 : this.bubbleMaxList.indexOf(item.womancount) * 4 + 14
          // }))
          console.log(this.mancount, this.womancount, '==this.mancount=>>>');

          this.drawBubble();
        }
      })
    },
    drawBubble() {
      console.log(this.womancount,this.mancount,'-this.mancount---')
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("topChart"));
      this.option = {
        color:['#fae928','#d3373f'],
        title: {
          text: "各时间段入住统计",
          top: 8,
          textStyle: {
            color: '#83A5E1',
            fontFamily: 'Adobe Heiti Std',
            fontSize: 12,
          },
        },
        // tooltip: {
        //    show: true,
        //  },
        legend: {
           data: ['男', '女'],
           show:true,
           textStyle: {
               color: '#83A5E1',
               fontSize: 13,
               width: 40
           },
           right: 20,
           top: 20,
           icon: 'none',
         },
        grid: {
          bottom: 20,
          left: 0,
          containLabel: true,
        },
        xAxis: [
          {
            boundaryGap: true,
            type:'category',
            interval: 0,
            axisLabel: {
              interval: 0,
              textStyle: {
                color: 'rgba(131, 165, 225, 1)',
                fontFamily: 'MicrosoftYaHei',
                fontWeight: 400,
                fontSize:10
              }
            },
            axisTick: {
              //刻度线样式
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine:{
              show: true,
              lineStyle:{
                  color: 'rgba(55, 93, 158, 1)' //更改坐标轴颜色
              }
            }
          },
        ],
        yAxis: [
          {
             type:'value',
            //type: "category",

            //interval: 6,
            name: "单位（时）",
            nameTextStyle: {
              padding: [0,0,0,50],
              fontSize: 12,
              fontFamily: "Adobe Heiti Std",
              color: "#83A5E1",
            },
            axisLabel: {
              textStyle: {
                color: 'rgba(131, 165, 225, 1)',
                fontFamily: 'MicrosoftYaHei',
                fontWeight: 400
              }
            },
            min: 0,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine:{
              show: true,
              lineStyle:{
                  color: 'rgba(55, 93, 158, 1)' //更改坐标轴颜色
              }
            }
          },
        ],
        series: [
          {

            type: 'bar',
            data:this.mancount,
            name: "男",
            barWidth:20,
            itemStyle: {
              //当前数据的样式
              normal: {
                label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'rgba(131, 165, 225, 1)',
                            fontSize: 10
                        }
                    }
              },
            },
          },
          {

            type: 'bar',
            barWidth:20,
            name: "女",
            data:this.womancount,
            itemStyle: {
              //当前数据的样式
              normal: {
                label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'rgba(131, 165, 225, 1)',
                            fontSize: 10
                        }
                    }
              }
            },
          }
          // {
          //   type: "scatter",
          //   name: "男",
          //   data: this.bubbleMaleList,
          //   itemStyle: {
          //     //当前数据的样式
          //     normal: {
          //       color: "transparent",
          //       // shadowColor: 'rgba(250, 233, 40, 0.5)',
          //       // shadowBlur: 5
          //     },
          //   },
          //   animationDelay: 2 * 1000
          // }, {
          //    type: "scatter",
          //    name: "女",
          //    data: this.bubbleFemaleList,
          //    itemStyle: {
          //      //当前数据的样式
          //      normal: {
          //        color: "transparent",
          //        // shadowColor: 'rgba(255, 54, 54, 0.5)',
          //        // shadowBlur: 5
          //      },
          //    },
          //    animationDelay: 2 * 1000
          //  }
        ],
      }
      this.chart.setOption(this.option)
    },
    drawline() {
      // 基于准备好的dom，初始化echarts实例
      this.lineChart = this.$echarts.init(document.getElementById("bottomChart"));
      this.lineOption = {
         tooltip: {
           show: true,
         },
         legend: {
           data: ['男', '女'],
           textStyle: {
               color: '#83A5E1',
               fontSize: 13,
               width: 40
           },
           right: 35,
           top: 5,
           icon: 'none',
         },
         grid: {
           bottom: 30,
           top: 40,
           left: 15,
           containLabel: true,
         },
         xAxis: {
             boundaryGap: false,
             axisTick: {
               show: false,
             },
             splitLine: {
               show: false,
               lineStyle: {
                 color: 'rgba(56, 89, 130, 0.3)'
               }
             },
             axisLine: {
               show: true,
               lineStyle: {
                 color: 'rgba(56, 89, 130, 0.3)'
               }
             },
             axisLabel: {
               color: 'rgba(131, 165, 225, 1)',
               fontFamily: 'MicrosoftYaHei',
               fontWeight: 400
             },
             min: 0,
             max: Math.max(...this.maxList),
         },
         yAxis: {
             type: 'category',
             name: '单位（天）',
             boundaryGap: false,
             nameTextStyle: {
               padding: [0,0,0,50],
               fontSize: 12,
               fontFamily: "Adobe Heiti Std",
               color: "#83A5E1",
             },
             axisTick: {
               show: false,
             },
             splitLine: {
               show: false
             },
             axisLine: {
               show: true,
               lineStyle: {
                 color: 'rgba(56, 89, 130, 0.3)'
               }
             },
             axisLabel: {
               color: 'rgba(131, 165, 225, 1)',
               fontFamily: 'MicrosoftYaHei',
               fontWeight: 400
             }
         },
         series: [{
             data: this.maleList,
             type: 'line',
             name: '男',
             smooth: true,
             symbol: "circle",
             symbolSize: 10,
             itemStyle: {
               color: 'transparent',
             },
             lineStyle: {
               color: 'rgba(94, 214, 255, 1)'
             },
             areaStyle: {
               color: 'rgba(94, 214, 255, 0.4)'
             },
             animationDelay: 2 * 1000
         },{
             data: this.femaleList,
             type: 'line',
             smooth: true,
             name: '女',
             symbol: "circle",
             symbolSize: 10,
             itemStyle: {
               color: 'transparent',
             },
             lineStyle: {
               color: 'rgba(135, 119, 65, 1)'
             },
             areaStyle: {
               color: 'rgba(135, 119, 65, 0.5)'
             },
             animationDelay: 2 * 1000
         }]
       }
      this.lineChart.setOption(this.lineOption)
     }
   },
};
</script>
<style lang="scss" scoped>

.passenger_flow {
  background: url("../../assets/images/homestay/passenger_flow_bg@2x.png") no-repeat;
  width: 409px;
  height: 522px;
  margin-top: 19px;
  padding: 0 15px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    //font-weight: normal;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 32px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
  }
  .legend {
    position: absolute;
  }
  .legend_left {
    width: 10px;
    height: 2px;
    //border-radius: 50%;
    background: rgba(250, 233, 40, 1);
    top: 64px;
    right: 120px;
  }
  .legend_right {
    width: 10px;
    height: 2px;
    //border-radius: 50%;
    background: rgba(211, 55, 63, 1);
    top: 64px;
    right: 65px;
  }

  .legend_line_left {
    width: 10px;
    height: 2px;
    background: #27CDFF;
    top: 249px;
    right: 130px;
  }
  .legend_line_right {
    width: 10px;
    height: 2px;
    background: #FFF100;
    top: 249px;
    right: 75px;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
  .item {
    border-radius: 50%;
    background: rgba(250, 233, 40, 1);
    box-shadow: 0 0 5px rgba(250, 233, 40, 0.5);
    position: absolute;
    animation: scale 3s linear infinite;
  }
  .femaleItem {
    border-radius: 50%;
    background: rgba(255, 54, 54, 1);
    box-shadow: 0 0 5px rgba(255, 54, 54, 0.5);
    position: absolute;
    animation: scale 3s linear infinite;
  }
}




</style>
