<template>
  <div class="expense">
    <div class="title">三务公开</div>
    <div class="total">
      <span class="total-title">总支出</span>
      <span class="num">
        <countTo ref="num" :startVal='0' :endVal='totalPay' :duration='4000' style="min-width: 85px;display: inline-block;"></countTo>
      </span>
      <span class="unit">元</span>
    </div>
    <div class="legend legend_left" />
    <div class="legend legend_right" />
    <div class="legend legend_middle" />
    <div id="expenseChart" style="height: 80%;margin-left: -15px"></div>
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import toThousands from '../../utils/toThousands'

export default {
  data() {
    return {
      chart: null,
      option: {},
      cunganbuList: [],
      cunwugongList: [],
      cunguanList: [],
      dateMap: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dece: '12月',
      }
    }
  },
  props: ['totalPay', 'isChecked', 'refreshNum'],
  mounted() {
    this.getData();    //执行下面的函数
    setInterval(() => {
      setTimeout(() => {
        this.restart()
      }, 0)
    }, 15000)
  },
  components: {
    countTo
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/village/payChange')
          .then(({ result }) => {
            console.log(result,2222222222)
            this.cunguanList = result[1] || {}
            this.cunwugongList = result[0] || {}
            this.cunganbuList = result[1] || {}
            this.cunwugongList = Object.keys(this.cunwugongList)
                .filter((item, index) => this.dateMap[item])
                .map((item, index) => [this.dateMap[item], this.cunwugongList[item]])
            this.cunganbuList = Object.keys(this.cunganbuList)
                .filter((item, index) => this.dateMap[item])
                .map((item, index) => [this.dateMap[item], this.cunganbuList[item]])
            this.cunguanList = Object.keys(this.cunguanList)
                .filter((item, index) => this.dateMap[item])
                .map((item, index) => [this.dateMap[item], this.cunguanList[item]])
            this.drawLine();
            console.log( this.cunwugongList ,9999)
          })
    },
    restart() {
      this.$refs.num && this.$refs.num.start()
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    formatNum(num) {
      return toThousands(num)
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("expenseChart"));
      this.option = {
        tooltip: {
          show: true
        },
        legend: {
          data: ['村干部', '村官', '务工'],
          textStyle: {
            color: '#83A5E1',
            fontSize: 10,
            width: 40
          },
          icon: 'none',
          right: 15,
          top: 25
        },

        grid:{
          left:60

        },
        xAxis: {
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(56, 89, 130, 0.3)'
            }
          },
          axisLabel: {
            color: 'rgba(131, 165, 225, 1)',
            fontSize: 12,
            fontFamily: 'MicrosoftYaHei',
          },
          type: 'category',
        },
        yAxis: {
          type: 'value',
          name: '单位/元',
          nameTextStyle: {
            padding: [0, 0, 0, 40],
            fontSize: 10,
            fontFamily: 'Microsoft YaHei',
            fontWeight: 400,
            color: 'rgba(131, 165, 225, 1)',
            width: 50
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(56, 89, 130, 0.3)'
            }
          },
          axisLabel: {
            color: 'rgba(131, 165, 225, 1)',
            fontSize: 12,
            fontFamily: 'ArialMT',
          }
        },
        series: [{
          data: this.cunganbuList,
          type: 'bar',
          name: '村干部',
          barWidth: 5,
          itemStyle: {
            barBorderRadius: [3, 3, 0, 0],
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(50, 243, 250, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(47, 224, 233, 0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          animationDelay: 2000
        }, {
          data: this.cunguanList,
          type: 'bar',
          name: '村官',
          animationDelay: 2000,
          barWidth: 5,
          itemStyle: {
            barBorderRadius: [3, 3, 0, 0],
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(244, 120, 11, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(244, 120, 11, 0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }, {
          data: this.cunwugongList,
          type: 'bar',
          name: '务工',
          animationDelay: 2000,
          barWidth: 5,
          itemStyle: {
            barBorderRadius: [3, 3, 0, 0],
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(193, 252, 5, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(193, 252, 5, 0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }]
      };
      this.chart.setOption(this.option)
    }
  }
};
</script>
<style lang="scss" scoped>
.expense {
  margin-right: 10px;
  background: url("../../assets/images/housekeeper/expense_bg@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 356px;
  padding-left: 20px;
  position: relative;
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
    border-radius: 1px;
  }
  .legend_left {
    right: 190px;
    top: 122px;
    background: rgba(50, 243, 250, 1)
  }
  .legend_right {
    right: 120px;
    top: 122px;
    background: rgba(244, 120, 11, 1);
  }
  .legend_middle {
    right: 60px;
    top: 122px;
    background: rgba(193, 252, 5, 1);
  }
  .title {
    font-size: 16px;
    margin-right: 15px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #FFFFFF;
    line-height: 32px;
  }
  .total {
    margin-top: 23px;
    .total-title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #CFDBE9;
    }
    .unit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8995A4;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      margin: 0 8px;
    }
  }
}




</style>
