import axios from 'axios';

let url = process.env.NODE_ENV == 'development' ? '/api': 'https://luci-jsc.zjsszxc.com'

//
const instance = axios.create({
  // baseURL: 'http://mock.duoge.work',
  baseURL: url, 
});

instance.interceptors.request.use(function (config) {
  return {
    ...config,
    headers: {
      'x-access-token': window.localStorage.token
    }
  };
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  if (response.status === 200) {
    return response.data;
  }
  return response;
}, function (error) {
  // window.location.href = '/login'
  // window.localStorage.removeItem('token')
  return Promise.reject(error);
});

export default instance;


