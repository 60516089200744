<template>
  <div class="totalInfo">
    <div class="title">游客进出统计分析</div>
    <div class="top">
      <div class="item">
        <div class="name">今日进客量</div>
        <div class="num" style="color: rgba(50, 243, 250, 1)">
          <countTo
            ref="num"
            :startVal="0"
            :endVal="todayIn"
            :duration="4000"
            style="min-width: 50px"
          ></countTo>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="item">
        <div class="name">今日出客量</div>
        <div class="num">
          <countTo
            ref="numOne"
            :startVal="0"
            :endVal="todayOut"
            :duration="4000"
            style="min-width: 50px"
          ></countTo>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="item">
        <div class="name">人流量</div>
        <div class="num">
          <countTo
            ref="numTwo"
            :startVal="0"
            :endVal="total"
            :duration="4000"
            style="min-width: 20px"
          ></countTo>
          <span class="unit">人/小时</span>
        </div>
      </div>
    </div>
    <div id="inOutChart" style="height: 200px" />
    <!-- <div
      v-for="(item, idx) in list"
      :key="idx"
      class="bubble-item"
      :style="{
        width: `${item.symbolSize}px`,
        height: `${item.symbolSize}px`,
        top: `${tagList.indexOf(item.value[1]) * 32 + 190}px`,
        left: `${nameList.indexOf(item.value[0]) * 69 + 110}px`
      }"
    /> -->
  </div>
</template>
<script>
import countTo from "vue-count-to";
import toThousands from "../../utils/toThousands";

export default {
  data() {
    return {
      chart: null,
      option: {},
      lastyearNum: 0,
      todayIn: 0,
      todayOut: 0,
      total: 0,
      nameList: ["天然浴场", "村委会广场", "芦茨大道", "时光码头"],
      tagList: ["7:30~9:30", "11:30~03:30", "18:30~20:30", "其他"],
      list: [
        { value: ["天然浴场", "7:30~9:30"], symbolSize: 30 },
        { value: ["村委会广场", "11:30~03:30"], symbolSize: 25 },
        { value: ["天然浴场", "18:30~20:30"], symbolSize: 15 },
        { value: ["村委会广场", "7:30~9:30"], symbolSize: 20 },
        { value: ["芦茨大道", "18:30~20:30"], symbolSize: 30 },
        { value: ["芦茨大道", "7:30~9:30"], symbolSize: 26 },
        { value: ["时光码头", "11:30~03:30"], symbolSize: 50 },
        { value: ["时光码头", "其他"], symbolSize: 20 },
      ],
      valueList: [30, 25, 15, 20, 30, 26, 50, 20],
    };
  },
  props: ["isChecked", "refreshNum"],
  mounted() {
    this.getData();
    // this.getDataLine()
    // this.drawLine()
  },
  components: {
    countTo,
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    restart() {
      if (this.isChecked && this.chart) {
        this.$refs.num && this.$refs.num.start();
        this.$refs.numOne && this.$refs.numOne.start();
        this.$refs.numTwo && this.$refs.numTwo.start();
        this.chart.clear();
        this.chart.setOption(this.option, true);
      }
    },
    getData() {
      this.$api.get("/jeecg-boot/passenger/flow/stat").then(({ data }) => {
        const { flowInNum, flowOutNum, humanTraffic, densityDTOS } = data || {};
        this.todayIn = flowInNum;
        this.todayOut = flowOutNum;
        this.total = humanTraffic;
        this.drawLine();
      });
    },
    getDataLine() {},
    formatNum(num) {
      return toThousands(num);
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("inOutChart"));
      this.option = {
        grid: {
          top: 40,
          bottom: 20,
          left: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // legend: {
        //   data: ["Line 1", "Line 2", "Line 3", "Line 4", "Line 5"],
        // },
        toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
        xAxis: [
          {
            data: this.tagList,
            boundaryGap: false,
            //interval: 0,
            // type: "category",
            axisLabel: {
              // formatter: (value, index) => {
              //   return `12/0${index + 1}`;
              // },

              interval: 0,
              textStyle: {
                color: "rgba(131, 165, 225, 1)",
                fontFamily: "MicrosoftYaHei",
                fontWeight: 400,
              },
            },
            axisTick: {
              //刻度线样式
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(55, 93, 158, 1)", //更改坐标轴颜色
              },
            },
          },
        ],
        yAxis: [
          {
            // type: "category",
            type: "value",
            name: "密度",
            nameTextStyle: {
              padding: [0, 0, 0, 50],
              fontSize: 12,
              fontFamily: "Adobe Heiti Std",
              color: "#83A5E1",
            },
            axisLabel: {
              textStyle: {
                color: "rgba(131, 165, 225, 1)",
                fontFamily: "MicrosoftYaHei",
                fontWeight: 400,
              },
            },
            min: 0,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(55, 93, 158, 1)", //更改坐标轴颜色
              },
            },
          },
        ],
        series: [
          { 
            //type: "scatter",
            itemStyle: {
              //当前数据的样式
              normal: {
                //color: "transparent",
                // shadowColor: 'rgba(255, 255, 255, 0.5)',
                // shadowBlur: 5
                label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'rgba(131, 165, 225, 1)',
                            fontSize: 16
                        }
                    }
              },
            },
            //name: "Line 2",
            type: "line",
            stack: "总量",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(0, 221, 255)",
                },
                {
                  offset: 1,
                  color: "rgba(77, 119, 255)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: [120, 282, 111, 234, 220, 340, 310],
            //animationDelay: 2 * 1000
          },
        ],
      };
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.totalInfo {
  padding: 0 10px 0 25px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: "Adobe Heiti Std";
    ///font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 40px;
    width: 106%;
  }
  background: url("../../assets/images/abortion/inOut.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 347px;
  .top {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: left;
    .item {
      min-width: 110px;
    }
    .name {
      font-size: 16px;
      font-family: "Microsoft YaHei";
      font-weight: bold;
      color: #cfdbe9;
      margin-bottom: 5px;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #cfdbe9;
      min-width: 100px;
      display: flex;
      align-items: baseline;
      .unit {
        font-size: 14px;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        color: #b5c0cd;
        margin-left: 5px;
      }
    }
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
  .bubble-item {
    border-radius: 50%;
    background: rgba(0, 234, 255, 1);
    box-shadow: 0 0 5px rgba(0, 234, 255, 0.5);
    position: absolute;
    animation: scale 3s linear infinite;
  }
}
</style>
