<template>
  <div class="inOut">
    <div class="title"> 总体客流量</div>
    <div class="top">
      <div class="item">
        <div class="name" style="color: #fff">今日游客</div>
        <div class="num" style="color: rgba(50, 243, 250, 1)">
          <countTo ref="num" :startVal='0' :endVal='132' :duration='4000' style="min-width: 50px;"></countTo>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="item">
        <div class="name">本月游客</div>
        <div class="num">
          <countTo ref="numOne" :startVal='0' :endVal='2870' :duration='4000' style="min-width: 70px;"></countTo>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="item">
        <div class="name">本年游客</div>
        <div class="num">
          <countTo ref="numTwo" :startVal='0' :endVal='25378' :duration='4000' style="min-width: 90px;"></countTo>
          <span class="unit">人</span>
        </div>
      </div>
    </div>
    <!-- <div class="legend legend_left" />
    <div class="legend legend_right" /> -->
    <div id="twoChart" style="height: 230px" />
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import toThousands from '../../utils/toThousands'

export default {
  data() {
    return {
      chart: null,
      option: {},
      lastyearNum: 0,
      todayNum: 0,
      monthNum: 0,
      yearNum: 0,
      yearList: []
    }
  },
  props: ['homestayInfo', 'isChecked', 'refreshNum'],
  mounted() {
    this.getData()
  },
  components: {
    countTo
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    restart() {
      if (this.isChecked && this.chart) {
        this.$refs.num && this.$refs.num.start()
        this.$refs.numOne && this.$refs.numOne.start()
        this.$refs.numTwo&& this.$refs.numTwo.start()
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$api.get('/jeecg-boot/passenger/flow/summary')
      .then(({ data }) => {
        const { monthlyCount, yearlyCount, dailyCount, list } = data || {}
        this.monthNum = monthlyCount
        this.todayNum = dailyCount
        this.yearNum = yearlyCount
        this.yearList = Object.keys(list).map(time => [time, list[time]])
        this.drawLine();
      })
    },
    formatNum(num) {
      return toThousands(num)
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("twoChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          grid: {
            top: 50,
            bottom: 20,

            x:50
          },
          xAxis: {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(55, 93, 158, 1)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontWeight: 'bold',
                fontSize: 12,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
              splitNumber: 5
          },
          yAxis: {
              type: 'value',
              name: '(人)',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 13,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              min: 0,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(55, 93, 158, 1)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontWeight: 'bold',
                fontFamily: 'ArialMT',
                 interval:0
              }
          },
          series: [{
              data: this.yearList,
              type: 'line',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(50, 183, 233, 1)'
              },
              areaStyle: {
                color: 'rgba(50, 183, 233, 0.2)'
              },
          }]
      }
      this.chart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.inOut {
  padding: 4px 10px 0;
  position: relative;
  .title {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
  }
  background: url("../../assets/images/abortion/total.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 387px;
  .top {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 20px;
    text-align: left;
    .item {
      min-width: 110px;
    }
    .name {
      font-size: 16px;
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      color: #CFDBE9;
      margin-bottom: 5px;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      min-width: 100px;
      display: flex;
      align-items: baseline;
      .unit {
        font-size: 14px;
        font-family: 'Microsoft YaHei';
        font-weight: 400;
        color: #B5C0CD;
        margin-left: 5px;
      }
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
    border-radius: 1px;
  }
  .legend_left {
    right: 150px;
    top: 159px;
    background: rgba(249, 243, 3, 1)
  }
  .legend_right {
    right: 80px;
    top: 159px;
    background: rgba(39, 205, 255, 1);
  }
}


</style>
