import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://luci.anchukeji.com',
});

instance.interceptors.request.use(function (config) {
  return {
    ...config,
    headers: {
      'x-access-token': window.localStorage.token
    }
  };
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  if (response.status === 200) {
    return response.data;
  }
  return response;
}, function (error) {
  // window.location.href = '/login'
  // window.localStorage.removeItem('token')
  return Promise.reject(error);
});

export default instance;
