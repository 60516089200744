<template>
  <div class="vehicleTraffi">
    <div class="title">车辆流量</div>
    <div class="list">
      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="item_top">
          <div class="item_top_left">
            <div class="crossroads">{{item.name}}</div>
            <div class="crossroads_hao crossroads_active" v-if="item.statu==0">畅通</div>
            <div class="crossroads_du crossroads_active" v-if="item.statu==1">拥堵</div>
            <div class="crossroads_sai crossroads_active" v-if="item.statu==2">
              堵塞
            </div>
          </div>
          <div class="item_top_right">
            <div class="item_top_right_num">{{item.num}}</div>
            <div class="item_top_right_text">辆</div>
          </div>
        </div>
        <div
          class="progress_hao progress"
          v-if="item.statu==0"
          :style="{ width: (item.num  / 1200) * 357 + 'px' }"
        ></div>
        <div
          class="progress_du progress"
          v-if="item.statu==1"
          :style="{ width: (item.num  / 1200) * 357 + 'px' }"
        ></div>
        <div
          class="progress_sai progress"
         v-if="item.statu==2"
          :style="{ width: (item.num / 1200) * 357 + 'px' }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return{
            list:[
                {   name:"4号路口",
                    statu:0,
                    num:400,
                },
                {   name:"4号路口",
                    statu:1,
                    num:600,
                },
                {   name:"6号路口",
                    statu:2,
                    num:800,
                },
                {   name:"7号路口",
                    statu:0,
                    num:400,
                },
                {   name:"8号路口",
                    statu:1,
                    num:600,
                }
                
            ]
        }
    }
};
</script>
<style lang="scss" scoped>
.vehicleTraffi {
  width: 408px;
  height: 521px;
  background: url("../../assets/images/monitor/right_1.png") no-repeat;
  background-size: contain;
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    padding-top: 9px;
  }
  .list {
    width: 100%;
    .item {
      padding: 0 25px;
      margin-top: 38px;
      .item_top {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .item_top_left {
          display: flex;
          .crossroads {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #cfdbe9;
            margin-right: 19px;
          }
          .crossroads_active {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
          }
          .crossroads_hao {
            color: #01ed8c;
          }
          .crossroads_du {
            color: #fac670;
          }
          .crossroads_sai {
            color: #ff4e4d;
          }
        }
        .item_top_right {
          display: flex;
          align-items: baseline;
          .item_top_right_num {
            font-size: 26px;
            font-family: Impact;
            font-weight: 400;
            color: #cfdbe9;
            margin-right: 7px;
          }
          .item_top_right_text {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a4afbc;
          }
        }

        
      }
      .progress {
          height: 8px;
          border-radius: 0px 4px 4px 0px;
          margin-top: 14px;
        }
        .progress_hao {
          background: linear-gradient(
            90deg,
            rgba(0, 243, 141, 0) 0%,
            #00f38d 100%
          );
        }
        .progress_du {
          background: linear-gradient(
            90deg,
            rgba(251, 199, 112, 0) 0%,
            #fbc770 100%
          );
        }
        .progress_sai {
          background: linear-gradient(
            90deg,
            rgba(255, 77, 77, 0) 0%,
            #ff4d4d 100%
          );
        }
    }
  }
}
</style>