<template>
  <div class="garbage">
    <div class="title">垃圾处理</div>
    <div class="top">
      <div class="item">
        <div class="name">总垃圾</div>
        <div class="num">{{ totalRubbish }}<span class="unit">kg</span></div>
      </div>
      <div class="item">
        <div class="name">垃圾处理</div>
        <div class="num">{{ disposeRubbish }}<span class="unit">kg</span></div>
      </div>
      <div class="item">
        <div class="name">投放合格</div>
        <div class="num" style="color: rgba(50, 243, 250, 1)">{{ rightPercent }}<span class="unit">%</span></div>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, idx) in list" :key="item.rank">
        <div class="rank rank_num" :style="{'background': idx > 2 ? '#1C4997' : 'none'}">
          <img v-if="idx === 0" src="../../assets/images/homestay/rank_one@2x.png" />
          <img v-if="idx === 1" src="../../assets/images/homestay/rank_two@2x.png" />
          <img v-if="idx === 2" src="../../assets/images/homestay/rank_three@2x.png" />
          <span v-if="idx > 2">{{ idx + 1 }}</span>
        </div>
        <div class="name">{{ item.name }}</div>
        <div class="score"><span style="margin-right: 4px">评分</span>{{ item.totalScore }}</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['totalRubbish', 'disposeRubbish', 'rightPercent', 'refreshNum'],
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getData();
    setInterval(() => {
      setTimeout(() => {
        this.list = this.list.concat([this.list[0]])
        this.list.shift()
      })
    }, 10000)
  },
  watch: {
    refreshNum: function () {
      if (this.refreshNum > 0) this.getData();
    },
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/village/rubbish')
      .then(({ result }) => {
        this.list = result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.garbage {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #FFFFFF;
    line-height: 32px;
  }
  background: url("../../assets/images/housekeeper/garbage@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 603px;
  margin-top: 12px;
  .top {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    .name {
      font-size: 16px;
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      color: #CFDBE9;
      margin-bottom: 5px;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      .unit {
        font-size: 14px;
        font-family: 'Microsoft YaHei';
        font-weight: 400;
        color: #B5C0CD;
        margin-left: 5px;
      }
    }
  }
  .list {
    margin-top: 13px;
    .item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #B8D0F5;
      line-height: 28px;
      width: 368px;
      height: 30px;
      margin: 0 auto;
      margin-bottom: 15px;
      background: rgba(12, 38, 82, 0.6);
      border: 1px solid #1E4586;
      display: flex;
      justify-content: space-around;
      .description {
        min-width: 200px;
      }
      .rank_num {
        font-weight: bold;
        width: 18px;
        height: 18px;
        line-height: 18px;
        background: #1C4997;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #B8D0F5;
        margin-top: 4px;
      }
      img {
        width: 17px;
        height: 22px;
      }
      .item {
        text-align: center;
      }
    }
  }
}


</style>
