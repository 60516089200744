<template>
  <div class="weather">
    <div class="card-title" style="top: 8px;font-weight: bold;"><router-link to="/weather">天气实况</router-link></div>
    <div class="content">
      <div class="weather-bg">
        <img class="weather-icon" :src="list[0].img"  />
        <div class="weather-bg-inner"></div>
      </div>
      <div class="w-content">
        <div class="w-num-wrap">
          <span class="w-date">今天</span>
          <div class="temp-txt-wrap">
            <span class="w-txt">{{ temp }}</span>
            <span class="w-unit">℃</span>
          </div>
        </div>
        <div class="w-line"></div>
        <div class="w-info">
          <div style="word-break: keep-all;">{{ list[0].description }}{{ list[0].tempMin }}~{{ list[0].tempMax }}℃</div>
          <div>{{ list[0].windDirDay }}{{ list[0].windScaleDay }}级</div>
        </div>
      </div>
      <div class="w-a">
        <div class="w-a-txt">明天</div>
        <div class="w-a-icon"><img :src="list[1].img" /></div>
        <div class="w-a-rain">{{ list[1].description }}</div>
        <div class="w-a-temp">{{ list[1].tempMin }}~{{ list[1].tempMax }}℃</div>
      </div>
      <div class="w-a">
        <div class="w-a-txt">后天</div>
        <div class="w-a-icon"><img :src="list[2].img" /></div>
        <div class="w-a-rain">{{ list[2].description }}</div>
        <div class="w-a-temp">{{ list[2].tempMin }}~{{ list[2].tempMax }}℃</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{}, {}, {}],
      temp: '',
    }
  },
  mounted() {
    this.getData()
    this.getMoreData()
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/weather/weather3d')
      .then(({ daily = [] }) => {
        this.list = daily.map(item => {
          const month = new Date(item.fxDate).getMonth() + 1
          const date = new Date(item.fxDate).getDate()
          const fullDate = `${month}月${date}日`
          return ({
            date: fullDate,
            description: item.textDay,
            tempMin: item.tempMin,
            tempMax: item.tempMax,
            windDirDay: item.windDirDay,
            windScaleDay: item.windScaleDay,
            img: require(`../../assets/images/color-128/${item.iconDay}.png`)
          })
        })
      })
    },
    getMoreData() {
      this.$api.get('/jeecg-boot/weather/weatherNow')
      .then(({ now = {} }) => {
        this.temp = now.temp
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.weather {
  position: relative;
  width: 411px;
  height: 170px;
  display: flex;
  align-items: center;
  padding: 15px;
  background: {
    image: url('../../assets/images/home/weather-border.png');
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}
.content {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}
.weather-bg {
  position: relative;
  width: 90px;
  height: 90px;
  background: rgba(15, 38, 41, 0.12);
  border: 2px solid rgba(0, 222, 255, 0.12);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.weather-bg-inner {
  width: 75px;
  height: 75px;
  background: rgba(0, 222, 255, 0.08);
  border: 2px solid #00DEFF;
  box-shadow: 0px 0px 29px 0px rgba(0, 222, 255, 0.35);
  border-radius: 50%;
  margin-bottom: 2px;
}
@keyframes fade {
  0% {
    opacity: 0.6;
    left: 25px;
  }
  50% {
    opacity: 1;
    left: 15px;
  }
  100% {
    left: 25px;
    opacity: 0.6;
  }
}
.weather-icon {
  position: absolute;
  left: 20px;
  top: 22px;
  width: 45px;
}
.w-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 84px;
  margin: 0 24px 0 14px;
}
.w-line {
  width: 100%;
  height: 1px;
  background-color: #00DEFF;
  margin: 2px 0;
}

.w-num-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: #00DEFF;
  .w-date {
    width: 32px;
    height: 18px;
    line-height: 18px;
    background: #00DEFF;
    border-radius: 3px;
    font-size: 13px;
    color: #073432;
    margin-right: 8px;
  }
  .temp-txt-wrap {
    display: flex;
    align-items: center;
  }
  .w-txt {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
  }
  .w-unit {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00DEFF;
  }
}
.w-info {
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B8D0F5;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.w-a {
  width: 76px;
  height: 108px;
  background: rgba(24, 110, 183, 0.3);
  margin-left: 10px;
  padding: 10px;
}
.w-a-txt {
  color: #B8D0F5;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B8D0F5;
  line-height: 17px;
}
@keyframes ani {
   0%{
        box-shadow: 0 0 0px #fff;
        border-color: transparent;
   }
    50%{
        box-shadow: 0 0 10px #fff;
        border-color: transparent;
   }
   100%{
        box-shadow: 0 0 0px #fff;
        border-color: transparent;
   }
}
.w-a-icon {
  width: 37px;
  height: 34px;
  margin: 4px auto;
  line-height: 34px;
  position: relative;
  .quanOne {
    position: absolute;
    width: 25px;
    height: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 19px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 6px;
    top: 9px;
    left: 6px;
    border-color: transparent;
    animation: ani 6s linear infinite;
  }
  .quan {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 7px;
    left: 9px;
    border-color: transparent;
    animation: ani 6s linear infinite;
  }
  img {
    width: 37px;
    height: 34px;
  }
}
.w-a-temp,
.w-a-rain {
  color: #91B3ED;
  font-size: 13px;
}
</style>
