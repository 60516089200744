<template>
  <div class="anion">
    <div class="title">负氧离子趋势</div>
    <div id="anionChart" style="height: 270px"></div>
  </div>
</template>
<script>
import getDate from "../../utils/getDate"

export default {
  data() {
    return {
      chart: null,
      option: {},
      list: []
    }
  },
  props: ['isChecked', 'refreshNum'],
  mounted() {
    this.getData();    //执行下面的函数
  },
  watch: {
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    }
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$api.get('/jeecg-boot/weather/history7dPm2p5')
      .then(({ airHourly = [] }) => {
        this.list = airHourly
          .sort((a, b) => new Date(a.pubTime).getTime() - new Date(b.pubTime).getTime())
          .map((item, index) => [getDate(item.pubTime), item.co])
          this.drawLine();    //执行下面的函数
      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("anionChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          xAxis: {
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.5)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
          },
          yAxis: {
              type: 'value',
              name: '单位：个/cm³',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 10,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.list,
              type: 'line',
              smooth: true,
              symbol:'circle',
              symbolSize: 10,
              itemStyle: {
                color: 'transparent'
              },
              lineStyle: {
                width: 1,
                color: 'rgba(255, 241, 0, 1)',
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(255, 241, 0, 0.6)' // 0% 处的颜色
                    }, {
                        offset: 0.7, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              },
              // animationDelay: 2000
          }]
      };
      this.chart.setOption(this.option)
    }
  }
};
</script>
<style lang="scss" scoped>
.anion {
  margin-right: 10px;
  background: url("../../assets/images/weather/anion_bg@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 314px;
  position: relative;
  padding-left: 10px;
  margin-top: 12px;
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
    border-radius: 1px;
  }
  .legend_left {
    right: 120px;
    top: 68px;
    background: rgba(249, 243, 3, 1)
  }
  .legend_right {
    right: 60px;
    top: 68px;
    background: rgba(39, 205, 255, 1);
  }
  .title {
    font-size: 16px;
    margin-right: 15px;
    font-family: 'Adobe Heiti Std';
   // font-weight: normal;
   font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 32px;
  }
  .total {
     margin-top: 23px;
    .total-title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #CFDBE9;
    }
    .unit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8995A4;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      margin: 0 8px;
    }
  }
}
</style>
