<template>
  <div class="construction">
    <div class="title">工程建设</div>
    <div class="content">
      <div class="item" v-for="(item, idx) in list" :key="idx">
        <div class="name">{{ item.name }}</div>
        <div class="item_conten">
          <div
            class="item_text"
            :class="[
              listActive[idx] == idx ? ' item_text_false' : 'item_text_true',
            ]"
          >
            项目位于芦茨村溪对岸，项目总用地面积约28000平方米，在保持地块现状的前提下，通过优化功能布局、景观建设等手段进行艺术化改造，包括巨石大地艺术装置、“两山”理论艺术装置、临溪临崖景观、田浪艺术菜园、阳光草坪、游步道、曲艺台等约20余项内容。
            白云源道路扩建提升改造：
            芦梅线全长约8.4公里，全线铺设沥青路面，画设交通标示线及绿道彩虹标示线，并对沿线停车场、排水沟、边坡进行整治
          </div>
          <div class="item_more" v-if="listActive[idx]==-1" @click="moreOn(idx)">
            查看更多
          </div>
        </div>
        <div class="top">
          <div class="top_text">工程进度</div>
          <div class="rate">{{ item.plan }}<span class="unit">%</span></div>
        </div>
        <div class="process">
          <div
            :class="['process_bar', { process_bar_active: active }]"
            :style="{ width: `${item.plan}%` }"
          />
        </div>
        <div class="bottom">
          <div class="time-left">{{ item.dateStart }}</div>
          <div class="time-right">{{ item.dateEnd }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["refreshNum"],
  mounted() {
    this.getData();
  },
  data() {
    return {
      list: [],
      listActive:[-1,-1],
      active: false,

    };
  },
  watch: {
    refreshNum: function () {
      if (this.refreshNum > 0) {
        this.getData();
        this.active = true;
        setTimeout(() => {
          this.active = false;
        }, 6000);
      }
    },
  },
  methods: {
    getData() {
      this.$api.get("/jeecg-boot/village/build").then(({ result }) => {
        this.list = result;

      });
    },
    moreOn(index) {
      this.listActive[index]=index
    },
  },
};
</script>
<style lang="scss" scoped>
.construction {
  //padding: 0 10px;
  // margin-left: 14px;
  position: relative;
  .content {
    height: 100%;
    display: flex;
  }
  .title {
    font-size: 16px;
    font-family: "Adobe Heiti Std";
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #ffffff;
    padding-top: 9px;
  }
  width: 1023px;
  height: 271px;
  background: url("../../assets/images/housekeeper/engineering.png") no-repeat;
  background-size: contain;
  .item {
    padding: 0 33px;

    .top,
    .bottom {
      display: flex;
      justify-content: space-between;
    }
    .name {
      font-size: 16px;
      font-family: "Microsoft YaHei";
      font-weight: bold;
      color: #cfdbe9;
      text-align: left;
      padding-top: 20px;
      margin-bottom: 8px;
    }
    .item_conten {
      width: 434px;
      height: 59px;
      margin-bottom: 35px;
      position: relative;
      .item_text {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #aebed2;
        text-align: left;
      }
      .item_text_true {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 60px;
      }
      .item_text_false {
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-y: scroll;
        height: 95px;
      }
      .item_text_false::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .item_more {
        position: absolute;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #f4c120;
        right: 0px;
        bottom: -20px;
        cursor: pointer;
      }
    }
    .top {
      align-items: baseline;
      .top_text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #cfdbe9;
      }
      .rate {
        font-size: 26px;
        font-family: "Impact";
        font-weight: 400;
        color: #cfdbe9;
        .unit {
          font-size: 14px;
          font-family: "Microsoft YaHei";
          font-weight: 400;
          color: #b5c0cd;
          padding-left: 5px;
        }
      }
    }
    .process {
      width: 414px;
      height: 4px;
      background: #1e4374;
      border-radius: 2px;
      margin: 8px 0 16px 0;
      @keyframes fadeIn {
        0% {
          width: 0;
        }
      }
      .process_bar {
        height: 4px;
        background: linear-gradient(
          90deg,
          rgba(193, 252, 5, 0) 0%,
          #c1fc05 100%
        );
        border-radius: 2px;
        &.process_bar_active {
          animation: fadeIn 4s linear;
        }
      }
    }
    .time-left,
    .time-right {
      font-size: 16px;
      font-family: "Microsoft YaHei";
      font-weight: 400;
      color: #8895a5;
    }
  }
}
</style>
