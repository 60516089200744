import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    videoData: {
      carFlow: [],
      busyRoad: [],
      device: [],
      flowStatistics: {
        charts: {
          xData: [],
          yData: []
        }
      }
    },
  },
  mutations: {
    setVideoData(state, data) {
      console.log(data);
      state.videoData = data;
    },
  },
  actions: {
    setVideoData(context, data) {
      context.commit("setVideoData", data);
    },
  },
});

