<template>
  <div class="bubble" :style="{'width': width, 'padding-left': paddingLeft, 'padding-right': paddingRight}">
      <div id="bubbleChart" style="height: 100%"/>
      <!-- <div
        v-for="(item, idx) in list"
        :key="idx"
        class="item"
        :style="{
          width: `${item.symbolSize}px`,
          height: `${item.symbolSize}px`,
          top: getTop(item.value[1]),
          left: `${item.left}px`
        }"
      /> -->
  </div>
</template>
<script>
export default {
  props: ['width', 'paddingLeft', 'paddingRight', 'refreshNum'],
  mounted() {
    this.getData()
  },
  data() {
    return {
      chart: null,
      option: {},
      list: [],
      list1:[],
      xList: [],
      yList: [],
      maxList: []
    }
  },
  watch: {
    refreshNum: function () {
      this.restart();
    },
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getTop(data) {
      if (data === this.yList[0]) {
        return '185px'
      }
      if (data === this.yList[1]) {
        return '90px'
      }
      if (data === this.yList[2]) {
        return '160px'
      }
      if (data === this.yList[3]) {
        return '120px'
      }
      if (data === this.yList[4]) {
        return '70px'
      }
    },
    getData() {
      this.$api.get('/jeecg-boot/carFlow/getInOrCome')
      .then(({ list = [] }) => {
        this.maxList = list.map(item => item.cars)
        this.xList = list.map(item => item.cars).sort((a, b) => a - b)
        this.yList = list.map(item => item.period)

        list.forEach((item) => {
          this.list = this.list.concat([
            {
              value: [item.cars, item.period],
              symbolSize: this.maxList.indexOf(item.cars) * 4 + 14,
              left: this.xList.indexOf(item.cars) * 55 + 60,
            }
          ])
        })
       // console.log(this.list,this.xList,this.maxList)
        this.drawLine()
      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bubbleChart"));
      this.option = ({
        color:['rgba(38, 231, 138, 1)'],
        title: {
          text: "各时间段出入统计",
          textStyle: {
            color: '#83A5E1',
            fontFamily: 'MicrosoftYaHei',
            fontSize: 12,
            fontWeight: 400
          },
          top: 20
        },
        grid: {
          left: 0,
          bottom: 10,
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: this.yList,
            axisLabel: {
              // formatter: (value, index) => {
              //   return `12/0${index + 1}`;
              // },
              show: true,
              textStyle: {
                color: 'rgba(131, 165, 225, 1)',
                fontFamily: 'MicrosoftYaHei',
                fontWeight: 400
              }
            },
            axisTick: {
              //刻度线样式
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine:{
              lineStyle:{
                  color: 'rgba(55, 93, 158, 1)' //更改坐标轴颜色
              }
            }
          },
        ],
        yAxis: [
          {
            //type: "category",
            type: 'value',
            // type: 'value',
            // interval: 6,
            // data: this.yList,
            axisLabel: {
              //formatter: (value, index) => this.yList[index],
              textStyle: {
                color: 'rgba(131, 165, 225, 1)',
                fontFamily: 'MicrosoftYaHei',
                fontWeight: 400
              },
               //show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine:{
              show: true,
              lineStyle:{
                  color: 'rgba(55, 93, 158, 1)' //更改坐标轴颜色
              }
            }
          },
        ],
        series: [
          {
            // type: "scatter",
            type: 'bar',
            //data: this.list,
            data:this.maxList,
             barWidth:'55%',
            itemStyle: {
              //当前数据的样式
              normal: {
               color: new this.$echarts.graphic.LinearGradient(
                        //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。
                        //通过修改前4个参数，可以实现不同的渐变方向
                        /*第五个参数则是一个数组，用于配置颜色的渐变过程。
                          每项为一个对象，包含offset和color两个参数
                        */
                        0, 0, 0, 1, [{//代表渐变色从正上方开始
                                offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                                color: '#005BEA'
                            }, //柱图渐变色
                            {
                                offset: 1, //指100%处的颜色
                                color: '#00C6FB'
                            }
                        ]
                    ),

                // shadowColor: 'rgba(255, 255, 255, 0.5)',
                // shadowBlur: 5
                 barBorderRadius: [4, 4, 0, 0], //柱体圆角
                label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'rgba(131, 165, 225, 1)',
                            fontSize: 16
                        }
                }
              },
            },
          }
        ],
      })
      this.chart.setOption(this.option)
    }
  }

}
</script>
<style lang="scss" scoped>
  .bubble {
      height: 60%;
      width: 100%;
      padding-left: 15px;
      padding-right: 10px;
      position: relative;
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.7);
        }
        100% {
          transform: scale(1);
        }
      }
      .item {
        border-radius: 50%;
        background: rgba(38, 231, 138, 1);
        box-shadow: 0 0 5px rgba(38, 231, 138, 0.5);
        position: absolute;
        animation: scale 3s linear infinite;
      }
  }

</style>
