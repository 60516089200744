<template>
  <div class="container">
    <head-top
        :isChecked="!dataVisible"
        title="芦茨数字驾驶舱"
        @changeChecked="dataVisibleChange"
        tip="芦茨村夜市即将开市欢迎人来购物。"
    />
    <div class="main-content">
      <!-- <div class="mark" /> -->
      <!-- <iframe class="main-bg" ref="iframe" src="https://demo.vrcms.cn/vr/MzAyMjkwN2ItYmZhNC05ZjU2LTc4YmEtZjZiYWQ3YWJkNmZk.html" /> -->
      <div :class="['column-one', !dataVisible && 'column-one-hide']">
        <HomeStay
            :homestayInfo="homestayInfo"
            :isChecked="dataVisible"
            :refreshNum="refreshNum"
        />
        <Statistics :isChecked="dataVisible" :refreshNum="refreshNum"/>
        <Park
            :parkInfo="parkInfo"
            :isChecked="dataVisible"
            :refreshNum="refreshNum"
        />
      </div>
      <!-- <div :class="['column-two', !dataVisible && 'column-two-hide']">
          <SafeMonitor v-if="showSafeMonitor" :mList="safeMonitorList"/>
      </div> -->
      <div class="bgs" v-if="dataVisible">
        <p class="title">
          <router-link to="/monitor">热点区域监控</router-link>
        </p>
        <div id="playWnd" class="playWnd" style="display: flex; overflow: hidden;">
          
          <div id="player" style="width:100%; height:434px"></div>
          <!-- <video id="videoElement"  controls autoplay muted width="755px" height="216px">
          </video> -->
        </div>
      </div>

      <div :class="['column-three', !dataVisible && 'column-three-hide']">
        <Weather :weatherData="weatherData" :refreshNum="refreshNum"/>
        <Housekeeper
            :housekeeperInfo="housekeeperInfo"
            :isChecked="dataVisible"
            :refreshNum="refreshNum"
        />
        <AdVideo
            :adVideo="adVideo"
            :hideSafeMonitor="hideSafeMonitor"
            :handlePlay="handlePlay"
        />
      </div>
    </div>
    <CenterVideo v-if="visible" :close="close" @closeVideo="closeVideoFn" @playVideo="playVideoFn"/>
    
    <!-- <div class="global-shadow"></div> -->
  </div>
</template>

<script>
var oWebControl = null; // 插件对象
var bIE = !!window.ActiveXObject || "ActiveXObject" in window; // 是否为IE浏览器
var pubKey = "";
var initCount = 0;
var width = 755;
var height = 216;

var times = null;
import flvjs from "flv.js";
import headTop from "@/components/park/menu";
import Weather from "@/components/home/weather";
import HomeStay from "@/components/home/home-stay";
import CarMonitor from "@/components/home/car-monitor";
import CarEnter from "@/components/home/car-enter";
import SafeMonitor from "@/components/home/safe-monitor.vue";
import AdVideo from "@/components/home/ad-video.vue";
import CenterVideo from "@/components/home/center-video.vue";
import PublicGovernance from "@/components/home/public-governance.vue";
import Housekeeper from "@/components/home/housekeeper.vue";
import Statistics from "@/components/home/statistics.vue";
import Park from "@/components/home/park.vue";

export default {
  name: "Home",
  components: {
    headTop,
    Weather,
    HomeStay,
    Housekeeper,
    Statistics,
    Park,
    CarMonitor,
    CarEnter,
    SafeMonitor,
    AdVideo,
    CenterVideo,
    PublicGovernance,
  },
  data() {
    return {
      monitorList: [
        "8b2b4b63f19c4ffdaac2b6a23f4b45d4",
        "c4a933c5e68f42619974471e68fd28fa",
      ],
      dataVisible: true,
      showSafeMonitor: true,
      visible: false,
      refreshNum: 0,
      weatherData: {},
      safeMonitorList: [],
      adVideo: "",
      statisticsInfo: {},
      homestayInfo: {list: []},
      housekeeperInfo: {},
      parkInfo: {},

      player: null,
    };
  },
  beforeRouteLeave(to, form, next) {
    
    next();
  },
  mounted() {
    this.createPlayer()
    this.$page.index.isOpen = true;
    times = setInterval(() => {
      if (window.iframe && window.iframe.contentWindow.isLoading) {
        console.log(window.iframe);
        window.iframe.contentWindow.unityInstance.SendMessage(
            "Main Camera",
            "SetSence",
            "view04, -190.8724,-301.927,-214.5015"
        );
        
        window.iframe.contentWindow.$(".Tip").fadeOut(200);
        clearInterval(times);
      }
    }, 1000);

    //this.initPlugin();
    //this.init()


    // this.initVideo("videoDiv01", 380, 216, "http://luci-ng.zjsszxc.com/live/c10.m3u8");
    // this.initVideo("videoDiv02", 380, 216, "http://luci-ng.zjsszxc.com/live/c11.m3u8");

    // if (flvjs.isSupported()) {
    //       var videoElement = document.getElementById('videoElement');
    //       this.flvPlayer = flvjs.createPlayer({
    //       type: 'flv',
    //       isLive: true,
    //       hasAudio: false,
    //         url: 'http://61.160.212.33:9503/live/livestream.m3u8'
    //       });
    //       this.flvPlayer.attachMediaElement(videoElement);
    //       this.flvPlayer.load();
    //   	  this.flvPlayer.play();
    // }

    
  },
  destroyed(){
    if(this.player != null)  this.player.JS_Stop();
    this.player.JS_Destroy();
	},
  methods: {
    initPlayer() {
        // 设置播放容器的宽高并监听窗口大小变化
        // window.addEventListener('resize', () => {
        //   this.player.JS_Resize()
        // })
    },
    async createPlayer() {

      this.player = new window.JSPlugin({
        szId: 'player',
        szBasePath: "./",
        iMaxSplit: 4,
        iCurrentSplit: 2,
        openDebug: true,
        oStyle: {
          borderSelect: '#FFCC00',
        }
      });
      
      let res01 = await this.$getReq(1000, '434e4e1df77047638e923c2a8dff0700');
      let res02 = await this.$getReq(0, '928b29625c3c47fba7e563ffb356255f');
      if(res01.code != 200) return;
      if(res02.code != 200) return;
      console.log(res01);
      console.log(res02);
      console.log("--------------------");
      console.log(res01.data.data.url);
      console.log(res02.data.data.url);
      console.log("--------------------");
      this.player.JS_Play(res01.data.data.url, { playURL:res01.data.data.url, mode:1 }, 0);
      this.player.JS_Play(res02.data.data.url, { playURL:res02.data.data.url, mode:1 }, 1);


      
      
      // 事件回调绑定
      this.player.JS_SetWindowControlCallback({
        windowEventSelect: async (iWndIndex) => {  //插件选中窗口回调
            console.log('windowSelect callback: ', iWndIndex);

            
        },
        pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {  //插件错误回调
            console.log('pluginError callback: ', iWndIndex, iErrorCode, oError);
        },
        windowEventOver: function (iWndIndex) {  //鼠标移过回调
            //console.log(iWndIndex);
        },
        windowEventOut: function (iWndIndex) {  //鼠标移出回调
            //console.log(iWndIndex);
        },
        windowEventUp: function (iWndIndex) {  //鼠标mouseup事件回调
            //console.log(iWndIndex);
        },
        windowFullCcreenChange: function (bFull) {  //全屏切换回调
            console.log('fullScreen callback: ', bFull);
        },
        firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {  //首帧显示回调
            console.log('firstFrame loaded callback: ', iWndIndex, iWidth, iHeight);
        },
        performanceLack: function () {  //性能不足回调
            console.log('performanceLack callback: ');
        }
      });
    },


    initVideo(name, width, height,  src) {
      // video标签无法动态加载src，所以在vue中如果是动态写入视频地址，js加载在HTML渲染之后，会导致video在取src时无法解析。
      // 所以需要用js在获取到src值之后生成HTML元素
      this.$refs[name].innerHTML =
        '<video class="video-js vjs-default-skin vjs-big-play-centered">' +
        "</video>";
      this.player = this.$video(this.$refs[name].firstElementChild, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //自动播放属性,muted:静音播放
        autoplay: "muted",
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示宽度（以像素为单位）
        width: width + "px",
        //设置视频播放器的显示高度（以像素为单位）
        height: height + "px",
        //width="755px" height="216px"
        sources: [
          {
            src,
            //src: 'http://61.160.212.33:9503/live/a10.m3u8',
            type: "application/x-mpegURL",
          },
        ],
      });
    },


    playVideoFn(e) {
      if (e) {
        this.initPlugin();
      }
    },
    closeVideoFn(e) {
      if (e) {
        oWebControl.JS_Disconnect().then(
            function () {
              // oWebControl 为 WebControl 的对象
              // 断开与插件服务的连接成功
            },
            function () {
              // 断开与插件服务的连接失败
            }
        );
      }

    },
    /*启动监控*/
    monitorFn() {
      var self = this;
      self.monitorList.forEach(function (e, idx) {
        self.startPreview(e);
      });
    },
    // 初始化插件
    initPlugin() {
      return;
      var self = this;
      oWebControl = new WebControl({
        szPluginContainer: "playWnd",
        iServicePortStart: 15900,
        iServicePortEnd: 15909,
        szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11", // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: function () {
          initCount = 0;
          self.setCallbacks();
          oWebControl
              .JS_StartService("window", {
                dllPath: "./VideoPluginConnect.dll",
              })
              .then(
                  function () {
                    oWebControl
                        .JS_CreateWnd("playWnd", width, height)
                        .then(function () {
                          console.log("JS_CreateWnd success");
                          self.init();
                        });
                  },
                  function () {
                  }
              );
        },
        cbConnectError: function () {
          console.log("cbConnectError");
          oWebControl = null;
          alert("插件未启动，正在尝试启动，请稍候...");
          WebControl.JS_WakeUp("VideoWebPlugin://");
          initCount++;
          if (initCount < 3) {
            setTimeout(function () {
              initPlugin();
            }, 3000);
          } else {
            alert("插件启动失败，请检查插件是否安装！");
          }
        },
        cbConnectClose: function (bNormalClose) {
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          console.log("cbConnectClose");
          oWebControl = null;
        },
      });
    },

    /*初始化*/
    init() {
      var self = this;
      self.getPubKey(function () {
        var secret = self.setEncrypt("oMlE4cQVTo1YnFT0Tz9h");
        var obj = {
          appkey: "24716082",
          buttonIDs:
              "1,0,16,256,257,258,259,260,512,513,514,515,516,517,768,769",
          enableHTTPS: 1,
          encryptedFields: "secret",
          ip: "218.108.194.22",
          language: "zh_CN",
          layout: "1x2",
          playMode: 0,
          port: 443,
          reconnectDuration: 5,
          reconnectTimes: 5,
          secret: secret,
          showSmart: 1,
          showToolbar: 1,
          snapDir: "D:SnapDir",
          videoDir: "D:VideoDir",
        };

        oWebControl
            .JS_RequestInterface({
              funcName: "init",
              argument: JSON.stringify(obj),
            })
            .then(function (oData) {
              oWebControl.JS_Resize(width, height); // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
              self.monitorFn();
            });
      });
    },

    /*开始预览*/
    startPreview(e) {
      var self = this;
      var cameraIndexCode = e;
      var streamMode = 0;
      var transMode = 1;
      var gpuMode = 0;
      var wndId = -1; //默认为空闲窗口预览
      var isDirectEzviz = 0;
      oWebControl
          .JS_RequestInterface({
            funcName: "startPreview",
            argument: JSON.stringify({
              cameraIndexCode: cameraIndexCode,
              streamMode: streamMode,
              transMode: transMode,
              gpuMode: gpuMode,
              wndId: wndId,
              ezvizDirect: isDirectEzviz,
            }),
          })
          .then(function (oData) {
            console.log(oData);
            // 标签关闭
            $(window).unload(function () {
              if (oWebControl != null) {
                oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
                oWebControl.JS_Disconnect().then(
                    function () {
                    },
                    function () {
                    }
                );
              }
            });

            // 窗口resize
            $(window).resize(function () {
              if (oWebControl != null) {
                oWebControl.JS_Resize(width, height);
                self.setWndCover();
              }
            });

            // 滚动条scroll
            $(window).scroll(function () {
              if (oWebControl != null) {
                oWebControl.JS_Resize(width, height);
                self.setWndCover();
              }
            });
          });
    },

    // 设置窗口裁剪，当因滚动条滚动导致窗口需要被遮住的情况下需要JS_CuttingPartWindow部分窗口
    setWndCover() {
      var iWidth = $(window).width();
      var iHeight = $(window).height();
      var oDivRect = $("#playWnd").get(0).getBoundingClientRect();
      var iCoverLeft = oDivRect.left < 0 ? Math.abs(oDivRect.left) : 0;
      var iCoverTop = oDivRect.top < 0 ? Math.abs(oDivRect.top) : 0;
      var iCoverRight =
          oDivRect.right - iWidth > 0 ? Math.round(oDivRect.right - iWidth) : 0;
      var iCoverBottom =
          oDivRect.bottom - iHeight > 0
              ? Math.round(oDivRect.bottom - iHeight)
              : 0;

      iCoverLeft = iCoverLeft > width ? width : iCoverLeft;
      iCoverTop = iCoverTop > height ? height : iCoverTop;
      iCoverRight = iCoverRight > width ? width : iCoverRight;
      iCoverBottom = iCoverBottom > height ? height : iCoverBottom;

      oWebControl.JS_RepairPartWindow(0, 0, 801, height); // 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft != 0) {
        oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, height);
      }
      if (iCoverTop != 0) {
        oWebControl.JS_CuttingPartWindow(0, 0, 801, iCoverTop); // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight != 0) {
        oWebControl.JS_CuttingPartWindow(
            width - iCoverRight,
            0,
            iCoverRight,
            height
        );
      }
      if (iCoverBottom != 0) {
        oWebControl.JS_CuttingPartWindow(
            0,
            height - iCoverBottom,
            width,
            iCoverBottom
        );
      }
    },

    // 获取公钥
    getPubKey(callback) {
      oWebControl
          .JS_RequestInterface({
            funcName: "getRSAPubKey",
            argument: JSON.stringify({
              keyLength: 1024,
            }),
          })
          .then(function (oData) {
            console.log(oData);
            if (oData.responseMsg.data) {
              pubKey = oData.responseMsg.data;
              callback();
            }
          });
    },

    // 设置窗口控制回调
    setCallbacks() {
      var self = this;
      oWebControl.JS_SetWindowControlCallback({
        cbIntegrationCallBack: self.cbIntegrationCallBack(),
      });
    },

    // 推送消息
    cbIntegrationCallBack(oData) {
      /*  alert(JSON.stringify(oData.responseMsg));*/
    },

    // RSA加密
    setEncrypt(value) {
      var encrypt = new JSEncrypt();
      encrypt.setPublicKey(pubKey);
      return encrypt.encrypt(value);
    },

    /*---------------------  end  --------------------*/

    hideSafeMonitor() {
      this.showSafeMonitor = !this.showSafeMonitor;
    },
    dataVisibleChange() {
      this.dataVisible = !this.dataVisible;
    },
    handlePlay() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
  watch: {
    dataVisible(val, newval) {
      if (val) {
        this.initPlugin();
      } else {
        oWebControl.JS_Disconnect().then(
            function () {
              // oWebControl 为 WebControl 的对象
              // 断开与插件服务的连接成功
            },
            function () {
              // 断开与插件服务的连接失败
            }
        );
      }
      console.log(val);
      console.log(newval);
    },
  },
};
</script>

<style lang="scss" scoped>
$animateTime: 1s;

.main-content {
  
  position: absolute;
  display: flex;
  width: 1920px;
  height: 1065px;
  overflow: hidden;
  padding: 56px 16px 20px 16px;
  justify-content: space-around;
  align-items: flex-end;

  .mark {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background: rgba(0, 9, 18, 0.5);
  }
}

.column-one {
  width: 408px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  justify-content: space-between;
  position: absolute;
  left: 16px;
  @keyframes leftIn {
    0% {
      transform: translateX(-600px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes leftOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-600px);
    }
  }

  animation: leftIn $animateTime linear;
  animation-fill-mode: forwards;

  &.column-one-hide {
    animation-name: leftOut;
  }
}

.column-two {
  z-index: 9999;
  width: 1032px;
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  bottom: 20px;
  @keyframes bottomIn {
    0% {
      bottom: -400px;
    }
    100% {
      bottom: 20px;
    }
  }
  @keyframes bottomOut {
    0% {
      bottom: 20px;
    }
    100% {
      bottom: -400px;
    }
  }

  animation: bottomIn $animateTime linear;
  animation-fill-mode: forwards;

  &.column-two-hide {
    animation-name: bottomOut;
  }
}

.column-three {
  z-index: 9999;
  width: 408px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 16px;
  @keyframes rightIn {
    0% {
      right: -500px;
    }
    100% {
      right: 16px;
    }
  }
  @keyframes rightOut {
    0% {
      right: 16px;
    }
    100% {
      right: -500px;
    }
  }

  right: 16px;
  animation: rightIn $animateTime linear;
  animation-fill-mode: forwards;

  &.column-three-hide {
    right: -500px;
    animation: rightOut $animateTime linear;
  }
}

.global-shadow {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/images/home/global-shadow.png");
  pointer-events: none;
}
</style>
<style scoped lang="scss">
.bgs {
  background: url("../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  width: 810px;
  height: 310px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 1000;
  margin-left: -405px;
  box-sizing: border-box;
  padding: 0 28px 19px;

  .title {
    text-align: center;
    padding-top: 22px;
    padding-bottom: 19px;

    a {
      font-weight: normal;
      color: #ffffff;
      font-size: 15px;
      padding: 10px;
      text-decoration: none;
    }
  }

  .playWnd {
    width: 755px;
    height: 216px;
  }
}
</style>
