<template>
	<div class="villageMonitoring">
		<div id="playWnd2" class="playWnd">
			<div id="player" style="width:100%; height:750px"></div>
		</div>
		
	</div>
</template>
<script>
var oWebControl = null; // 插件对象
var bIE = !!window.ActiveXObject || 'ActiveXObject' in window; // 是否为IE浏览器
var pubKey = '';
var initCount = 0;
var width = 1850;
var height = 900;
export default {
  props:['type'],
	data() {
		return {
			player: null,
			monitorList: [
				'ea3e9d5bf9a84d57a2837597570f221e',
				'9b37cac8376e4112be2ac111a06c37fc',
				'8b2b4b63f19c4ffdaac2b6a23f4b45d4',
				'41c518344e4d40199af943f4dbe8e531',
				'7160c44a6dc44efd827ac4137485407e',
				'c4a933c5e68f42619974471e68fd28fa'
			]
		};
	},

  watch: {
    type(e, i) {
      if(e){
        oWebControl.JS_Disconnect().then(
            function() {
              // oWebControl 为 WebControl 的对象
              // 断开与插件服务的连接成功
            },
            function() {
              // 断开与插件服务的连接失败
            }
        );
      }
    }
  },
  
  	mounted() {
		//this.initPlugin();
		this.$page.index.isOpen = false;
		let width = 400;
		let height = 200;
		this.createPlayer();
	},
	destroyed(){
		if(this.player != null)  this.player.JS_Stop();
		this.player.JS_Destroy();
	},
	methods: {

		async createPlayer() {

			this.player = new window.JSPlugin({
				szId: 'player',
				szBasePath: "./",
				iMaxSplit: 4,
				iCurrentSplit: 2,
				openDebug: true,
				oStyle: {
				borderSelect: '#FFCC00',
				}
			});
			this.player.JS_ArrangeWindow(4)

			let res01 = await this.$getReq(1000, '2472ae2c3df44c3687e1ed89d13f740f');
			this.player.JS_Play(res01.data.data.url, { playURL:res01.data.data.url, mode:1 }, 0);
			
			let res02 = await this.$getReq(0, '434e4e1df77047638e923c2a8dff0700');
			this.player.JS_Play(res02.data.data.url, { playURL:res02.data.data.url, mode:1 }, 1);
			
			let res03 = await this.$getReq(0, '928b29625c3c47fba7e563ffb356255f');
			this.player.JS_Play(res03.data.data.url, { playURL:res03.data.data.url, mode:1 }, 2);
			
			let res04 = await this.$getReq(0, '36129f67a20f4705bdb9bbcaa043b51a');
			this.player.JS_Play(res04.data.data.url, { playURL:res04.data.data.url, mode:1 }, 3);

			let res05 = await this.$getReq(0, 'c5dc164e5c354946b789ad0636714609');
			this.player.JS_Play(res05.data.data.url, { playURL:res05.data.data.url, mode:1 }, 4);

			let res06 = await this.$getReq(0, '48e784f773de49ddb2c5d335c5e71211');
			this.player.JS_Play(res06.data.data.url, { playURL:res06.data.data.url, mode:1 }, 5);

			let res07 = await this.$getReq(0, '4bffbff5329143fb9dcd870c7262fbed');
			this.player.JS_Play(res07.data.data.url, { playURL:res07.data.data.url, mode:1 }, 6);

			let res08 = await this.$getReq(0, 'cbe507339f174822b3a7726d6e25009b');
			this.player.JS_Play(res08.data.data.url, { playURL:res08.data.data.url, mode:1 }, 7);

			let res09 = await this.$getReq(0, '5cde23892014498986d39c8fcc93239e');
			this.player.JS_Play(res09.data.data.url, { playURL:res09.data.data.url, mode:1 }, 8);

			let res10 = await this.$getReq(0, 'fa6292ce957947809f93062f5358946a');
			this.player.JS_Play(res10.data.data.url, { playURL:res10.data.data.url, mode:1 }, 9);

			let res11 = await this.$getReq(0, 'e7f6ccbde3824417930651b46173a42a');
			this.player.JS_Play(res11.data.data.url, { playURL:res11.data.data.url, mode:1 }, 10);

			let res12 = await this.$getReq(0, 'fac906fea87d41489ddd40aa77600d7e');
			this.player.JS_Play(res12.data.data.url, { playURL:res12.data.data.url, mode:1 }, 11);

			let res13 = await this.$getReq(0, '9224ef49719f4b619bf6d8052c512047');
			this.player.JS_Play(res13.data.data.url, { playURL:res13.data.data.url, mode:1 }, 12);
			
			let res14 = await this.$getReq(0, '7832c6c4363b45ee810f2919a92d1597');
			this.player.JS_Play(res14.data.data.url, { playURL:res14.data.data.url, mode:1 }, 13);
			
			let res15 = await this.$getReq(0, '8b2ab5913900443b9f9da8447b3b6a27');
			this.player.JS_Play(res15.data.data.url, { playURL:res15.data.data.url, mode:1 }, 14);
			
			let res16 = await this.$getReq(0, '0ccc6e6dcb4b4ca0b903e0dfb14b5770');
			this.player.JS_Play(res16.data.data.url, { playURL:res16.data.data.url, mode:1 }, 15);
			
			
			// 事件回调绑定
			this.player.JS_SetWindowControlCallback({
				windowEventSelect: async (iWndIndex) => {  //插件选中窗口回调
					console.log('windowSelect callback: ', iWndIndex);
				},
				pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {  //插件错误回调
					console.log('pluginError callback: ', iWndIndex, iErrorCode, oError);
				},
				windowEventOver: function (iWndIndex) {  //鼠标移过回调
					//console.log(iWndIndex);
				},
				windowEventOut: function (iWndIndex) {  //鼠标移出回调
					//console.log(iWndIndex);
				},
				windowEventUp: function (iWndIndex) {  //鼠标mouseup事件回调
					//console.log(iWndIndex);
				},
				windowFullCcreenChange: function (bFull) {  //全屏切换回调
					console.log('fullScreen callback: ', bFull);
				},
				firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {  //首帧显示回调
					console.log('firstFrame loaded callback: ', iWndIndex, iWidth, iHeight);
				},
				performanceLack: function () {  //性能不足回调
					console.log('performanceLack callback: ');
				}
			});
		},

		initVideo(name, width, height,  src) {
			// video标签无法动态加载src，所以在vue中如果是动态写入视频地址，js加载在HTML渲染之后，会导致video在取src时无法解析。
			// 所以需要用js在获取到src值之后生成HTML元素
			this.$refs[name].innerHTML =
				'<video class="video-js vjs-default-skin vjs-big-play-centered">' +
				"</video>";
			this.player = this.$video(this.$refs[name].firstElementChild, {
				//确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
				controls: true,
				//自动播放属性,muted:静音播放
				autoplay: "muted",
				//建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
				preload: "auto",
				//设置视频播放器的显示宽度（以像素为单位）
				width: width + "px",
				//设置视频播放器的显示高度（以像素为单位）
				height: height + "px",
				//width="755px" height="216px"
				sources: [
				{
					src,
					//src: 'http://61.160.212.33:9503/live/a10.m3u8',
					type: "application/x-mpegURL",
				},
				],
			});
		},

		/*启动监控*/
		monitorFn() {
			var self = this;
			self.monitorList.forEach(function(e, idx) {
				self.startPreview(e);
			});
		},
		// 初始化插件
		initPlugin() {
			var self = this;
			oWebControl = new WebControl({
				szPluginContainer: 'playWnd2',
				iServicePortStart: 15900,
				iServicePortEnd: 15909,
				szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11', // 用于IE10使用ActiveX的clsid
				cbConnectSuccess: function() {
					initCount = 0;
					self.setCallbacks();
					oWebControl
						.JS_StartService('window', {
							dllPath: './VideoPluginConnect.dll'
						})
						.then(
							function() {
								oWebControl.JS_CreateWnd('playWnd2', width, height).then(function() {
									console.log('JS_CreateWnd success');
									self.init();
								});
							},
							function() {}
						);
				},
				cbConnectError: function() {
					console.log('cbConnectError');
					oWebControl = null;
					alert('插件未启动，正在尝试启动，请稍候...');
					WebControl.JS_WakeUp('VideoWebPlugin://');
					initCount++;
					if (initCount < 3) {
						setTimeout(function() {
							initPlugin();
						}, 3000);
					} else {
						alert('插件启动失败，请检查插件是否安装！');
					}
				},
				cbConnectClose: function(bNormalClose) {
					// 异常断开：bNormalClose = false
					// JS_Disconnect正常断开：bNormalClose = true
					console.log('cbConnectClose');
					oWebControl = null;
				}
			});
		},

		/*初始化*/
		init() {
			var self = this;
			self.getPubKey(function() {
				var secret = self.setEncrypt('oMlE4cQVTo1YnFT0Tz9h');
				var obj = {
					appkey: '24716082',
					buttonIDs: '1,0,16,256,257,258,259,260,512,513,514,515,516,517,768,769',
					enableHTTPS: 1,
					encryptedFields: 'secret',
					ip: '218.108.194.22',
					language: 'zh_CN',
					layout: '1+5',
					playMode: 0,
					port: 443,
					reconnectDuration: 5,
					reconnectTimes: 5,
					secret: secret,
					showSmart: 1,
					showToolbar: 1,
					snapDir: 'D:SnapDir',
					videoDir: 'D:VideoDir'
				};

				oWebControl
					.JS_RequestInterface({
						funcName: 'init',
						argument: JSON.stringify(obj)
					})
					.then(function(oData) {
						oWebControl.JS_Resize(width, height); // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
						self.monitorFn();
					});
			});
		},

		/*开始预览*/
		startPreview(e) {
			var self = this;
			var cameraIndexCode = e;
			var streamMode = 0;
			var transMode = 1;
			var gpuMode = 0;
			var wndId = -1; //默认为空闲窗口预览
			var isDirectEzviz = 0;
			oWebControl
				.JS_RequestInterface({
					funcName: 'startPreview',
					argument: JSON.stringify({
						cameraIndexCode: cameraIndexCode,
						streamMode: streamMode,
						transMode: transMode,
						gpuMode: gpuMode,
						wndId: wndId,
						ezvizDirect: isDirectEzviz
					})
				})
				.then(function(oData) {
					console.log(oData);
					// 标签关闭
					$(window).unload(function() {
						if (oWebControl != null) {
							oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
							oWebControl.JS_Disconnect().then(function() {}, function() {});
						}
					});

					// 窗口resize
					$(window).resize(function() {
						if (oWebControl != null) {
							oWebControl.JS_Resize(width, height);
							self.setWndCover();
						}
					});

					// 滚动条scroll
					$(window).scroll(function() {
						if (oWebControl != null) {
							oWebControl.JS_Resize(width, height);
							self.setWndCover();
						}
					});
				});
		},

		// 设置窗口裁剪，当因滚动条滚动导致窗口需要被遮住的情况下需要JS_CuttingPartWindow部分窗口
		setWndCover() {
			var iWidth = $(window).width();
			var iHeight = $(window).height();
			var oDivRect = $('#playWnd2')
				.get(0)
				.getBoundingClientRect();
			var iCoverLeft = oDivRect.left < 0 ? Math.abs(oDivRect.left) : 0;
			var iCoverTop = oDivRect.top < 0 ? Math.abs(oDivRect.top) : 0;
			var iCoverRight = oDivRect.right - iWidth > 0 ? Math.round(oDivRect.right - iWidth) : 0;
			var iCoverBottom = oDivRect.bottom - iHeight > 0 ? Math.round(oDivRect.bottom - iHeight) : 0;

			iCoverLeft = iCoverLeft > width ? width : iCoverLeft;
			iCoverTop = iCoverTop > height ? height : iCoverTop;
			iCoverRight = iCoverRight > width ? width : iCoverRight;
			iCoverBottom = iCoverBottom > height ? height : iCoverBottom;

			oWebControl.JS_RepairPartWindow(0, 0, 801, height); // 多1个像素点防止还原后边界缺失一个像素条
			if (iCoverLeft != 0) {
				oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, height);
			}
			if (iCoverTop != 0) {
				oWebControl.JS_CuttingPartWindow(0, 0, 801, iCoverTop); // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
			}
			if (iCoverRight != 0) {
				oWebControl.JS_CuttingPartWindow(width - iCoverRight, 0, iCoverRight, height);
			}
			if (iCoverBottom != 0) {
				oWebControl.JS_CuttingPartWindow(0, height - iCoverBottom, width, iCoverBottom);
			}
		},

		// 获取公钥
		getPubKey(callback) {
			oWebControl
				.JS_RequestInterface({
					funcName: 'getRSAPubKey',
					argument: JSON.stringify({
						keyLength: 1024
					})
				})
				.then(function(oData) {
					console.log(oData);
					if (oData.responseMsg.data) {
						pubKey = oData.responseMsg.data;
						callback();
					}
				});
		},

		// 设置窗口控制回调
		setCallbacks() {
			var self = this;
			oWebControl.JS_SetWindowControlCallback({
				cbIntegrationCallBack: self.cbIntegrationCallBack()
			});
		},

		// 推送消息
		cbIntegrationCallBack(oData) {
			/*  alert(JSON.stringify(oData.responseMsg));*/
		},

		// RSA加密
		setEncrypt(value) {
			var encrypt = new JSEncrypt();
			encrypt.setPublicKey(pubKey);
			return encrypt.encrypt(value);
		}
	}
};
</script>
<style lang="scss" scoped>
.villageMonitoring {
	width: 1880px;
	height: 1000px;
	padding: 70px 14px;
	background: url('../../assets/images/monitor/1.png') no-repeat;
	background-size: 100% 100%;
}
.playWnd {
	width: 1850px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content:center;
	align-items:center;
}
.playWnd div{margin: 10px 10px 0px 0px; width: 400px; height: 200px; overflow: hidden; }
</style>
