<template>
  <div :class="['right', { hide: isChecked }]">
    <CarFlow />
  </div>
</template>

<script>
import CarFlow from "@/components/video-monitor/car-flow";
export default {
  props: ["isChecked"],
  components: {
    CarFlow,
  },
};
</script>

<style lang="scss" scoped>
.right {
  position: relative;
  width: 408px;
  @keyframes bounceIn {
    0% {
      right: -500px;
    }
    100% {
      right: 10px;
    }
  }
  @keyframes bounceOut {
    0% {
      right: 10px;
    }
    100% {
      right: -500px;
    }
  }
  &.hide {
    animation: bounceOut 4s;
    right: -500px;
  }
  animation: bounceIn 4s;
}
</style>
