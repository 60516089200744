<template>
  <div class="content">
    <div class="video-wrap">
      <video
        class="video"
        ref="videoEl"
        src="https://www.runoob.com/try/demo_source/movie.mp4"
      ></video>
      <img
        class="play-btn"
        ref="playBtn"
        @click="handlePlay"
        src="../../assets/images/home/play.png"
      />
    </div>
    <div class="image-wrap">
      <div class="image-fl">
        <img src="../../assets/images/videoMonitor/test.png" alt="" />
      </div>
      <div class="image-fr">
        <div class="image-small" @click="handlePreview">
          <img src="../../assets/images/videoMonitor/test.png" alt="" />
        </div>
        <div class="image-small">
          <img src="../../assets/images/videoMonitor/test.png" alt="" />
        </div>
        <div class="image-small">
          <img src="../../assets/images/videoMonitor/test.png" alt="" />
        </div>
        <div class="image-small">
          <img src="../../assets/images/videoMonitor/test.png" alt="" />
        </div>
        <div class="image-small">
          <img src="../../assets/images/videoMonitor/test.png" alt="" />
        </div>
        <div class="image-small">
          <img src="../../assets/images/videoMonitor/test.png" alt="" />
        </div>
      </div>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="viewerImgList"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { ElImageViewer },
  data() {
    return {
      showViewer: false,
      viewerImgList: [],
    };
  },
  methods: {
    handlePlay() {
      this.$refs.videoEl.play();
      this.$refs.playBtn.style.display = "none";
    },
    closeViewer() {
      this.showViewer = false;
    },
    handlePreview() {
      this.viewerImgList = ["../../assets/images/videoMonitor/test.png"];
      this.showViewer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1036px;
  height: 938px;
  padding: 60px 32px 0;
  margin-top: 32px;
  background: {
    image: url("../../assets/images/videoMonitor/video_bg.png");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}

.video {
  width: 100%;
  height: 100%;
}

.video-wrap {
  position: relative;
  width: 100%;
  height: 554px;
  .play-btn {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
}

.image-wrap {
  display: flex;
  margin-top: 14px;

  img {
    width: 100%;
  }

  .image-fl {
    width: 390px;
    margin-right: 8px;
  }

  .image-fr {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }

  .image-small {
    width: 186px;
    height: 140px;
    overflow: hidden;
    margin-right: 8px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
