<template>
  <div class="container">
    <!-- <div class="mark" /> -->
    <head-top :isChecked="isChecked" title="游客统计" @changeChecked="handleChecked" />
    <div :class="['left', { 'hide': isChecked }]">
        <total-info :isChecked="isChecked" :refreshNum="refreshNum" />
        <distribute :isChecked="isChecked" :refreshNum="refreshNum" />
        <sourceTourists :isChecked="isChecked" :refreshNum="refreshNum"></sourceTourists>
        <!-- <density :isChecked="isChecked" :refreshNum="refreshNum" /> -->
        <div class="back" @click="back">返回</div>
    </div>
 <!--  <div :class="['bottom', { 'hide': isChecked }]">
        <safeMonitor :isChecked="isChecked" :refreshNum="refreshNum" />
    </div>-->
    <div class="bgs" v-if="!isChecked" >
      <p class="title">热点区域监控</p>
      <div id="playWnd" class="playWnd"></div>
    </div>
    <div :class="['right', { 'hide': isChecked }]">
        <inOut :isChecked="isChecked" :refreshNum="refreshNum" />
        <rank :isChecked="isChecked" :refreshNum="refreshNum" />
        <warning :isChecked="isChecked" :refreshNum="refreshNum" />
    </div>
    <centerVideo v-if="visible" :close="close" />
    <!-- <center :play='handlePlay' :isChecked="isChecked"/> -->
  </div>
</template>

<script>
var oWebControl = null;// 插件对象
var bIE = (!!window.ActiveXObject || 'ActiveXObject' in window);// 是否为IE浏览器
var pubKey = '';
var initCount = 0;
var width = 755;
var height = 216;
import moment from "moment";
// import request from 'superagent';
import headTop from '@/components/park/menu';
import totalInfo from '@/components/abortion/totalInfo';
import distribute from '@/components/abortion/distribute';
import density from '@/components/abortion/density';
import inOut from '@/components/abortion/inOut';
import rank from '@/components/abortion/rank';
import warning from '@/components/abortion/warning';
import safeMonitor from '@/components/abortion/safe-monitor';
import centerVideo from '@/components/abortion/center-video.vue';
import center from '@/components/abortion/center.vue';
import sourceTourists from '@/components/abortion/source-tourists'
export default {
  components: {
    headTop,
    totalInfo,
    distribute,
    density,
    inOut,
    warning,
    rank,
    safeMonitor,
    centerVideo,
    center,
    sourceTourists
  },
  watch: {
    isChecked(val, newval) {
      if (val) {
        oWebControl.JS_Disconnect().then(
            function () {
              // oWebControl 为 WebControl 的对象
              // 断开与插件服务的连接成功
            },
            function () {
              // 断开与插件服务的连接失败
            }
        );
      } else {
        this.initPlugin()

      }
      console.log(val)
      console.log(newval)
    }
  },
  data() {
    return {
      refreshNum: 0,
      monitorList: ['8b2b4b63f19c4ffdaac2b6a23f4b45d4', 'c4a933c5e68f42619974471e68fd28fa'],
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
      isChecked: false,
      visible: false,
      allDatas: {
        baseInfo: { },
        checkInInfo: {
          remainRate: 67,
          remain: 301,
          checkIn: 145
        },
        earningsInfo: {

        },
        portraitInfo: {
          sexInfo: { boyInfo: {}, girlInfo: {} },
          consumptionList: [],
          ageList: []
        },
        comparisonInfo: {
          list: []
        },
        rankInfo: {
          rankList: []
        }
      }
    };
  },
  beforeRouteLeave(to, form, next) {

    oWebControl.JS_Disconnect().then(
        function () {
          // oWebControl 为 WebControl 的对象
          // 断开与插件服务的连接成功
        },
        function () {
          // 断开与插件服务的连接失败
        }
    );
    next();

  },
  mounted() {
      var times = setInterval(() => {
          if (window.iframe && window.iframe.contentWindow.isLoading) {
              window.iframe.contentWindow.unityInstance.SendMessage("Main Camera", "SetSence", "view10, -367.2181,-43.80785,191.5615");
              window.iframe.contentWindow.$(".Tip").fadeOut(200);
              clearInterval(times)
          }

      }, 1000)


    this.getData()
    setInterval(() => {
      setTimeout(() => {
        this.getData()
      }, 0)
    }, 15000)
    setInterval(() => {
      this.time = moment().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
    this.getData()
    this.initPlugin()
  },
  methods: {


    /*启动监控*/
    monitorFn() {
      var self = this;
      self.monitorList.forEach(function (e, idx) {
        self.startPreview(e)
      })

    },
    // 初始化插件
    initPlugin() {
      var self = this;
      oWebControl = new WebControl({
        szPluginContainer: "playWnd",
        iServicePortStart: 15900,
        iServicePortEnd: 15909,
        szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11",   // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: function () {
          initCount = 0;
          self.setCallbacks();
          oWebControl.JS_StartService("window", {
            dllPath: "./VideoPluginConnect.dll"
          }).then(function () {
            oWebControl.JS_CreateWnd("playWnd", width, height).then(function () {
              console.log("JS_CreateWnd success");
              self.init()

            });
          }, function () {

          });
        },
        cbConnectError: function () {
          console.log("cbConnectError");
          oWebControl = null;
          alert("插件未启动，正在尝试启动，请稍候...");
          WebControl.JS_WakeUp("VideoWebPlugin://");
          initCount++;
          if (initCount < 3) {
            setTimeout(function () {
              initPlugin();
            }, 3000)
          } else {
            alert("插件启动失败，请检查插件是否安装！");
          }
        },
        cbConnectClose: function (bNormalClose) {
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          console.log("cbConnectClose");
          oWebControl = null;
        }
      });
    },

    /*初始化*/
    init() {
      var self = this;
      self.getPubKey(function () {
        var secret = self.setEncrypt("oMlE4cQVTo1YnFT0Tz9h")
        var obj = {
          appkey: "24716082",
          buttonIDs: "1,0,16,256,257,258,259,260,512,513,514,515,516,517,768,769",
          enableHTTPS: 1,
          encryptedFields: "secret",
          ip: "218.108.194.22",
          language: "zh_CN",
          layout: "1x2",
          playMode: 0,
          port: 443,
          reconnectDuration: 5,
          reconnectTimes: 5,
          secret: secret,
          showSmart: 1,
          showToolbar: 1,
          snapDir: "D:\SnapDir",
          videoDir: "D:\VideoDir"
        }

        oWebControl.JS_RequestInterface({
          funcName: "init",
          argument: JSON.stringify(obj)
        }).then(function (oData) {
          oWebControl.JS_Resize(width, height);  // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
          self.monitorFn()

        });
      })

    },

    /*开始预览*/
    startPreview(e) {
      var self = this;
      var cameraIndexCode = e;
      var streamMode = 0;
      var transMode = 1;
      var gpuMode = 0;
      var wndId = -1;  //默认为空闲窗口预览
      var isDirectEzviz = 0
      oWebControl.JS_RequestInterface({
        funcName: "startPreview",
        argument: JSON.stringify({
          cameraIndexCode: cameraIndexCode,
          streamMode: streamMode,
          transMode: transMode,
          gpuMode: gpuMode,
          wndId: wndId,
          ezvizDirect: isDirectEzviz
        })
      }).then(function (oData) {
        console.log(oData)
        // 标签关闭
        $(window).unload(function () {
          if (oWebControl != null) {
            oWebControl.JS_HideWnd();  // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
            oWebControl.JS_Disconnect().then(function () {
            }, function () {
            });
          }
        });

        // 窗口resize
        $(window).resize(function () {
          if (oWebControl != null) {
            oWebControl.JS_Resize(width, height);
            self.setWndCover();
          }
        });

        // 滚动条scroll
        $(window).scroll(function () {
          if (oWebControl != null) {
            oWebControl.JS_Resize(width, height);
            self.setWndCover();
          }
        });
      });


    },


    // 设置窗口裁剪，当因滚动条滚动导致窗口需要被遮住的情况下需要JS_CuttingPartWindow部分窗口
    setWndCover() {
      var iWidth = $(window).width();
      var iHeight = $(window).height();
      var oDivRect = $("#playWnd").get(0).getBoundingClientRect();
      var iCoverLeft = (oDivRect.left < 0) ? Math.abs(oDivRect.left) : 0;
      var iCoverTop = (oDivRect.top < 0) ? Math.abs(oDivRect.top) : 0;
      var iCoverRight = (oDivRect.right - iWidth > 0) ? Math.round(oDivRect.right - iWidth) : 0;
      var iCoverBottom = (oDivRect.bottom - iHeight > 0) ? Math.round(oDivRect.bottom - iHeight) : 0;

      iCoverLeft = (iCoverLeft > width) ? width : iCoverLeft;
      iCoverTop = (iCoverTop > height) ? height : iCoverTop;
      iCoverRight = (iCoverRight > width) ? width : iCoverRight;
      iCoverBottom = (iCoverBottom > height) ? height : iCoverBottom;

      oWebControl.JS_RepairPartWindow(0, 0, 801, height);  // 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft != 0) {
        oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, height);
      }
      if (iCoverTop != 0) {
        oWebControl.JS_CuttingPartWindow(0, 0, 801, iCoverTop);  // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight != 0) {
        oWebControl.JS_CuttingPartWindow(width - iCoverRight, 0, iCoverRight, height);
      }
      if (iCoverBottom != 0) {
        oWebControl.JS_CuttingPartWindow(0, height - iCoverBottom, width, iCoverBottom);
      }
    },


    // 获取公钥
    getPubKey(callback) {
      oWebControl.JS_RequestInterface({
        funcName: "getRSAPubKey",
        argument: JSON.stringify({
          keyLength: 1024
        })
      }).then(function (oData) {
        console.log(oData)
        if (oData.responseMsg.data) {
          pubKey = oData.responseMsg.data
          callback()
        }
      })
    },

    // 设置窗口控制回调
    setCallbacks() {
      var self = this;
      oWebControl.JS_SetWindowControlCallback({
        cbIntegrationCallBack: self.cbIntegrationCallBack()
      });
    },

    // 推送消息
    cbIntegrationCallBack(oData) {
      /*  alert(JSON.stringify(oData.responseMsg));*/
    },

    // RSA加密
    setEncrypt(value) {
      var encrypt = new JSEncrypt();
      encrypt.setPublicKey(pubKey);
      return encrypt.encrypt(value);
    },



    /*---------------------  end  --------------------*/
    getData() {
      this.refreshNum = this.refreshNum + 1
    },
    handlePlay() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleChecked() {
      this.isChecked = !this.isChecked;
    },
    back() {
      this.$router.back(-1)
    }
  },
};
</script>

<style lang="scss" scoped>

 .container {
   width: 1920px;
   height: 100%;
   // z-index: 99999;
   background: #011326;
   margin: 0 auto;
   padding: 0;
   position: relative;
   /*background: url("../assets/images/park/bg.png") no-repeat;*/
   background-size: contain;
   .bgImg {
     background-size: contain;
   }
   .mark {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background: rgba(0, 9, 18, 0.5);
   }
}
.back {
  background: url("../assets/images/park/back@2x.png") no-repeat;
  position: absolute;
  left: 440px;
  top: 10px;
  z-index: 10000;
  width: 75px;
  height: 45px;
  font-size: 15px;
  background-size: contain;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 37px;
  letter-spacing: 2px;
  transform: none;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.left {
  top: 74px;
  left: 18px;
  @keyframes bounceLeftIn {
    0% {
      left:  -520px
    }
    100% {
      left: 18px
    }
  }
  @keyframes bounceLeftOut {
    0% {
      left: 18px
    }
    100% {
      left: -520px
    }
  }
  &.hide {
    animation: bounceLeftOut 1s linear;
    left: -520px
  }
  position: absolute;
  z-index: 999;
  animation: bounceLeftIn 1s linear;
}

.right {
  position: absolute;
  right: 18px;
  top: 54px;
  z-index: 9999;
  @keyframes bounceRightIn {
    0% {
      right: -520px
    }
    100% {
      right: 18px;
    }
  }
  @keyframes bounceRightOut {
    0% {
      right: 18px;
    }
    100% {
      right: -520px
    }
  }
  &.hide {
    animation: bounceRightOut 1s linear;
    right: -420px
  }
  animation: bounceRightIn 1s linear;
}

.bottom {
  position: absolute;
  bottom: 15px;
  left: 446px;
  display: flex;
  z-index: 9999;
  @keyframes bounceBottomIn {
    0% {
      bottom: -400px
    }
    100% {
      bottom: 15px
    }
  }
  @keyframes bounceBottomOut {
    0% {
      bottom: 15px
    }
    100% {
      bottom: -400px
    }
  }
  &.hide {
    animation: bounceBottomOut 1s linear;
    bottom: -400px
  }
  animation: bounceBottomIn 1s linear;

}
 .bgs {
   background: url("../assets/bg.png") no-repeat;
   background-size: 100% 100%;
   width: 810px;
   height: 310px;
   position: fixed;
   bottom: 20px;
   left: 50%;
   z-index: 1000;
   margin-left: -405px;
   box-sizing: border-box;
   padding: 0 28px 19px;

   .title {
     text-align: center;
     padding-top: 22px;
     font-size: 15px;
     padding-bottom: 19px;
     font-weight: normal;
     color: #FFFFFF;
   }

   .playWnd {

     width: 755px;
     height: 216px;

   }
 }
</style>
