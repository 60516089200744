<template>
  <div :class="['left_main', { 'hide': isChecked }]">
    <div class="back" @click="back">返回</div>
    <div class="total">
      <div class="title">车辆总概</div>
      <div class="top">
        <div class="num-one item">
          <div class="name">今日停车</div>
          <div class="num">
            <countTo ref="todayNum" :startVal='0' :endVal='todayCars' :duration='4000'></countTo>
          </div>
        </div>
        <div class="num-two item">
          <div class="name">昨日停车</div>
          <div class="num">
            <countTo ref="yesterdayNum" :startVal='0' :endVal='yesterdayCars' :duration='4000'></countTo>
          </div>
        </div>
        <div class="num-three item">
          <div class="name">本月停车</div>
          <div class="num">
            <countTo ref="monthNum" :startVal='0' :endVal='mouthCars' :duration='4000'></countTo>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="num-one item">
          <div class="name">上月停车</div>
          <div class="num">
            <countTo ref="lastMonthNum" :startVal='0' :endVal='lastMonthCars' :duration='4000'></countTo>
          </div>
        </div>
        <div class="num-two item">
          <div class="name">总停车</div>
          <div class="num">
            <countTo ref="totalNum" :startVal='0' :endVal='sumCars' :duration='4000'></countTo>
            <!-- <span class="unit">万</span> -->
          </div>
        </div>
        <div class="num-three item">
          <div class="name">停车率</div>
          <div class="num">
            <!-- <countTo ref="monthRateNum" :startVal='0' :endVal='carsRate' :duration='4000'></countTo> -->
            <span>{{carsRate}}</span>
            <span class="unit">%</span>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="item">
          <div class="name">今日入场<img src="../../assets/images/park/car@2x.png" /></div>
          <div class="num">
            <countTo ref="inNum" :startVal='0' :endVal='inComeCars' :duration='4000' style="min-width: 65px;display: inline-block;"></countTo>
            <span class="unit">辆</span>
          </div>
          <div class="description">较昨日
              <img class="add" v-if="inFlag === 1" src="../../assets/images/park/arrow_up@2x.png" />
              <img class="minus" v-if="inFlag === -1" src="../../assets/images/park/arrow_down@2x.png" />
              <span class="num" :style="{'color': inFlag === 1 ? 'rgba(50, 218, 61, 1)' : 'rgba(247, 86, 55, 1)'}">
                <countTo ref="lastInNum" :startVal='0' :endVal='inFlagNum' :duration='4000'></countTo>
              </span>
          </div>
        </div>
        <div class="item">
          <div class="name">今日出场<img src="../../assets/images/park/car@2x.png" /></div>
          <div class="num">
            <countTo ref="outNum" :startVal='0' :endVal='outComeCars' :duration='4000' style="min-width: 65px;display: inline-block;"></countTo>
            <span class="unit">辆</span>
          </div>
          <div class="description">较昨日
              <img class="add" v-if="outFlag === 1" src="../../assets/images/park/arrow_up@2x.png" />
              <img class="minus" v-if="outFlag === -1" src="../../assets/images/park/arrow_down@2x.png" />
              <span class="num" :style="{'color': outFlag === 1 ? 'rgba(50, 218, 61, 1)' : 'rgba(247, 86, 55, 1)'}">
                <countTo ref="lastOutNum" :startVal='0' :endVal='outFlagNum' :duration='4000'></countTo>
              </span>
          </div>
        </div>
      </div>
    </div>
    <div class="type">
      <div class="title">车辆类型</div>
      <div class="types">
        <div class="item" v-for="(item, idx) in typeList">
          <div class="name">{{item.carSort}}</div>
          <div class="num" :style="{'color': item.color }">
            <countTo :startVal='0' :endVal='item.carsNum' :duration='4000'></countTo>
          </div>
          <div class="process">
            <div
              :class="['process_bar', { 'process_bar_active': active }]"
              :style="{'background': `linear-gradient(90deg, ${item.color} 0%, ${item.color} 100%)`, 'width': `${item.rate}%` }"/>
            </div>
        </div>
      </div>
    </div>
    <div class="detection">
      <div class="title">车辆出入监测</div>
      <div class="list">
        <div class="item" v-for="(item, idx) in detectionList">
          <div class="name">{{ item.name }}</div>
          <div class="num">
            <countTo v-if="idx === 0" ref="dnum" :startVal='0' :endVal='item.num + addNum' :duration='4000' style="min-width: 60px;display: inline-block;"></countTo>
            <countTo v-if="idx === 1" ref="dnumOne" :startVal='0' :endVal='item.num - addNum' :duration='4000' style="min-width: 55px;display: inline-block;"></countTo>
            <countTo v-if="idx === 2" ref="dnumTwo" :startVal='0' :endVal='item.num' :duration='4000' style="min-width: 40px;display: inline-block;"></countTo>
            <countTo v-if="idx === 3" ref="dnumThree" :startVal='0' :endVal='item.num' :duration='4000' style="min-width: 40px;display: inline-block;"></countTo>
            <span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
      <Bubble />
    </div>
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import Bubble from '@/components/park/bubble';
import toThousands from '../../utils/toThousands'

export default {
  components: {
    Bubble,
    countTo
  },
  data() {
    return {
      addNum: 0,
      minusNum: 0,
      active: false,
      detectionList: [],
      typeList: [],
      todayCars: 0,
      yesterdayCars: 0,
      mouthCars: 0,
      lastMonthCars: 0,
      sumCars: 0,
      carsRate: 0,
      outComeCars: 0,
      inComeCars: 0,
      outFlagNum: 0,
      inFlagNum: 0,
      inFlag: 0,
      outFlag: 0,
    }
  },
  props: ['isChecked', 'parkInfo', 'refreshNum'],
  // watch: {
  //   // 如果 `textValue` 发生改变，这个函数就会运行
  //   isChecked: function () {
  //     this.restart();
  //   },
  //   refreshNum: function () {
  //     if (this.refreshNum > 0) this.restart();
  //   },
  // },
  // computed: {
  //   randomInfo() {
  //     const carNum = this.parkInfo.carNum + this.addNum
  //     const busNum = this.parkInfo.busNum + this.addNum
  //     const bBusNum = this.parkInfo.bBusNum + this.addNum
  //     const total = carNum + busNum + bBusNum
  //     return {
  //       carNum,
  //       carNumRate: Math.round(carNum * 100 / total),
  //       busNum,
  //       busNumRate: Math.round(busNum * 100 / total),
  //       bBusNum,
  //       bBusNumRate: Math.round(bBusNum * 100 / total),
  //     }
  //   },
  //   today() {
  //     return this.parkInfo.today + this.addNum
  //   },
  //   month() {
  //     return this.parkInfo.month + this.addNum
  //   },
  //   inNum() {
  //     return this.parkInfo.inNum + this.addNum
  //   },
  //   lastInNum() {
  //     return this.parkInfo.lastInNum + this.addNum
  //   },
  //   outNum() {
  //     return this.parkInfo.outNum + this.minusNum
  //   },
  //   lastOutNum() {
  //     return this.parkInfo.lastOutNum - this.minusNum
  //   },
  // },
  mounted() {
    var times = setInterval(() => {
      if (window.iframe && window.iframe.contentWindow.isLoading) {
        window.iframe.contentWindow.unityInstance.SendMessage("Main Camera", "SetSence", "view01, -224.1969,-191.7131,-295.022");
        window.iframe.contentWindow.$(".Tip").fadeOut(200);
        clearInterval(times)
      }
    }, 1000)




    setInterval(() => {
      setTimeout(() => {
        this.getData()
        // this.getMinusNum()
      }, 0)
    }, 10000)
    this.getData()
  },
  methods: {
    // getAddNum() {
    //   this.addNum = this.addNum + Math.ceil(Math.random()*10)
    // },
    // getMinusNum() {
    //   this.minusNum = this.minusNum + Math.ceil(Math.random()*10)
    // },
    getData() {
      this.$api.get('/jeecg-boot/carFlow/getCarsShall')
      .then(({
        todayCars, yesterdayCars,
        mouthCars, lastMonthCars,
        sumCars, carsRate,
        inComeCars, outComeCars, inFlagNum, outFlagNum,
        inFlag, outFlag
      }) => {
        this.todayCars = todayCars
        this.yesterdayCars = yesterdayCars
        this.mouthCars = mouthCars
        this.lastMonthCars = lastMonthCars
        this.sumCars = sumCars
        this.carsRate = String(carsRate).replace('%', '')
        this.inComeCars = inComeCars
        this.outComeCars = outComeCars
        this.inFlagNum = inFlagNum
        this.outFlagNum = outFlagNum
        this.inFlag = inFlag
        this.outFlag = outFlag
      })
      this.$api.get('/jeecg-boot/carFlow/getInOrCome')
      .then(({ inCarsNum, outCarsNum, inSpeed, outSpeed }) => {
        this.detectionList = [
          { name: '入场车辆', num: inCarsNum || 0, unit: '辆' },
          { name: '出场车辆', num: outCarsNum || 0, unit: '辆' },
          { name: '入场速度', num: inSpeed || 0, unit: '辆/时' },
          { name: '出场速度', num: outSpeed || 0, unit: '辆/时' },
        ]
      })
      this.$api.get('/jeecg-boot/carFlow/getCarsSortList')
      .then((list) => {
        const colorMaps = {
          0: '#C1FC05',
          1: 'rgba(254, 124, 2, 1)',
          2: 'rgba(50, 243, 250, 1)',
          3: 'rgb(247, 86, 55)'
        }
        let total = 0
        for(let i = 0; i<list.length; i++){
            total = total+list[i].carsNum
        }
        this.typeList = list.map((item, idx) => ({
          carSort: item.carSort,
          carsNum: item.carsNum,
          color: colorMaps[idx],
          rate: item.carsNum * 100 / total,
        }))
        console.log(total, list, 'this.typeList');
      })
    },
    restart() {
      this.$refs.todayNum.start()
      this.$refs.yesterdayNum.start()
      this.$refs.monthNum.start()
      this.$refs.monthRateNum.start()
      this.$refs.totalNum.start()
      this.$refs.lastMonthNum.start()
      this.$refs.outNum.start()
      this.$refs.inNum.start()
      this.$refs.lastInNum.start()
      this.$refs.lastOutNum.start()
      this.$refs.dnum && this.$refs.dnum[0] && this.$refs.dnum[0].start()
      this.$refs.dnumOne && this.$refs.dnumOne[0] && this.$refs.dnumOne[0].start()
      this.$refs.dnumTwo && this.$refs.dnumTwo[0] && this.$refs.dnumTwo[0].start()
      this.$refs.dnumThree && this.$refs.dnumThree[0] && this.$refs.dnumThree[0].start()
      this.active = true
      setTimeout(() => {
        this.active = false
      }, 5000)
    },
    formatNum(num) {
      return toThousands(num)
    },
    back() {
      this.$router.back(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.left_main {
    .back {
      background: url("../../assets/images/park/back@2x.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: 440px;
      top: 70px;
      z-index: 10000;
      width: 75px;
      height: 45px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 37px;
      letter-spacing: 2px;
      text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    @keyframes bounceIn {
      0% {
        left:  -550px
      }
      100% {
        left: 0
      }
    }
    @keyframes bounceOut {
      0% {
        left: 0
      }
      100% {
        left: -550px
      }
    }
    &.hide {
      animation: bounceOut 2s;
      left: -550px
    }
    position: absolute;
    z-index: 999;
    animation: bounceIn 2s;
  .total, .type, .detection {
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      font-family: 'MicrosoftYaHei-Bold'
    }
  }
  .total {
    margin-left: 10px;
    background: url("../../assets/images/park/totle_bg@2x.png") no-repeat;
    background-size: contain;
    width: 409px;
    height: 364px;
    margin-top: 60px;
    padding-top: 5px;
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      font-family: 'MicrosoftYaHei-Bold'
    }
    .bottom {
      margin-top: 31px;
    }
    .top {
      margin-top: 30px;
      align-items: baseline;
    }
    .top, .bottom {
      display: flex;
      text-align: left;
      padding-left: 30px;
      justify-content: space-around;
      .item {
        width: 30%;
        .name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #CFDBE9;
        }
        .num {
          font-size: 26px;
          font-family: Impact;
          font-weight: 400;
          color: #CFDBE9;
          margin: 0;
          margin-top: 5px;
          .unit {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #A0ACBA;
          }
        }
      }
    }
    .footer {
      display: flex;
      margin-top: 25px;
      justify-content: space-around;
      text-align: left;
      .item {
        text-align: left;
      }
      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #CFDBE9;
        img {
          width: 60px;
          height: 21px;
          margin-left: 10px;
        }
      }
      .num {
        font-size: 26px;
        font-family: Impact;
        font-weight: 400;
        color: #00B7F8;
        margin: 15px 0;
        line-height: 1;
        .unit {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #A0ACBA;
          margin-left: 5px;
        }
      }
      .description {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #CFDBE9;
        display: flex;
        align-items: center;
        line-height: 1;
        height: 20px;
        img {
          width: 10px;
          height: 16px;
          margin: 0 5px;
          position: relative;
        }
        .add {
          @keyframes up {
            0% {
              top: 2px
            }
            100% {
              top: -2px
            }
          }
          top: 0;
          animation: up 2s alternate infinite;
        }
        .minus {
          @keyframes down {
            0% {
              top: -2px
            }
            100% {
              top: 2px
            }
          }
          top: 0;
          animation: down 2s alternate infinite;
        }
        .num {
          font-size: 20px;
          font-family: DIN;
          font-weight: bold;
          color: #F75637;
        }
      }
    }
  }
  .type {
    margin-left: 10px;
    background: url("../../assets/images/park/type_bg@2x.png") no-repeat;
    background-size: contain;
    width: 409px;
    height: 228px;
    padding-left: 36px;
    .title {
      padding-top: 5px;
      padding-right: 30px;
    }
    .types {
      margin-left: 10px;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      height: 64%;
      margin-top: 20px;
      .item {
        display: flex;
        align-items: center;
        // margin-top: 30px;
        .name {
          font-size: 16px;
          font-family: 'Microsoft YaHei';
          font-weight: bold;
          color: #CFDBE9;
        }
        .num {
          font-family: 'DIN';
          font-weight: 400;
          margin: 0 20px;
          min-width: 45px;
        }
        .process {
          width: 188px;
          height: 4px;
          background: #1E4374;
          border-radius: 2px;
          @keyframes progressIn {
            0% {
              width: 0
            }
          }
          .process_bar {
            height: 4px;
            border-radius: 2px;
            &.process_bar_active {
              animation: progressIn 5s linear;
            }
          }
        }
      }
    }
  }
  .detection {
    margin-left: 10px;
    background: url("../../assets/images/park/detection_bg@2x.png") no-repeat;
    background-size: contain;
    width: 408px;
    height: 373px;
    margin-top: 10px;
    padding-top: 8px;
    .list {
      .item {
        .name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #CFDBE9;
        }
        .unit {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9FABBA;
        }
        .num {
          font-size: 22px;
          font-family: Impact;
          font-weight: 400;
          color: #CFDBE9;
          margin-top: 10px;
        }
      }
      display: flex;
      margin-bottom: 12px;
      justify-content: space-between;
      padding: 0 25px;
      margin-top: 30px;
    }
  }
}


</style>
