<template>
  <div class="content">
    <div class="card-title">拥堵路段</div>
    <div class="blockup">
      <span class="blockup-count">1号路口</span>
      <span class="blockup-detail">拥堵</span>
    </div>
    <div class="blockup">
      <span class="blockup-count">2号路口</span>
      <span class="blockup-detail">拥堵</span>
    </div>
    <div class="blockup">
      <span class="blockup-count">3号路口</span>
      <span class="blockup-detail">拥堵</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 283px;
  padding: 50px 34px 0;
  background: {
    image: url("../../assets/images/carMonitor/yong_du_bg.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }
}

.blockup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid;
  border-color: rgba($color: #fff, $alpha: 0.33);

  .blockup-count {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
  }

  .blockup-detail {
    font-size: 26px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #da115d;
  }
}
</style>
