<template>
  <div class="content">
    <div class="card-title">流量统计</div>
    <div class="tourist">
      <div>
        <div class="tourist-white">今日游客</div>
        <div class="tourist-green">约10000</div>
      </div>
      <div>
        <div class="tourist-white">人流量</div>
        <div class="tourist-yellow">456/小时</div>
      </div>
      <div>
        <div class="tourist-white">累计人数</div>
        <div class="tourist-blue">2000亿</div>
      </div>
    </div>

    <div style="height: 160px">
      <div id="myChart" style="height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      const option = {
        grid: {
          left: 0,
          top: 30,
          bottom: 0,
          right: 0,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
          axisLabel: {
            color: "#83A5E1",
            fontSize: 12,
            fontFamily: "MicrosoftYaHei",
          },
          axisTick: {
            show: false,
          },
           axisLine: {
            show: true,
            lineStyle: {
              color: "#375D9E"
            }
          },
        },
        yAxis: {
          type: "value",
          show: true,
          axisLabel: {
            color: "#83A5E1",
            fontSize: 12,
            fontFamily: "MicrosoftYaHei",
          },
          
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#375D9E"
            }
          },
        },
        series: [
          {
            data: [
              820,
              932,
              901,
              934,
              1290,
              1330,
              1320,
              901,
              934,
              1290,
              1330,
              1320,
            ],
            type: "line",
            showSymbol: false,
            lineStyle: {
              color: "#EEA72C",
              width: 4
            }
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  width: 409px;
  height: 348px;
  padding: 66px 43px 0 32px;
  background: {
    image: url("../../assets/images/home/box1.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }
}

.tourist {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 100px;
    height: 80px;
    background: rgba($color: #186eb7, $alpha: 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tourist-white {
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .tourist-green {
    color: #41cf3e;
    font-size: 22px;
  }

  .tourist-yellow {
    color: #ebd019;
    font-size: 22px;
  }

  .tourist-blue {
    color: #3e89cf;
    font-size: 22px;
  }
}
</style>
