<template>
  <div class="distribute">
    <div class="title">热点区域密集度</div>
    <div class="legend legend_left" />
    <div class="legend legend_right" />
    <div id="currentChart" style="height: 260px"></div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      chart: null,
      option: {},
      list: [],
      list1: []
    }
  },
  props: ['isChecked', 'refreshNum'],
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$api.get('/jeecg-boot/passenger/flow/distribution')
      .then(({ data = [] }) => {
        const list = data || []
        this.list = list.map(item => [(item.find(each => each.type === '本周') || {}).groupName, (item.find(each => each.type === '本周') || {}).flowInNum])
        this.list1 = list.map(item => [(item.find(each => each.type === '本月') || {}).groupName, (item.find(each => each.type === '本月') || {}).flowInNum])
        this.drawLine()
      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("currentChart"));
      this.option = {
          tooltip: {
            show: true,
            triggerOn: 'mousemove|click'
          },
          legend: {
            data: ['本周', '本月'],
            textStyle: {
                color: '#83A5E1',
                fontSize: 13,
                width: 40
            },
            right: 35,
            top: 15,
            icon: 'none',
          },
          xAxis: {
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(55, 93, 158, 1)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 10,
                fontFamily: 'MicrosoftYaHei',
                interval: 0,
                // rotate:"25",
                formatter: function(val) {
                  var strs = val.split(''); //字符串数组
                  var str = ''
                  for(var i = 0, s; s = strs[i++];) { //遍历字符串数组
                    str += s;
                    if(!(i % 5)) str += '\n'; //按需要求余
                  }
                  return str
                }


              },
              type: 'category',

          },
          yAxis: {
              type: 'value',
              name: '(人)',
              nameTextStyle: {
                fontSize: 10,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)'
              },
              min: 0,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(55, 93, 158, 1)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.list1,
              type: 'bar',
              name: '本月',
              barGap: 0,
              smooth: true,
              barWidth: 15,
              itemStyle: {
                barBorderRadius: [3, 3, 0, 0],
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(50, 243, 250, 1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(57, 214, 243, 0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              }
          },
          {
              data: this.list,
              type: 'bar',
              name: '本周',
              smooth: true,
              barWidth: 15,
              itemStyle: {
                barBorderRadius: [3, 3, 0, 0],
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(244, 120, 11, 1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(244, 120, 11, 0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              }
          }]
      };
      this.chart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.distribute {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
   // font-weight: normal;
   font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 40px;
    height: 40px;
  }
  background: url("../../assets/images/abortion/distribute.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 278px;
  margin-top: 12px;
  .total {
     margin-top: 29px;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #CFDBE9;
    }
    .unit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8995A4;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      margin: 0 8px;
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
  }
  .legend_left {
    right: 150px;
    top: 65px;
    background:rgba(240, 119, 13, 1)
  }
  .legend_right {
    right: 85px;
    top: 65px;
    background: rgba(0, 255, 255, 1)
  }
}


</style>
