<template>
  <div class="rank bgImg">
    <div class="title">区域客流量排行</div>
    <div class="rank_list">
      <div class="rank_sub_title">
        <div class="item rank_num">排名</div>
        <div class="item name">区域名称</div>
        <div class="item average">最大限制</div>
        <div class="item room">人数</div>
        <div class="item percent">满载率</div>
      </div>
      <div class="rank_item" v-for="(item, idx) in list" :key="idx">
          <div class="item rank_num">
            <img v-if="idx === 0" src="../../assets/images/homestay/rank_one@2x.png" />
            <img v-if="idx === 1" src="../../assets/images/homestay/rank_two@2x.png" />
            <img v-if="idx === 2" src="../../assets/images/homestay/rank_three@2x.png" />
            <span v-if="idx > 2">{{ idx + 1 }}</span>
          </div>
          <div class="item name">{{ item.areaName }}</div>
          <div class="item average">{{ item.maxLimit }}人</div>
          <div class="item room">{{ item.peopleNumber }}人</div>
          <div class="item percent">{{ item.loadFactor }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/passenger/flow/rank')
      .then(({ data }) => {
        this.list = data
      })
    },
  }
};
</script>
<style lang="scss" scoped>

.rank {
  background: url("../../assets/images/abortion/rank.png") no-repeat;
  width: 409px;
  height: 307px;
  margin-top: 19px;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 40px;
  }
  .rank_list {
    width: 363px;
    margin: 0 auto;
    .rank_sub_title {
      display: flex;
      font-size: 13px;
      color: #2B88E7;
      justify-content: space-around;
      align-items: center;
      height: 30px;
      font-weight: 400;
      .item {
        text-align: center;
      }
    }
    .rank_num {
      margin-left: 20px;
    }
    .percent, .room, .average {
      width: 40px;
      margin: 0 15px 0 0;
    }
    .average {
      width: 60px;
    }
    .name {
      flex: 1
    }
    .rank_item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 29px;
      margin-bottom: 16px;
      font-size: 13px;
      color: #B8D0F5;
      font-weight: 400;
      .rank_num {
        font-weight: bold;
        width: 18px;
        height: 18px;
        background: #1C4997;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #B8D0F5;
      }
      img {
        width: 17px;
        height: 22px;
        margin-bottom: 2px;
      }
      .item {
        text-align: center;
      }
    }
  }
}




</style>
