<template>
  <div class="baseInfo bgImg">
    <div class="title">芦茨馆收益</div>
    <div class="top">
      <div class="item" v-for="(info, idx) in numInfo" :key="info.name">
        <div class="name">{{ info.name }}</div>
        <div
          class="num"
          :style="{ color: idx === 2 ? 'rgba(50, 243, 250, 1)' : '#fff' }"
        >
          <countTo
            v-if="idx === 0"
            ref="num"
            :startVal="0"
            :endVal="info.num"
            :duration="4000"
            style="min-width: 45px"
          ></countTo>
          <countTo
            v-if="idx === 1"
            ref="numOne"
            :startVal="0"
            :endVal="info.num"
            :duration="4000"
            style="min-width: 65px"
          ></countTo>
          <countTo
            v-if="idx === 2"
            ref="numTwo"
            :startVal="0"
            :endVal="info.num"
            :duration="4000"
            style="min-width: 65px"
          ></countTo>
          <span
            class="unit"
            :style="{ color: idx === 2 ? 'rgba(50, 243, 250, 1)' : '#fff' }"
            >/{{ info.unit }}</span
          >
        </div>
      </div>
    </div>
    <div class="bottom" style="display: flex; justify-content: center">
      <div class="item" v-for="(info, idx) in rateInfo" :key="info.name">
        <div class="name">{{ info.name }}</div>
        <div
          class="num"
          :style="{ color: idx === 0 ? 'rgba(50, 243, 250, 1)' : '#fff' }"
        >
          <countTo
            v-if="idx === 0"
            ref="bnum"
            :decimals="1"
            :startVal="0"
            :endVal="info.num"
            :duration="4000"
            style="min-width: 55px"
          ></countTo>
          <countTo
            v-if="idx === 1"
            ref="bnumOne"
            :decimals="1"
            :startVal="0"
            :endVal="info.num"
            :duration="4000"
            style="min-width: 65px"
          ></countTo>
          <countTo
            v-if="idx === 2"
            ref="bnumTwo"
            :decimals="1"
            :startVal="0"
            :endVal="info.num"
            :duration="4000"
            style="min-width: 55px"
          ></countTo>
          <span
            class="unit"
            :style="{ color: idx === 0 ? 'rgba(50, 243, 250, 1)' : '#fff' }"
            >/{{ info.unit }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";

export default {
  props: ["isChecked", "refreshNum"],
  mounted() {
    this.getData();
  },
  components: {
    countTo,
  },
  data() {
    return {
      homeCount: 40,
      houseCount: 40,
      emptyHouse: 40,
      incomeToday: 1,
      incomeMonth: 1,
      fullPercent: 1,
    };
  },
  computed: {
    numInfo: function () {
      return [
        {
          name: "民宿总数",
          num: 188,
          unit: "个",
        },
        {
          name: "房间总数",
          num: 2256,
          unit: "间",
        },
        {
          name: "空余房间",
          num: 1634,
          unit: "间",
        },
      ];
    },
    rateInfo: function () {
      return [
        {
          name: "今日收益",
          num: "22.8",
          unit: "元",
        },
        {
          name: "本月收益",
          num: "634.14",
          unit: "元",
        },
        /* {
          name: '住满率', num: this.fullPercent * 100, unit: '%'
        }*/
      ];
    },
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    restart() {
      this.$refs.num[0].start();
      this.$refs.numOne[0].start();
      this.$refs.numTwo[0].start();
      this.$refs.bnum[0].start();
      this.$refs.bnumOne[0].start();
      this.$refs.bnumTwo[0].start();
    },
    getNum(num, index) {
      let numArr = String(num).split("");
      if (numArr.length === 2) numArr = [0].concat(numArr);
      if (numArr.length === 1) numArr = [0, 0].concat(numArr);
      return numArr[index];
    },
    getData() {
      this.$trueApi.post("/LuciHomestay/getLuciIncome").then(({ data }) => {
        const {
          roomTotalCount,
          spareRoomCount,
          homestayStorTotalCount,
          fullRate,
          incomeMonth,
          incomeToday,
        } = data;
        return;
        this.homeCount = roomTotalCount;
        this.emptyHouse = spareRoomCount;
        this.houseCount = homestayStorTotalCount;
        this.fullPercent = fullRate;
        this.incomeMonth = incomeMonth;
        this.incomeToday = incomeToday;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.baseInfo {
  background: url("../../assets/images/homestay/baseInfo_bg@2x.png") no-repeat;
  width: 409px;
  height: 206px;
  padding: 0 21px 0 24px;
  .title {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    //font-weight: normal;
    font-weight: bold;
    color: #ffffff;
    line-height: 32px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
  }
  .top,
  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    margin-left: 20px;
    .item {
      text-align: left;
      width: 33%;
      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #cfdbe9;
      }
      .num {
        font-size: 26px;
        font-family: Impact;
        font-weight: 400;
        color: #ffffff;
        margin-top: 5px;
        display: flex;
        align-items: baseline;
      }
      .unit {
        font-size: 10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 3px;
      }
    }
  }
  .bottom {
    margin-top: 20px;
  }
}
</style>
