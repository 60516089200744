<template>
  <div :class="['right_main', { hide: isChecked }]">
    <CarFlowBig :refreshNum="refreshNum"/>
    <Device :refreshNum="refreshNum"/>
  </div>
</template>

<script>
import CarFlowBig from './car-flow-big'
import Device from './device';

export default {
  props: ["isChecked", 'refreshNum'],
  components: {
    CarFlowBig,
    Device,
  },
};
</script>

<style lang="scss" scoped>
.right_main {
  width: 408px;
  position: relative;
  animation: bounceIn 4s;
  @keyframes bounceIn {
    0% {
      right: -500px;
    }
    100% {
      right: 0;
    }
  }
  @keyframes bounceOut {
    0% {
      right: 0;
    }
    100% {
      right: -500px;
    }
  }
  &.hide {
    animation: bounceOut 4s;
    right: -500px;
  }
}
</style>
