<template>
  <div class="center">
    <img class="guang" src="../../assets/images/weather/guang@2x.png"/>
    <div class="weather">{{ temp }}<span class="unit">℃</span></div>
    <div class="img"><img :src="imgPng" alt="天气情况"/></div>
    <div :class="['red', { red_active: active }]"/>
    <div :class="['detail', { detail_active: active }]">{{ tip }}</div>
    <div class="pollute">{{ category }}</div>
    <div :class="['wind each', { each_active: active }]">
      <div class="name">{{ windDir }}</div>
      <div class="num">{{ windScale }}<span class="unit">级</span></div>
    </div>
    <div :class="['airPressure each', { each_active: active }]">
      <div class="name">气压</div>
      <div class="num">{{ pressure }}<span class="unit">HPa</span></div>
    </div>
    <div :class="['humidity each', { each_active: active }]">
      <div class="name">湿度</div>
      <div class="num">{{ humidity }}<span class="unit"> %RH</span></div>
    </div>
    <div :class="['airQuality each', { each_active: active }]">
      <div class="name">空气质量</div>
      <div class="num">
        {{ aqi }}<span class="unit">{{ category }}</span>
      </div>
    </div>
    <div :class="['pm each', { each_active: active }]">
      <div class="name">PM2.5</div>
      <div class="num">{{ pm2p5 }}<span class="unit">ug/cm³</span></div>
    </div>
    <div :class="['list', { list_active: active }]">
      <div class="item" v-for="(item, idx) in list" :key="idx">
        <div class="address">
          {{ item.name }}<img :src="imgPngList[idx]" alt="天气情况"/>
        </div>
        <div class="list-weather">
          {{ item.temp }}<span class="unit">℃</span>
        </div>
        <!-- <div class="list-wind">{{ item.windDir }}{{ item.windScale }}级</div> -->
        <div class="list-wind">负氧离子{{ listCo[idx].co }}个/cm³</div>
        <div class="list-wind">空气质量{{ listCo[idx].aqi }} {{ listCo[idx].category }}</div>
        <div class="line">
          <div class="process"/>
        </div>
        <div class="line-left">
          <div class="process"/>
        </div>
        <div class="line-bottom">
          <div class="process"/>
        </div>
        <div class="line-right">
          <div class="process"/>
        </div>
      </div>
    </div>
    <!-- <div class="donghua">
      <svg width="181" height="181">
        <g>
          <line stroke-dasharray="5, 5" x1="10" y1="10" x2="390" y2="10" />
          <animateTransform attributeName="transform" begin="0s" dur="10s" type="rotate" from="0 160 160" to="360 160 160" repeatCount="indefinite"/>
        </g>
      </svg>
    </div> -->
  </div>
</template>
<script>
import cloudPng from "../../assets/images/weather/cloud_small@2x.png";
import rainPng from "../../assets/images/weather/rain_big@2x.png";
import sunPng from "../../assets/images/weather/sun_small@2x.png";
import minRainPng from "../../assets/images/weather/min_rain_small@2x.png";

export default {
  data() {
    return {
      active: false,
      aqi: 0,
      level: 0,
      category: 0,
      pm2p5: 0,
      co: 0,
      temp: 0,
      windDir: 0,
      windScale: 0,
      windSpeed: 0,
      humidity: 0,
      pressure: 0,
      img: "",
      imgPng: "",
      imgPngList: [],
      list: [],
      listCo: []
    };
  },
  props: ["refreshNum", "tip"],
  mounted() {

    this.getMoreData();
    this.getData();
  },
  watch: {
    refreshNum: function () {
      this.active = true;
      setTimeout(() => {
        this.active = false;
      }, 6000);
    },
  },
  methods: {
    getData() {
      var self = this;

      this.$api.get("/jeecg-boot/weather/qualityNow").then(({now = {}}) => {
        this.aqi = now.aqi;
        this.level = now.level;
        this.category = now.category;
        this.pm2p5 = now.pm2p5;
        this.co = now.co;
      });
      this.$api.get("/jeecg-boot/weather/quality").then((res) => {
        for (let i in res) {
          this.listCo.push(res[i].now);
        }
        console.log(this.listCo, 222222)

        self.listCo.forEach(function (e, idx) {
          e.co =parseInt( Math.random().toFixed(2) * 1100 + 100)
        })
        var time2 = setInterval(function () {
          if (self.listCo) {
            self.listCo.forEach(function (e, idx) {
              e.co =parseInt( Math.random().toFixed(2) * 1100 + 100)
            })
          } else {
            clearInterval(time2)
          }

        }, 60 * 1000)

      });
    },
    getMoreData() {
      this.$api.get("/jeecg-boot/weather/weatherNow").then(({now = {}}) => {
        this.temp = now.temp;
        this.windDir = now.windDir;
        this.windScale = now.windScale;
        this.windSpeed = now.windSpeed;
        this.humidity = now.humidity;
        this.pressure = now.pressure;
        this.imgPng = require(`../../assets/images/color-128/${now.icon}.png`);
      });
      this.$api.get("/jeecg-boot/weather/weather").then((res) => {
        console.log(res, "----");
        for (let i in res) {
          this.list.push(res[i].now);
          this.imgPngList.push(
              require(`../../assets/images/color-128/${res[i].now.icon}.png`)
          );
        }
        this.list[0].name = "杭州";
        this.list[1].name = "上海";
        this.list[2].name = "南京";
        console.log(this.list, this.imgPngList);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  position: absolute;
  top: 105px; //原来是105
  left: 290px;
  right: 410px;

  .guang {
    width: 371px;
    height: 259px;
  }

  .weather {
    position: absolute;
    top: 20px;
    left: 51%;
    font-size: 72px;
    font-family: Impact;
    font-weight: 400;
    color: #e4e4e4;

    .unit {
      font-size: 29px;
      vertical-align: super;
    }
  }

  .img {
    width: 54px;
    position: absolute;
    top: 100px;
    left: 630px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .red {
    background: url("../../assets/images/weather/red@2x.png");
    background-size: contain;
    width: 36px;
    height: 36px;
    left: 60%;
    top: 16px;
    position: absolute;

    &.red_active {
      animation: fadeIn 6s linear;
    }
  }

  .detail {
    background: url("../../assets/images/weather/detail@2x.png");
    background-size: contain;
    width: 221px;
    height: 182px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c5c7db;
    line-height: 28px;
    position: absolute;
    left: 63%;
    top: -20px;
    padding: 25px 30px 20px 40px;

    &.detail_active {
      animation: fadeIn 6s linear;
    }
  }

  @keyframes donghua {
    0% {
      opacity: 0;
      left: 630px;
      top: 160px;
    }
  }

  .pm,
  .wind,
  .airQuality,
  .airPressure,
  .humidity {
    position: absolute;
    text-align: left;

    &.each_active {
      animation: donghua 4s linear;
    }
  }

  .pm {
    top: 160px; //原来是160
    left: 910px;
  }

  .wind {
    top: 160px;
    left: 280px;
  }

  .airQuality {
    top: 260px;
    left: 800px;
  }

  .airPressure {
    top: 260px;
    left: 410px;
  }

  .humidity {
    top: 280px;
    left: 630px;
  }

  .pollute {
    position: absolute;
    top: 65px;
    left: 400px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cfdbe9;
  }

  .name {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cfdbe9;
  }

  .unit {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #9ea9b5;
    margin-left: 10px;
  }

  .num {
    font-size: 40px;
    font-family: DIN;
    font-weight: bold;
    color: #c9dce5;
  }

  .donghua {
    position: absolute;
    top: 420px;
    left: 180px;
    @keyframes lineMove {
      0% {
        stroke-dasharray: 0, 1350;
      }
      100% {
        stroke-dasharray: 1350, 1350;
      }
    }

    line {
      stroke-width: 5;
      stroke: #ff7700;
      animation: lineMove 5s linear forwards;
    }
  }

  .list {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 420px;
    left: 180px;
    right: 100px;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &.list_active {
      animation: fadeIn 6s linear;
    }

    .item {
      img {
        width: 28px;
        vertical-align: bottom;
        margin-left: 10px;
      }

      position: relative;

      .line {
        @keyframes topProcessIn {
          0% {
            left: 0px;
          }
          100% {
            left: 100%;
          }
        }
        position: absolute;
        top: 0;
        left: 15px;
        width: 149px;
        height: 2px;
        overflow: hidden;

        .process {
          position: absolute;
          left: 0;
          width: 50%;
          height: 100%;
          background-image: linear-gradient(
                  to right,
                  #175b79,
                  rgba(255, 255, 255, 0)
          );
          animation: topProcessIn 3s linear infinite;
        }
      }

      .line-left {
        @keyframes processIn {
          0% {
            top: 12px;
            height: 50%;
          }
          100% {
            top: 100%;
            height: 50%;
          }
        }
        position: absolute;
        top: 12px;
        left: 179px;
        width: 2px;
        height: 149px;
        overflow: hidden;

        .process {
          position: absolute;
          left: 0;
          width: 100%;
          height: 0;
          background-image: linear-gradient(
                  to bottom,
                  #175b79,
                  rgba(255, 255, 255, 0)
          );
          animation: processIn 3s 3s infinite;
        }
      }

      .line-bottom {
        @keyframes bottomProcessIn {
          0% {
            right: 0px;
            width: 50%;
          }
          100% {
            right: 100%;
            width: 50%;
          }
        }
        position: absolute;
        top: 179px;
        right: 15px;
        width: 146px;
        height: 2px;
        overflow: hidden;

        .process {
          position: absolute;
          right: 0;
          width: 0;
          height: 100%;
          background-image: linear-gradient(
                  to right,
                  #175b79,
                  rgba(255, 255, 255, 0)
          );
          animation: bottomProcessIn 3s 6s infinite;
        }
      }

      .line-right {
        @keyframes rightProcessIn {
          0% {
            bottom: 12px;
            height: 50%;
          }
          100% {
            bottom: 100%;
            height: 50%;
          }
        }
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 2px;
        height: 149px;
        overflow: hidden;

        .process {
          position: absolute;
          left: 0;
          width: 100%;
          height: 0;
          background-image: linear-gradient(
                  to bottom,
                  #175b79,
                  rgba(255, 255, 255, 0)
          );
          animation: rightProcessIn 3s 9s infinite;
        }
      }

      padding-top: 30px;
      width: 181px;
      height: 181px;
      background: url("../../assets/images/weather/list_bg.png");
      background-size: contain;

      .address {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #cfdbe9;
      }

      .list-weather {
        font-size: 32px;
        font-family: DIN;
        font-weight: bold;
        color: #c9dce5;
        margin: 10px 0;

        .unit {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #c9dce5;
          vertical-align: super;
        }
      }

      .list-wind {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a2afbe;
      }
    }
  }
}
</style>
