<template>
  <div class="statistics">
    <div class="title card-title"><router-link to="/abortion">游客统计</router-link></div>
    <div class="top">
      <div class="item">
        <div class="name" style="color: #fff">今日游客</div>
        <div class="num" style="color: rgba(50, 243, 250, 1)">
          <countTo ref="snum" :startVal='0' :endVal='132' :duration='4000' style="min-width: 50px;text-align: right"></countTo>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="item">
        <div class="name">本月游客</div>
        <div class="num">
            <countTo ref="snumOne" :startVal='0' :endVal='2870' :duration='4000' style="min-width: 50px;text-align: right"></countTo>
            <span class="unit">人</span>
        </div>
      </div>
      <div class="item">
        <div class="name">本年游客</div>
        <div class="num">
          <countTo ref="snumTwo" :startVal='0' :endVal='25378' :duration='4000' style="min-width: 60px;text-align: right"></countTo>
          <span class="unit">人</span>
        </div>
      </div>
    </div>
    <!-- <div class="sub-title">天然浴场人数实时监控</div>
    <div class="bottom">
      <div>近30分钟游客数：{{ realTimeCount }}</div>
      <div>今日人数：{{ today }}</div>
    </div> -->
    <div class="sub-title">天然浴场人数近30分钟监控</div>
    <div class="sub-title">客流量 : {{ 28 }}</div>
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import toThousands from '../../utils/toThousands'

export default {
  props: ['isChecked', 'refreshNum'],
  mounted() {
    this.getData()
  },
  data() {
    return {
      today: 0,
      month: 0,
      year: 0,
      realTimeCount: 0
    }
  },
  components: {
    countTo
  },
  methods: {
    restart() {
      if (this.isChecked) {
        this.$refs.snum.start()
        this.$refs.snumOne.start()
        this.$refs.snumTwo.start()
      }
    },
    formatNum(num) {
      return toThousands(num)
    },
    getData() {
      this.$api.get('/jeecg-boot/passenger/flow/summary')
      .then(({ data: { monthlyCount, yearlyCount, dailyCount, realTimeCount } }) => {
        this.year = yearlyCount
        this.month = monthlyCount
        this.today = dailyCount
        this.realTimeCount = realTimeCount
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.statistics {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    // font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    top: 8px;
  }
  background: url("../../assets/images/home/park_bg@2x.png") no-repeat;
  background-size: contain;
  width: 410px;
  height: 168px;
  margin-top: 15px;
  .top {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: left;
    .item {
      min-width: 100px;
    }
    .name {
      font-size: 16px;
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      color: #CFDBE9;
      margin-bottom: 5px;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      display: flex;
      align-items: baseline;
      .unit {
        font-size: 14px;
        font-family: 'Microsoft YaHei';
        font-weight: 400;
        color: #B5C0CD;
        margin-left: 5px;
      }
    }
  }
  .sub-title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .bottom {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
  }
}


</style>
