<template>
  <div class="statistics">
    <div class="title">人口统计</div>
    <div class="top">
      <div class="item">
        <div class="name">总人口</div>
        <div class="num" style="color: #32F3FA;">
          <countTo ref="num" :startVal='0' :endVal='1429' :duration='4000'></countTo>
        </div>
      </div>
      <div class="item">
        <div class="name">本地居民</div>
        <div class="num">
          <countTo ref="localNum" :startVal='0' :endVal='1210' :duration='4000'></countTo>
        </div>
      </div>
      <div class="item">
        <div class="name">外来人口</div>
        <div class="num">
          <countTo ref="externalNum" :startVal='0' :endVal='219' :duration='4000'></countTo>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="item">
        <div class="title">低保人员</div>
        <div class="num" style="color: #C1FC05">
          <countTo ref="basicLiving" :startVal='0' :endVal='lowQualityPeople' :duration='4000'></countTo>
        </div>
        <div class="process"><div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background':'linear-gradient(90deg, rgba(193, 252, 5, 0) 0%, #C1FC05 100%)','width': `${lowQualityPeople * 100 / total}%`}"/></div>
      </div>
      <div class="item">
        <div class="title">党员人员</div>
        <div class="num" style="color: rgba(254, 124, 2, 1)">
          <countTo ref="party" :startVal='0' :endVal='partyMember' :duration='4000'></countTo>
        </div>
        <div class="process"><div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background':'linear-gradient(90deg, rgba(244, 120, 11, 0) 0%, #F4780B 100%)','width': `${partyMember * 100 / total}%`}"/></div>
      </div>
      <div class="item">
        <div class="title">志愿之家</div>
        <div class="num" style="color: rgba(50, 243, 250, 1)">
          <countTo ref="volunteer" :startVal='0' :endVal='volunteer' :duration='4000'></countTo>
        </div>
        <div class="process"><div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background':'linear-gradient(90deg, rgba(47, 224, 233, 0) 0%, #32F3FA 100%)','width': `${volunteer * 100 / total}%`}"/></div>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import toThousands from '../../utils/toThousands'

export default {
  components: {
    countTo
  },
  data() {
    return {
      active: false
    }
  },
  props: ['foreignPeople', 'totalPopulation', 'localPeople', 'lowQualityPeople', 'partyMember', 'volunteer','isChecked', 'refreshNum'],
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  computed: {
    total: function() {
      return this.lowQualityPeople + this.partyMember + this.volunteer
    }
  },
  methods: {
    restart() {
      this.$refs.num.start()
      this.$refs.localNum.start()
      this.$refs.externalNum.start()
      this.$refs.basicLiving.start()
      this.$refs.party.start()
      this.$refs.volunteer.start()
      this.active = true
      setTimeout(() => {
        this.active = false
      }, 6000)
    },
    formatNum(num) {
      return toThousands(num)
    }
  }
}
</script>
<style lang="scss" scoped>
.statistics {
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #FFFFFF;
    line-height: 32px;
  }
  background: url("../../assets/images/housekeeper/demographic.png") no-repeat;
  background-size: contain;
  width: 408px;
  height: 359px;
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 29px;
    padding: 0 40px;
    .item {
        text-align: left;
        min-width: 65px;
       .name {
        font-size: 16px;
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: #CFDBE9;
       }
       .num {
          font-size: 26px;
          font-family: 'Impact';
          font-weight: 400;
          color: #CFDBE9;
          line-height: 1;
          margin-top: 15px;
       }
    }
  }
  .bottom {
     margin-top: 30px;
     padding: 0 40px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 11px;
      .title {
        font-size: 16px;
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: #8D9BAB;
        min-width: 70px;
      }
      .num {
        font-family: 'DIN';
        font-weight: 400;
        margin: 0 20px;
      }
      .process {
        width: 188px;
        height: 4px;
        background: #1E4374;
        border-radius: 2px;
        @keyframes fadeIn {
          0% {
            width: 0
          }
        }
        .process_bar {
          height: 4px;
          border-radius: 2px;
          &.process_bar_active {
            animation: fadeIn 4s linear;
          }
        }
      }
    }
  }
}


</style>
