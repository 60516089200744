<template>
  <div class="car-enter-container">
    <!-- <div class="card-title">车辆出入检测</div> -->
    <!-- <div class="echarts" id="carEnterChart"></div> -->
    <div class="echart-wrap">
      <Bubble :chartData="mData.chartData" style="height: 100%" />
    </div>
    <div class="grid">
      <div class="grid-item">
        <img src="../../assets/images/home/rccl.png" />
        <div class="flex-column">
          <div>入场车辆</div>
          <div class="check-txt">
            <span class="num">{{ mData.enterCarNum }}</span>
            <span class="unit">辆</span>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <img src="../../assets/images/home/fxsd.png" />
        <div class="flex-column">
          <div>放行速度</div>
          <div class="check-txt">
            <span class="num">{{ mData.goingSpeed }}</span>
            <span class="unit">辆/秒</span>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <img src="../../assets/images/home/rcsd.png" />
        <div class="flex-column">
          <div>入场速度</div>
          <div class="check-txt">
            <span class="num">{{ mData.enterSpeed }}</span>
            <span class="unit">辆/时</span>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <img src="../../assets/images/home/cccl.png" />
        <div class="flex-column">
          <div>出场车辆</div>
          <div class="check-txt">
            <span class="num">{{ mData.outCardNum }}</span>
            <span class="unit">辆</span>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <img src="../../assets/images/home/lcsd.png" />
        <div class="flex-column">
          <div>离场速度</div>
          <div class="check-txt">
            <span class="num">{{ mData.outSpeed }}</span>
            <span class="unit">辆/秒</span>
          </div>
        </div>
      </div>
      <div class="grid-item">
        <img src="../../assets/images/home/dcl.png" />
        <div class="flex-column">
          <div>堵车率</div>
          <div class="check-txt">
            <span class="num">{{ mData.trafficRate }}</span>
            <span class="unit">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Bubble from '@/components/park/bubble';

export default {
  components: {
    Bubble,
  },
  props: ['mData'],
};
</script>

<style lang="scss" scoped>

.car-enter-container {
  display: flex;
  flex-direction: column;
}

.introduce {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #83a5e1;
  line-height: 24px;
  text-align: left;
}

.echart-wrap {
  height: 65%;
}

.grid {
  display: grid;
  width: 100%;
  height: 35%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 0 10px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 40px;
    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
    }
  }
  .flex-column {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    margin-left: 8px;
    text-align: left;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8d0f5;
  }
  .num {
    font-size: 18px;
    margin-right: 2px;
  }
}

.check-txt {
  position: relative;
  display: table-cell;
  vertical-align: bottom;
  color: #00DEFF;
}

.echarts {
  width: 100%;
  flex: 1;
}
</style>
