<template>
  <div class="content">
    <div class="card-title title" @click="hideSafeMonitor">宣传片</div>
    <div class="video-wrap">
      <video class="video" id="video" ref="videoEl" src="https://shanhu.oss-cn-hangzhou.aliyuncs.com/client/anchu/luci/video/v1.mp4"></video>
      <img class="play-btn" ref="playBtn" @click="handlePlay" src="../../assets/images/home/play.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['adVideo', 'hideSafeMonitor', 'handlePlay'],
  mounted() {
    const videoEl = this.$refs.videoEl;
    const playBtn = this.$refs.playBtn;
    videoEl.addEventListener('play', () => {
      playBtn.style.display = 'none';
    });
    videoEl.addEventListener('pause', () => {
      playBtn.style.display = 'block';
    })
  },
  methods: {
    // handlePlay() {
    //   this.$refs.videoEl.play();
    //   this.$refs.playBtn.style.display = "none";
    // }
  }
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 272px;
  padding: 44px 20px 20px 20px;
  margin-top: 10px;
  background: {
    image: url('../../assets/images/home/ad-video.png');
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  .title {
    top: 8px;
    font-weight: bold;
  }
}
.video {
  width: 100%;
  height: 274px;
  position: absolute;
  object-fit: cover;
  top: -38px;
  bottom: 0;
  left: 0;
  right: 0;
}
.video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 2px solid #1C97FF;
  .play-btn {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
}
</style>
