<template>
  <div class="container">
    <head-top :isChecked="isChecked" title="天气实况" :refreshNum="refreshNum" :tip="tip" @changeChecked="handleChecked" />
    <div :class="['left', { 'hide': isChecked }]">
        <pm :isChecked="isChecked" :refreshNum="refreshNum"/>
        <anion :isChecked="isChecked" :refreshNum="refreshNum" />
        <wind :isChecked="isChecked" :refreshNum="refreshNum" :windList="windList" />
        <div class="back" @click="back">返回</div>
    </div>
    <div :class="['bottom', { 'hide': isChecked }]">
        <oxygen :isChecked="isChecked" :refreshNum="refreshNum" :now="now"/>
    </div>
    <div :class="['right', { 'hide': isChecked }]">
      <forecast :refreshNum="refreshNum" />
      <current :isChecked="isChecked" :refreshNum="refreshNum" :tempList="tempList" />
      <water :isChecked="isChecked" :refreshNum="refreshNum" :waterList="waterList" />
    </div>
    <center :refreshNum="refreshNum" :tip="tip" />
  </div>
</template>

<script>
import moment from "moment";
import getDate from "../utils/getDate"
import headTop from '@/components/weather/menu';
import pm from '@/components/weather/pm';
import anion from '@/components/weather/anion';
import wind from '@/components/weather/wind';
import oxygen from '@/components/weather/oxygen';
import forecast from '@/components/weather/forecast';
import current from '@/components/weather/current';
import water from '@/components/weather/water';
import center from '@/components/weather/center';

export default {
  components: {
    headTop,
    pm,
    anion,
    wind,
    oxygen,
    forecast,
    current,
    water,
    center
  },
  data() {
    return {
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
      isChecked: false,
      refreshNum: 0,
      tip: '明天降温18℃，一次大幅度降温，请提前做好出行准备',
      windList: [],
      tempList: [],
      waterList: [],
      now: {},
    };
  },
  mounted() {
    setInterval(() => {
      this.time = moment().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
    setInterval(() => {
      setTimeout(() => {
        this.getData()
        this.getCurrent()
      }, 0)
    }, 15000)
    this.getData()
    this.getCurrent()
    this.getTip()
  },
  methods: {
    getCurrent() {
      this.$api.get('/jeecg-boot/weather/qualityNow')
      .then(({ now }) => {
        this.now = now || {};
      })
    },
    getData() {
      this.$api.get('/jeecg-boot/weather/history7dWindy')
      .then(({ weatherHourly = [] }) => {
        this.windList = weatherHourly
          .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
          .map((item, index) => [getDate(item.time), item.windSpeed])
        this.waterList = weatherHourly
          .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
          .map((item, index) => [getDate(item.time), item.precip])
        this.tempList = weatherHourly
          .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
          .map((item, index) => [getDate(item.time), item.temp])
      })
    },
    getTip() {
      this.$api.get('/jeecg-boot/weather/lifeSlogan')
      .then(({ daily }) => {
        const { text } = (daily || [])[0] || {}
        this.tip = text
      })
    },
    handleChecked() {
      this.isChecked = !this.isChecked;
    },
    back() {
      this.$router.back(-1)
    }
  },
};
</script>

<style lang="scss" scoped>

 .container {
   width: 1920px;
   height: 100%;
   background: #011326;
   margin: 0 auto;
   padding: 0;
   z-index: 99999;
   position: relative;
   background: url("../assets/images/weather/bg.jpg") no-repeat;
   background-size: 100% 100%;
   .bgImg {
     background-size: contain;
   }
}
.back {
  background: url("../assets/images/park/back@2x.png") no-repeat;
  position: absolute;
  left: 440px;
  top: 24px;
  z-index: 10000;
  width: 75px;
  height: 45px;
  font-size: 15px;
  background-size: contain;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 37px;
  letter-spacing: 2px;
  transform: none;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.left {
    top: 74px;
  left: 28px;
  @keyframes bounceLeftIn {
    0% {
      left:  -520px
    }
    100% {
      left: 18px
    }
  }
  @keyframes bounceLeftOut {
    0% {
      left: 18px
    }
    100% {
      left: -520px
    }
  }
  &.hide {
    animation: bounceLeftOut 1s linear;
    left: -520px
  }
  position: absolute;
  z-index: 999;
  animation: bounceLeftIn 1s linear;
}

.right {
  position: absolute;
  right: 18px;
  top: 54px;
  z-index: 9999;
  @keyframes bounceRightIn {
    0% {
      right: -450px
    }
    100% {
      right: 18px;
    }
  }
  @keyframes bounceRightOut {
    0% {
      right: 18px;
    }
    100% {
      right: -450px
    }
  }
  &.hide {
    animation: bounceRightOut 1s linear;
    right: -450px
  }
  animation: bounceRightIn 1s linear;
}

.bottom {
  position: absolute;
  bottom: 15px;
  left: 446px;
  display: flex;
  z-index: 9999;
  @keyframes bounceBottomIn {
    0% {
      bottom: -400px
    }
    100% {
      bottom: 15px
    }
  }
  @keyframes bounceBottomOut {
    0% {
      bottom: 15px
    }
    100% {
      bottom: -400px
    }
  }
  &.hide {
    animation: bounceBottomOut 1s linear;
    bottom: -400px
  }
  animation: bounceBottomIn 1s linear;

}

</style>
