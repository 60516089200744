<template>
  <div class="rank bgImg">
    <div class="title">游客来源</div>
    <div class="content">
      <div class="content_title">排行榜</div>
      <div class="rank_list">
        <div class="rank_sub_title">
          <div class="item rank_num">排名</div>
          <div class="item name">地区</div>
          <div class="item average">百分比</div>
        </div>
        <div class="rank_item" v-for="(item, idx) in list" :key="idx">
          <div class="item rank_num">
            <img
              v-if="idx === 0"
              src="../../assets/images/homestay/rank_one@2x.png"
            />
            <img
              v-if="idx === 1"
              src="../../assets/images/homestay/rank_two@2x.png"
            />
            <img
              v-if="idx === 2"
              src="../../assets/images/homestay/rank_three@2x.png"
            />
            <span v-if="idx > 2">{{ idx + 1 }}</span>
          </div>
          <div class="item name">{{ item.city }}</div>
          <div class="item average">{{ item.rate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
    this.$api.get('/jeecg-boot/carFlow/getSourceCar')
      .then((data) => {
        this.list=data.sources
        this.list.splice(6,1)
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.rank {
  background: url("../../assets/images/abortion/distribute.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 278px;
  margin-top: 10px;
  .title {
    font-size: 16px;
    font-family: "Adobe Heiti Std";
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 40px;
  }
  .content {
    display: flex;
    margin: 0 auto;
    width: 367px;
    height: 212px;
    .content_title {
      width: 39px;
      height: 212px;
      background: linear-gradient(0deg, #0d4b93 0%, #0b57ac 100%);
      opacity: 0.6;
      border-radius: 5px 0px 0px 5px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #d4e7ff;
      line-height: 64px;
      padding: 10px;
      text-align: center;
    }
  }
  .rank_list {
    flex: auto;
    .rank_sub_title {
      display: flex;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2b88e7;
      justify-content: space-around;
      align-items: center;
      height: 29px;
      background: rgba(21, 49, 98, 0.6);
      border: 1px solid #1e4586;

      .item {
        text-align: center;
      }
    }
    .rank_num {
      margin-left: 20px;
    }
    .percent,
    .room,
    .average {
      width: 40px;
      margin: 0 15px 0 0;
    }
    .average {
      width: 60px;
    }
    .name {
      flex: 1;
    }
    .rank_item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 30px;
      background: rgba(21, 49, 98, 0.6);
      border: 1px solid #1e4586;
      font-size: 13px;
      color: #b8d0f5;
      font-weight: 400;
      &:nth-child(2n) {
        background: rgba(12, 38, 82, 0.6);
        border: 1px solid #1e4586;
      }
      .rank_num {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #b8d0f5;
      }
      img {
        width: 17px;
        height: 22px;
        margin-bottom: 2px;
      }
      .item {
        text-align: center;
      }
    }
  }
}
</style>
