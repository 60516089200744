<template>
  <div class="container">
    <!-- <div class="mark" /> -->
    <head-top :isChecked="isChecked" title="芦茨村务" @changeChecked="handleChecked" />
    <div :class="['left', { 'hide': isChecked }]">
        <statistics-info
          :foreignPeople="foreignPeople"
          :totalPopulation="totalPopulation"
          :localPeople="localPeople"
          :isChecked="isChecked"
          :lowQualityPeople="lowQualityPeople"
          :partyMember="partyMember"
          :volunteer="volunteer"
          :refreshNum="refreshNum"
        />
        <!-- <people-num :totalIncrement="totalIncrement" :isChecked="isChecked" :refreshNum="refreshNum"/>
        <home :isChecked="isChecked" :refreshNum="refreshNum"/> -->
        <redHeiBang></redHeiBang>
        <div class="back" @click="back">返回</div>
    </div>
    <div :class="['bottom', { 'hide': isChecked }]">
     
        <!-- <honour :refreshNum="refreshNum" /> -->
        <construction :refreshNum="refreshNum" />

    </div>
    <div :class="['right', { 'hide': isChecked }]">
      <expense :totalPay="totalPay" :isChecked="isChecked" :refreshNum="refreshNum" />
      <garbage :totalRubbish="totalRubbish" :disposeRubbish="disposeRubbish" :rightPercent="rightPercent" :isChecked="isChecked" :refreshNum="refreshNum" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import headTop from '@/components/park/menu';
import statisticsInfo from '@/components/housekeeper/statisticsInfo';
import peopleNum from '@/components/housekeeper/peopleNum';
import home from '@/components/housekeeper/home';
import honour from '@/components/housekeeper/honour';
import construction from '@/components/housekeeper/construction';
import expense from '@/components/housekeeper/expense';
import garbage from '@/components/housekeeper/garbage';
import redHeiBang from '@/components/housekeeper/redHeiBang';
//mport engineering from '@/components/housekeeper/engineering'
export default {
  components: {
    headTop,
    statisticsInfo,
    peopleNum,
    home,
    honour,
    construction,
    expense,
    garbage,
    redHeiBang,
   // engineering
  },
  data() {
    return {
      refreshNum: 0,
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
      isChecked: false,
      foreignPeople: null,
      totalPopulation: null,
      localPeople: null,
      lowQualityPeople: null,
      partyMember: null,
      volunteer: null,
      totalIncrement: null,
      totalPay: null,
      officialPay: null,
      workerPay: null,
      cadrePay: null,
      totalRubbish: null,
      disposeRubbish: null,
      pullYield: null,
      rightPercent: null,
      allDatas: {
        peopleInfo: {
          total: 6890,
          local: 3890,
          external: 3000,
          basicLiving: 234,
          party: 689,
          volunteer: 1368
        },
        numInfo: {
          total: 1089
        },
        homeInfo: {},
        honourInfo: {
          list: [
            { rank: 1, name: '陈君策', property: '属性', description: '属性详情' },
            { rank: 2, name: '陈海波', property: '属性', description: '属性详情' },
            { rank: 3, name: '沈一凡', property: '属性', description: '属性详情' },
            { rank: 4, name: '陈美君', property: '属性', description: '属性详情' },
            { rank: 5, name: '程成灵', property: '属性', description: '属性详情' },
          ]
        },
        constructionInfo: {
          list: [
            { name: '芦茨停车场', rate: '60', startTime: '2018-12-23', endTime: '2019-12-23' },
            { name: '海湾停车场', rate: '40', startTime: '2019-12-23', endTime: '2020-12-23' },
            { name: '时光停车场', rate: '30', startTime: '2019-12-23', endTime: '2020-12-23' },
          ]
        },
        expenseInfo: {
          total: 30890
        },
        garbageInfo: {
          list: [
            { rank: 1, name: '芦茨超级垃圾处理1号村', score: '9.8' },
            { rank: 2, name: '芦茨超级垃圾处理2号村', score: '9.0' },
            { rank: 3, name: '芦茨超级垃圾处理3号村', score: '8.8' },
            { rank: 4, name: '芦茨超级垃圾处理4号村', score: '7.8' },
            { rank: 5, name: '芦茨超级垃圾处理5号村', score: '6.8' },
            { rank: 6, name: '芦茨超级垃圾处理6号村', score: '6.8' },
            { rank: 7, name: '芦茨超级垃圾处理7号村', score: '5.8' },
            { rank: 8, name: '芦茨超级垃圾处理18号村', score: '5.4' },
            { rank: 9, name: '芦茨超级垃圾处理45号村', score: '3.8' },
            { rank: 10, name: '芦茨超级垃圾处理22号村', score: '1.8' },
          ],
          total: 100,
          deal: 90,
          standardRate: 90
        },
      }
    };
  },
  mounted() {
    setTimeout(() => {
      // 跳转到村委
      window.iframe.contentWindow.unityInstance.SendMessage("Main Camera", "SetSence", "view04, -190.8724,-301.927,-214.5015");
    }, 2000)
    setInterval(() => {
      this.time = moment().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
    this.getData()
    setInterval(() => {
      setTimeout(() => {
        this.getData()
      }, 0)
    }, 15000)
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/village/population')
      .then(({ result }) => {
        const {
          totalPopulation, localPeople, foreignPeople,
          lowQualityPeople, partyMember, volunteer,
          totalIncrement, totalPay, officialPay, workerPay, cadrePay, totalRubbish, disposeRubbish, pullYield, rightPercent,
        } = result
        this.foreignPeople = foreignPeople
        this.totalPopulation = totalPopulation
        this.localPeople = localPeople
        this.lowQualityPeople = lowQualityPeople
        this.partyMember = partyMember
        this.volunteer = volunteer
        this.totalIncrement = totalIncrement
        this.totalPay = totalPay
        this.officialPay = officialPay
        this.workerPay = workerPay
        this.cadrePay = cadrePay
        this.totalRubbish = totalRubbish
        this.disposeRubbish = disposeRubbish
        this.pullYield = pullYield
        this.rightPercent = rightPercent
        this.refreshNum = this.refreshNum + 1
      })
    },
    handleChecked() {
      this.isChecked = !this.isChecked;
    },
    back() {
      this.$router.back(-1)
    }
  },
};
</script>

<style lang="scss" scoped>

 .container {
   width: 1920px;
   height: 100%;
   background: #011326;
   margin: 0 auto;
   padding: 0;
   position: relative;
   /*background: url("../assets/images/park/bg.png") no-repeat;*/
   background-size: contain;
   .bgImg {
     background-size: contain;
   }
   .mark {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background: rgba(0, 9, 18, 0.5);
   }
}
.back {
  background: url("../assets/images/park/back@2x.png") no-repeat;
  position: absolute;
  left: 440px;
  top: 10px;
  z-index: 10000;
  width: 75px;
  height: 45px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  transform: none;
  color: #FFFFFF;
  line-height: 37px;
  letter-spacing: 2px;
  background-size: contain;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.left {
    top: 54px;
  left: 18px;
  @keyframes bounceLeftIn {
    0% {
      left:  -500px
    }
    100% {
      left: 18px
    }
  }
  @keyframes bounceLeftOut {
    0% {
      left: 18px
    }
    100% {
      left: -500px
    }
  }
  &.hide {
    animation: bounceLeftOut 1s;
    left: -500px
  }
  position: absolute;
  z-index: 999;
  animation: bounceLeftIn 1s;
}

.right {
  position: absolute;
  right: 18px;
  top: 54px;
  z-index: 9999;
  @keyframes bounceRightIn {
    0% {
      right: -400px
    }
    100% {
      right: 18px;
    }
  }
  @keyframes bounceRightOut {
    0% {
      right: 18px;
    }
    100% {
      right: -400px
    }
  }
  &.hide {
    animation: bounceRightOut 1s;
    right: -400px
  }
  animation: bounceRightIn 1s;
}

.bottom {
  position: absolute;
  bottom: 15px;
  left: 446px;
  display: flex;
  z-index: 9999;
  @keyframes bounceBottomIn {
    0% {
      bottom: -400px
    }
    100% {
      bottom: 15px
    }
  }
  @keyframes bounceBottomOut {
    0% {
      bottom: 15px
    }
    100% {
      bottom: -400px
    }
  }
  &.hide {
    animation: bounceBottomOut 1s;
    bottom: -400px
  }
  animation: bounceBottomIn 1s;

}

</style>
