<template>
  <div class="warning">
    <div class="title">区域客流量密度</div>
    <div id="bubbleChart" style="height: 220px"/>
    <div
      v-for="(item, idx) in list"
      :key="idx"
      class="item"
      :style="{
        width: `${item.symbolSize}px`,
        height: `${item.symbolSize}px`,
        top: `${tagList.indexOf(item.value[1]) * 32 + 100}px`,
        left: `${nameList.indexOf(item.value[0]) * 69 + 100}px`
      }"
    />
  </div>
</template>
<script>

export default {
  props: ['width', 'paddingLeft', 'paddingRight', 'refreshNum'],
  mounted() {
    this.drawLine()
  },
  data() {
    return {
      chart: null,
      option: {},
      nameList: ['天然浴场', '村委会广场', '芦茨大道', '时光码头'],
      tagList: ['7:30~9:30', '11:30~03:30', '18:30~20:30'],
      list: [
        { value: ['天然浴场', '7:30~9:30'], symbolSize: 30 },
        { value: ['村委会广场', '11:30~03:30'], symbolSize: 25 },
        { value: ['天然浴场', '18:30~20:30'], symbolSize: 15 },
        { value: ['村委会广场', '7:30~9:30'], symbolSize: 20 },
        { value: ['芦茨大道', '18:30~20:30'], symbolSize: 30 },
        { value: ['芦茨大道', '7:30~9:30'], symbolSize: 16 },
        { value: ['时光码头', '11:30~03:30'], symbolSize: 40 },
      ],
      maxList: []
    }
  },
  watch: {
    refreshNum: function () {
      this.restart();
    },
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      // this.$api.get('/jeecg-boot/carFlow/getInOrCome')
      // .then(({ list = [] }) => {
      //   this.maxList = list.map(item => item.cars).sort((a, b) => a - b)
      //   this.xList = list.map(item => item.time)
      //   this.yList = list.map(item => item.period)
      //   list.forEach((item) => {
      //     this.list = this.list.concat([
      //       {
      //         value: [item.cars, item.period],
      //         symbolSize: this.maxList.indexOf(item.cars) * 4 + 14,
      //         left: this.xList.indexOf(item.time) * 55 + 60,
      //       }
      //     ])
      //   })
      //   this.drawLine()
      // })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bubbleChart"));
      this.option = ({
        title: {
          text: "圈数越大人流越密集",
          top: 8,
          right: 10,
          textStyle: {
            color: '#83A5E1',
            fontFamily: 'Adobe Heiti Std',
            fontSize: 12,
          },
        },
        grid: {
          bottom: 20,
          left: 0,
          containLabel: true,
        },
        xAxis: [
          {
            boundaryGap: true,
            interval: 0,
            type: "category",
            axisLabel: {
              // formatter: (value, index) => {
              //   return `12/0${index + 1}`;
              // },
              interval: 0,
              textStyle: {
                color: 'rgba(131, 165, 225, 1)',
                fontFamily: 'MicrosoftYaHei',
                fontWeight: 400
              }
            },
            axisTick: {
              //刻度线样式
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine:{
              show: true,
              lineStyle:{
                  color: 'rgba(55, 93, 158, 1)' //更改坐标轴颜色
              }
            }
          },
        ],
        yAxis: [
          {
            type: "category",
            name: "密度",
            nameTextStyle: {
              padding: [0,0,0,50],
              fontSize: 12,
              fontFamily: "Adobe Heiti Std",
              color: "#83A5E1",
            },
            axisLabel: {
              textStyle: {
                color: 'rgba(131, 165, 225, 1)',
                fontFamily: 'MicrosoftYaHei',
                fontWeight: 400
              }
            },
            min: 0,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine:{
              show: true,
              lineStyle:{
                  color: 'rgba(55, 93, 158, 1)' //更改坐标轴颜色
              }
            }
          },
        ],
        series: [
          {
            type: "scatter",
            data: this.list,
            itemStyle: {
              //当前数据的样式
              normal: {
                color: "transparent",
                // shadowColor: 'rgba(250, 233, 40, 0.5)',
                // shadowBlur: 5
              },
            },
            animationDelay: 2 * 1000
          }
        ]
      });
      this.chart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.warning {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 40px;
    height: 40px;
  }
  background: url("../../assets/images/abortion/distribute.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 278px;
  
  margin-top: 18px;
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
  .item {
    border-radius: 50%;
    background: rgba(0, 234, 255, 1);
    box-shadow: 0 0 5px rgba(0, 234, 255, 0.5);
    position: absolute;
    animation: scale 3s linear infinite;
  }
}


</style>
