<template>
  <div class="pm">
    <div class="title">PM2.5趋势</div>
    <div id="pmChart" style="height: 280px"></div>
  </div>
</template>
<script>
import getDate from "../../utils/getDate"

export default {
  data() {
    return {
      chart: null,
      option: {},
      list: []
    }
  },
  props: ['isChecked', 'refreshNum'],
  mounted() {
    this.getData();
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) {
        this.restart();
      }
    }
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$api.get('/jeecg-boot/weather/history7dPm2p5')
      .then(({ airHourly = [] }) => {
        this.list = airHourly
          .sort((a, b) => new Date(a.pubTime).getTime() - new Date(b.pubTime).getTime())
          .map((item, index) => [getDate(item.pubTime), item.pm2p5])
          this.drawLine();    //执行下面的函数
      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("pmChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          xAxis: {
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.5)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
          },
          yAxis: {
              type: 'value',
              name: '单位：ug/cm³',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 10,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.5)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.list,
              type: 'bar',
              name: '',
              barWidth: 14,
              itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(57, 214, 243, 0.66)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(57, 214, 243, 0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              },
              // animationDelay: 2000
          },{
              data: this.list,
              type: 'line',
              name: '',
              symbolSize: 6,
              symbol: 'circle',
              lineStyle: {
                color: 'rgba(232, 229, 12, 1)'
              },
              itemStyle: {
                color: 'rgba(232, 229, 12, 1)'
              },
              // animationDelay: 2000
          }]
      };
      this.chart.setOption(this.option);
    }
  }
}
</script>
<style lang="scss" scoped>
.pm {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 32px;
  }
  background: url("../../assets/images/weather/pm_bg@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 314px;
  margin-top: 12px;
  .total {
     margin-top: 29px;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #CFDBE9;
    }
    .unit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8995A4;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      margin: 0 8px;
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
  }
  .legend_left {
    right: 130px;
    top: 68px;
    background: rgba(232, 229, 12, 1)
  }
  .legend_right {
    right: 72px;
    top: 68px;
    background: rgba(47, 159, 193, 1)
  }
}


</style>
