<template>
  <div class="center">
    <div class="list">
      <div class="item"><div class="icon" style="background: rgba(27, 198, 28, 1)" />畅通</div>
      <div class="item"><div class="icon" style="background: rgba(223, 213, 51, 1)" />拥堵</div>
      <div class="item"><div class="icon" style="background: rgba(230, 0, 18, 1)" />堵塞</div>
    </div>
    <div class="one" @click="showOne">
      <div :class="['content', oneVisible ? 'show' : 'hide']" :style="{'left': '-220px', 'top': '-100px', 'opacity': oneVisible ? 1 : 0 }">
        <div class="title">
          <div>天然浴场</div>
          <div @click="play"><img src="../../assets/images/abortion/play.png" /></div>
        </div>
        <div class="desc"><div>实时人数:660</div><div>今日人数:1960</div></div>
        <div class="traffic" style="color: rgba(255, 77, 77, 1)">
          <div class="icon"><img src="../../assets/images/abortion/red_sm.png" /></div>
          <div class="info">堵塞</div>
          <div class="rate">满载率60%</div>
        </div>
      </div>
    </div>
    <div class="two" @click="showTwo">
      <div :class="['content', twoVisible ? 'show' : 'hide']"  :style="{'left': '80px', 'top': '50px', 'opacity': twoVisible ? 1 : 0 }">
        <div class="title">
          <div>时光码头</div>
          <div @click="play"><img src="../../assets/images/abortion/play.png" /></div>
        </div>
        <div class="desc"><div>实时人数:56</div><div>今日人数:196</div></div>
        <div class="traffic" style="color: rgba(88, 255, 95, 1)">
          <div class="icon"><img src="../../assets/images/abortion/green_sm.png" /></div>
          <div class="info">畅通</div>
          <div class="rate">满载率60%</div>
        </div>
      </div>
    </div>
    <div class="three" @click="showThree">
      <div :class="['content', threeVisible ? 'show' : 'hide']"  :style="{'left': '-170px', 'top': '100px', 'opacity': threeVisible ? 1 : 0 }">
        <div class="title">
          <div>芦茨大道</div>
          <div @click="play"><img src="../../assets/images/abortion/play.png" /></div>
        </div>
        <div class="desc"><div>实时人数:360</div><div>今日人数:960</div></div>
        <div class="traffic" style="color: rgba(255, 220, 26, 1)">
          <div class="icon"><img src="../../assets/images/abortion/yellow_sm.png" /></div>
          <div class="info">拥堵</div>
          <div class="rate">满载率60%</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      oneVisible: true,
      twoVisible: false,
      threeVisible: true,
    }
  },
  props: ['play', 'isChecked'],
  watch: {
    isChecked: function () {
      this.showTwo();
    },
  },
  methods: {
    showOne() {
      // this.oneVisible = !this.oneVisible
    },
    showTwo() {
      this.twoVisible = !this.twoVisible
    },
    showThree() {
      // this.threeVisible = !this.threeVisible
    }
  }
}
</script>
<style lang="scss" scoped>
.center {
  .list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 730px;
    left: 1180px;
    .item {
      display: flex;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #F6F6F6;
      line-height: 22px;
      margin-right: 20px;
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 11px;
      }
    }
  }
  .one {
    position: absolute;
    left: 900px;
    top: 290px;
    width: 133px;
    height: 133px;
    background: url("../../assets/images/abortion/red.png") no-repeat;
  }
  .two {
    position: absolute;
    left: 120px;
    top: 850px;
    width: 133px;
    height: 133px;
    background: url("../../assets/images/abortion/green.png") no-repeat;
  }
  .three {
    position: absolute;
    left: 1350px;
    top: 170px;
    width: 133px;
    height: 133px;
    background: url("../../assets/images/abortion/yellow.png") no-repeat;
  }
  .one, .three, .two {
    cursor: pointer;
    @keyframes show {
      from {
        opacity: 0
      }
      to {
        opacity: 1
      }
    }
    @keyframes hide {
      from {
        opacity: 1
      }
      to {
        opacity: 0
      }
    }
    .content {
      width: 290.4px;
      height: 134.4px;
      text-align: left;
      padding: 18px 0 0 23px;
      box-sizing: border-box;
      position: absolute;
      background: url("../../assets/images/abortion/bg.png") no-repeat;
      background-size: contain;
      &.hide {
        animation: hide 2s;
      }
      &.show {
        animation: show 2s;
      }
      .title {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 22px;
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: center;
        img {
          width: 31px;
          height: 31px;
        }
      }
      .desc {
        display: flex;
        justify-content: space-between;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #CFDBE9;
        line-height: 22px;
        font-size: 16px;
        margin: 15px 0 15px 0;
        width: 80%;
      }
      .traffic {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #58FF5F;
        line-height: 22px;
        display: flex;
        align-items: center;
        .info {
          margin: 0 15px 0 10px;
        }
        .icon {
          width: 22px;
          height: 22px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
