<template>
  <div class="content">
    <div class="video-wrap">
      <video class="video" id="video" ref="videoEl" @click="handlePlayVideo"
             src="https://shanhu.oss-cn-hangzhou.aliyuncs.com/client/anchu/luci/video/v1.mp4"></video>
      <img class="play-btn" ref="playBtn" @click="handlePlay" src="../../assets/images/home/play.png"/>
    </div>
    <img src="../../assets/images/home/full.png" class="full" @click="full"/>
    <img src="../../assets/images/home/close.png" class="close" @click="close"/>
  </div>
</template>

<script>
export default {
  props: ['close'],
  data() {
    return {
      isPlay: false,
    }
  },
  mounted() {
    var self = this;
    setInterval(function () {
      document.addEventListener('fullscreenchange', (event) => {
        // document.fullscreenElement will point to the element that
        // is in fullscreen mode if there is one. If there isn't one,
        // the value of the property is null.
        if (document.fullscreenElement) {
          self.$emit('closeVideo', 1)
          console.log(222222222222,`Element: ${document.fullscreenElement.id} entered full-screen mode.`);
        } else {
          self.$emit('playVideo',1)
          console.log(111111111,'Leaving full-screen mode.');
        }
      });

    }, 1000)

  },
  methods: {
    handlePlay() {

      this.$refs.videoEl.play();
      this.$refs.playBtn.style.display = "none";
    },
    handlePlayVideo() {

      if (this.$refs.playBtn.style.display === "none") {
        this.$refs.videoEl.pause();
        this.$refs.playBtn.style.display = "block";
      }
    },



    full() {

      var movie = document.getElementById('video');
      if (movie.requestFullscreen) {
        movie.requestFullscreen();
      } else if (movie.mozRequestFullScreen) {
        movie.mozRequestFullScreen();//FireFox
      } else if (movie.webkitRequestFullScreen) {
        movie.webkitRequestFullScreen(); //Chrome等
      } else if (movie.msRequestFullscreen) { // IE11
        movie.msRequestFullscreen();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  height: 552px;
  width: 843px;
  z-index: 9;
  left: 550px;
  top: 130px;
  margin: 0 auto;
  background: {
    image: url('../../assets/images/home/video.png');
    repeat: no-repeat;
    size: cover;
    position: center;
  }

  .title {
    top: 9px;
  }
}

.video {
  width: 734px;
  height: 532px;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: -61px;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-wrap {
  position: absolute;
  overflow: hidden;
  width: 734px;
  height: 404px;
  top: 74px;
  bottom: 0;
  left: 58px;
  right: 0;
  border-radius: 4px;

  .play-btn {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
}

.full {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 50px;
  bottom: 21px;
  cursor: pointer;
}

.close {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 40px;
  top: 21px;
  cursor: pointer;
}

</style>
