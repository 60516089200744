<template>
  <div class="park">
    <div class="card-title title">
      <router-link to="/car-monitor">停车监控</router-link>
    </div>
    <div class="top">
      <div class="left">
        <div class="park_info">
          <img src="../../assets/images/park/park_info_icon.png" />
          <div class="info">
            <div class="top">
              <div class="name">车辆总数</div>
              <div class="num">
                <countTo ref="num" :startVal='0' :endVal='323' :duration='4000'></countTo>
                <span style="font-size: 12px;">辆</span>
              </div>
            </div>
            <div class="process">
                <div
                  :class="['process_bar', { 'process_bar_active': active }]"
                  :style="{'background': 'linear-gradient(90deg, rgba(251, 199, 112, 0.1) 0%, #FBC770 100%)', 'width': `${saturationRate}%`}"  />
            </div>
          </div>
        </div>
        <div class="park_info" style="margin-top: 27px;">
          <img src="../../assets/images/park/park_info_icon.png" />
          <div class="info">
            <div class="top">
              <div class="name">空闲车位</div>
              <div class="num">
                <countTo ref="numOne" :startVal='0' :endVal='120' :duration='4000'></countTo>
                <span style="font-size: 12px;">辆</span>
              </div>
            </div>
            <div class="process">
                <div
                  :class="['process_bar', { 'process_bar_active': active }]"
                  :style="{'background': 'linear-gradient(90deg, rgba(132, 120, 255, 0.1) 0%, rgba(132, 120, 255, 1) 100%)', 'width': `${idleRate}%`}"  />
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="park_info">
          <img src="../../assets/images/park/park_info_icon.png" />
          <div class="info">
            <div class="top">
              <div class="name">饱和率</div>
              <div class="num">
                <span>63</span>
                <!-- <countTo ref="numOne" :startVal='0' :endVal='saturationRate' :duration='4000'></countTo> -->
                <span style="font-size: 12px;">%</span>
              </div>
            </div>
            <div class="process">
                <div
                  :class="['process_bar', { 'process_bar_active': active }]"
                  :style="{'background': 'linear-gradient(90deg, rgba(255, 77, 77, 0.1) 0%, rgba(255, 77, 77, 1) 100%)','width': `${saturationRate}%`}"  />
            </div>
          </div>
        </div>
        <div class="park_info" style="margin-top: 27px;">
          <img src="../../assets/images/park/park_info_icon.png" />
          <div class="info">
            <div class="top">
              <div class="name">空闲率</div>
              <div class="num">
                <!-- <countTo ref="numTwo" :endVal='idleRate' :duration='4000'></countTo> -->
                <span>37</span>
                <span style="font-size: 12px;">%</span>
              </div>
            </div>
            <div class="process">
                <div
                  :class="['process_bar', { 'process_bar_active': active }]"
                  :style="{'background': 'linear-gradient(90deg, rgba(0, 226, 131, 0.1) 0%, rgba(0, 226, 131, 1) 100%)','width': `${idleRate}%`}"  />
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="time">
          <div class="stay-time-txt">2</div>
          <div class="stay-time-unit">时</div>
        </div>
        <div class="time-tip">平均停车时长</div>
      </div>
    </div>
    <Bubble width='410px' paddingLeft='0' paddingRight='0' :refreshNum='refreshNum' />
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import Bubble from '@/components/park/bubble';
import toThousands from '../../utils/toThousands'

export default {
  data() {
    return {
      active: false,
      sumCars: 0,
      saturationRate: 0,
      remainSpace: 0,
      idleRate: 0,
      minutes: 0,
    }
  },
  props: ['parkInfo', 'isChecked', 'refreshNum'],
  mounted() {
    this.getData()
  },
  components: {
    countTo,
    Bubble
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    restart() {
      this.active = true
      this.$refs.num && this.$refs.num.start()
      this.$refs.numOne && this.$refs.numOne.start()
      this.$refs.numTwo && this.$refs.numTwo.start()
      setTimeout(() => {
        this.active = false
      }, 6000)
    },
    formatNum(num) {
      return toThousands(num)
    },
    getData() {
      this.$api.get('/jeecg-boot/carFlow/remainSpaceNum')
      .then(({ sumCars, saturationRate, remainSpace, idleRate, minutes }) => {
        this.sumCars = sumCars
        this.saturationRate = String(saturationRate).replace('%', '')
        this.minutes = minutes
        this.remainSpace = remainSpace
        this.idleRate = String(idleRate).replace('%', '')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.park {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    margin-top: 2px;
    top: 8px;
  }
  background: url("../../assets/images/home/homestay@2x.png") no-repeat;
  background-size: contain;
  width: 410px;
  height: 384px;
  margin-top: 15px;
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    padding-left: 6px;
    .left, .middle {
      .park_info {
        display: flex;
        min-width: 125px;
        margin-top: 25px;
        align-items: center;
        img {
          width: 10px;
          height: 30px;
        }
        .info {
          margin-left: 5px;
          width: 90%;
          .top {
            display: flex;
            align-items: flex-start;
            height: 28px;
            margin-top: -10px;
            align-items: baseline;
            padding-left: 0;
            .name {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #B8D0F5;
            }
            .num {
              font-size: 20px;
              font-weight: 400;
              line-height: 22px;
              color: rgba(0, 222, 255, 1);
              font-family: "MicrosoftYaHei";
            }
          }
          .process {
            width: 100%;
            border: 1px solid #2052A1;
            border-radius: 0px 5px 5px 0px;
            height: 10px;
          }
          @keyframes progressIn {
            0% {
              width: 0
            }
          }
          .process_bar {
            height: 7px;
            background: linear-gradient(90deg, rgba(1, 216, 131, 0.1) 0%, #01D283 100%);
            border-radius: 0px 3px 3px 0px;
            &.process_bar_active {
              animation: progressIn 5s linear;
            }
          }
        }
      }
    }
    .right {
      .stay-time-txt {
        font-size: 20px;
        font-family: Microsoft YaHei;
        color: #ffffff;
      }
      .stay-time-unit {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .time {
        width: 108px;;
        height: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 21px;
        background: {
          image: url("../../assets/images/home/fgdz.png");
          size: cover;
          repeat: no-repat;
          position: center;
        }
        @keyframes fadeIn {
          0% {
            opacity: 1
          }
          60% {
            opacity: 0.4
          }
          100% {
            opacity: 1
          }
        };
        opacity: 0.4;
        animation: fadeIn 4s linear infinite;
      }
      .time-tip {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #B8D0F5;
        text-align: center;
      }
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
    border-radius: 1px;
  }
  .legend_left {
    right: 130px;
    top: 178px;
    background: rgba(244, 70, 28, 1)
  }
  .legend_right {
    right: 70px;
    top: 178px;
    background: rgba(39, 205, 255, 1);
  }
}


</style>
