<template>
  <div class="trafficJam">
    <div class="title">拥堵路段</div>
    <div class="wrap">
      <vue-seamless-scroll
        :data="busyRoad"
        :class-option="classOption"
        class="listWrap"
      >
        <div
          :class="[
            'item',
            item.loadFactor === '故障' ? 'item-busy' : '',
            item.loadFactor === '正常' ? '' : '',
          ]"
          v-for="(item, idx) in busyRoad"
          :key="idx"
        >
          <span class="item-name">{{ item.areaName }}</span>
          <span class="item-status">{{ item.loadFactor }}</span>
          <div v-if="item.loadFactor === '故障'" class="item-icon"></div>
          <div v-if="item.loadFactor === '正常'" class="item-yd-icon"></div>
          
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  mounted() {
    this.getData()
  },
  data() {
    return {
      classOption: {
        singleHeight: 50,
      },
      busyRoad: [
      ]
    };
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/passenger/flow/warn')
      .then(({ data }) => {
        this.busyRoad = data
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.trafficJam {
  width: 408px;
  height: 436px;
  background: url("../../assets/images/monitor/right_2.png") no-repeat;
  background-size: contain;
  margin-top: 9px;
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    padding-top: 5px;
  }
  .wrap {
    overflow: hidden;
    height: 200px;
    margin-top: 39px;
   
    .item {
      display: flex;
      align-items: center;
      width: 318px;
      height: 40px;
      margin:  0 auto;
      line-height: 40px;
      padding-left: 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #cfdbe9;
      margin-bottom: 10px;
      
      background: linear-gradient(90deg, rgba(47, 130, 236, 0.1) 0%, rgba(6, 65, 140, 0) 100%);

      .item-name {
        margin-right: 29px;
        text-align: left;
        width: 170px;
      }
    }

    .item-busy {
      background: linear-gradient(
        90deg,
        rgba(255, 77, 77, 0.69) 0%,
        rgba(255, 77, 77, 0.35) 33%,
        rgba(6, 65, 140, 0) 100%
      );

      .item-icon {
        width: 28px;
        height: 28px;
        background: rgba($color: #ff4d4d, $alpha: 0.4);
        border-radius: 50%;
        position: relative;
        margin-left: 38px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: rgba($color: #ff4d4d, $alpha: 0.5);
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: #ff4d4d;
          border-radius: 50%;
        }
      }
    }
    .item-busy-1 {
      background: linear-gradient(
        90deg,
        rgba(229, 233, 14, 0.69) 0%,
        rgba(229, 233, 14, 0.35) 33%,
        rgba(6, 65, 140, 0) 100%
      );
      .item-yd-icon {
        width: 28px;
        height: 28px;
        background: rgba($color: rgb(229, 233, 14), $alpha: 0.4);
        border-radius: 50%;
        position: relative;
        margin-left: 38px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: rgba($color: rgb(229, 233, 14), $alpha: 0.5);
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: rgb(229, 233, 14);
          border-radius: 50%;
        }
      }
    }
    .item-busy-2 {
      background: linear-gradient(
        90deg,
        rgba(138, 233, 14, 0.69) 0%,
        rgba(14, 233, 14, 0.35) 33%,
        rgba(6, 65, 140, 0) 100%
      );
      .item-yd-icon2 {
        width: 28px;
        height: 28px;
        background: rgba($color: rgb(14, 233, 25), $alpha: 0.4);
        border-radius: 50%;
        position: relative;
        margin-left: 38px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: rgba($color: rgb(14, 233, 25), $alpha: 0.5);
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: rgb(14, 233, 25);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>