<template>
  <div class="redHeiBang">
    <div class="top">垃圾处理红黑榜</div>
    <div class="title">
      <div class="title_left" @click="navOn(0)">
        <div :style="{ color: nav_index == 0 ? '#ffffff' : '#748BAA' }">
          垃圾处理红榜
        </div>
        <img :src="nav_index == 0 ? redHeiBang_yes1 : redHeiBang_yes" />
      </div>
      <div class="title_right" @click="navOn(1)">
        <div :style="{ color: nav_index == 1 ? '#ffffff' : '#748BAA' }">
          垃圾处理黑榜
        </div>
        <img :src="nav_index == 1 ? redHeiBang_no : redHeiBang_no1" />
      </div>
    </div>
    <div class="list" v-if="nav_index == 0">
      <div class="item" v-for="(item, idx) in list" :key="item.rank">
        <div
          class="rank rank_num"
          :style="{ background: idx > 2 ? '#1C4997' : 'none' }"
        >
          <img
            v-if="idx === 0"
            src="../../assets/images/homestay/rank_one@2x.png"
          />
          <img
            v-if="idx === 1"
            src="../../assets/images/homestay/rank_two@2x.png"
          />
          <img
            v-if="idx === 2"
            src="../../assets/images/homestay/rank_three@2x.png"
          />
          <span v-if="idx > 2">{{ idx + 1 }}</span>
        </div>
        <div class="name">{{ item.name }}</div>
        <div class="score">
          <span style="margin-right: 4px">评分</span>{{ item.totalScore }}
        </div>
      </div>
    </div>
    <div class="list" v-else>
      <!-- <div class="item" v-for="(item, idx) in list" :key="item.rank">
        <div
          class="rank rank_num"
          style="background: #e01919; color: #fffcf7"
          v-if="idx == 0"
        >
          {{ idx + 1 }}
        </div>
        <div
          class="rank rank_num"
          style="background: #e08519; color: #fef5ef"
          v-if="idx == 1"
        >
          {{ idx + 1 }}
        </div>
        <div
          class="rank rank_num"
          style="background: #bfb514; color: #ffffff"
          v-if="idx == 2"
        >
          {{ idx + 1 }}
        </div>
        <div
          class="rank rank_num"
          style="background: #1c4997; color: #b8d0f5"
          v-if="idx > 2"
        >
          {{ idx + 1 }}
        </div>
        <div class="name">{{ item.name }}</div>
        <div class="score">
          <span style="margin-right: 4px">评分</span>{{ item.totalScore }}
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { methods } from "vue-echarts";
export default {
  data() {
    return {
      nav_index: 0,
      redHeiBang_no: require("@/assets/images/housekeeper/redHeiBang_no.png"),
      redHeiBang_yes: require("@/assets/images/housekeeper/redHeiBang_yes.png"),
      redHeiBang_no1: require("@/assets/images/housekeeper/redHeiBang_no1.png"),
      redHeiBang_yes1: require("@/assets/images/housekeeper/redHeiBang_yes1.png"),
      list: [],
    };
  },
  mounted() {
    this.getData();
    setInterval(() => {
      setTimeout(() => {
        this.list = this.list.concat([this.list[0]]);
        this.list.shift();
      });
    }, 10000);
  },
  methods: {
    navOn(value) {
      this.nav_index = value;
    },
    getData() {
      this.$api.get("/jeecg-boot/village/rubbish").then(({ result }) => {
        this.list = result;
      });
    },
  },
};
</script>
<style  lang="scss" scoped>
.redHeiBang {
  background: url("../../assets/images/housekeeper/redHeiBang.png") no-repeat;
  background-size: contain;
  width: 408px;
  height: 607px;
  margin-top: 8px;
  .top {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    padding-top: 8px;
  }
  .title {
    display: flex;
    align-items: center;
    padding-top: 32px;
    .title_left {
      position: relative;
      width: 173px;
      height: 39px;
      background: url("../../assets/images/housekeeper/redHeiBang_left.png")
        no-repeat;
      background-size: contain;
      font-size: 14px;
      font-weight: bold;
      line-height: 39px;
      text-align: left;
      padding-left: 15px;
      margin-right: 12px;
      margin-left: 21px;
      img {
        width: 32px;
        height: 33px;
        position: absolute;
        left: 119px;
        top: -3px;
      }
    }
    .title_right {
      position: relative;
      width: 192px;
      height: 58px;
      background: url("../../assets/images/housekeeper/redHeiBang_right.png")
        no-repeat;
      background-size: contain;
      font-size: 14px;
      font-weight: bold;
      line-height: 58px;
      text-align: left;
      padding-left: 25px;
      img {
        width: 32px;
        height: 33px;
        position: absolute;
        left: 129px;
        bottom: 6px;
      }
    }
  }
  .list {
    margin-top: 13px;
    .item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b8d0f5;
      line-height: 28px;
      width: 368px;
      height: 30px;
      margin: 0 auto;
      margin-bottom: 15px;
      background: rgba(12, 38, 82, 0.6);
      border: 1px solid #1e4586;
      display: flex;
      justify-content: space-around;
      .description {
        min-width: 200px;
      }
      .rank_num {
        font-weight: bold;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-top: 4px;
      }
      img {
        width: 17px;
        height: 22px;
      }
      .item {
        text-align: center;
      }
    }
  }
}
</style>