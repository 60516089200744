<template>
  <div class="trafficStatistics">
    <div class="title">流量统计</div>
    <div class="top">
      <div class="top_item">
        <div class="top_item_text">今日游客</div>
        <div class="top_item_num">
          <countTo
            ref=""
            :startVal="0"
            :endVal="132"
            :duration="4000"
          ></countTo>
        </div>
      </div>
      <div class="top_item">
        <div class="top_item_text">人流量</div>
        <div class="top_item_num">
          <countTo
            ref=""
            :startVal="0"
            :endVal="6499"
            :duration="4000"
          ></countTo>
          <span>/小时</span>
        </div>
      </div>
      <div class="top_item">
        <div class="top_item_text">累计人数</div>
        <div class="top_item_num">
          <countTo
            ref=""
            :startVal="0"
            :endVal="3000"
            :duration="4000"
          ></countTo>
          <span>人</span>
        </div>
      </div>
    </div>
    <div id="twoChart" style="height: 230px" />
  </div>
</template>
<script>
import countTo from "vue-count-to";
export default {
  data() {
    return {
      option: {},
    };
  },
  components: {
    countTo,
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("twoChart"));
      this.option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#83A5E1",
              fontSize: 12,
            },
          },
          axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(56, 89, 130, 0.3)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#375D9E",
              },
            },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#83A5E1",
              fontSize: 12,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(55, 93, 158, 1)",
            },
          },
        },
        series: [
          {
            data: [
              820, 932, 901, 934, 1290, 1330, 1320, 901, 934, 1290, 1330, 1320,
            ],
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgba(50, 183, 233, 1)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#375D9E", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#163463", //   0% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.trafficStatistics {
  width: 408px;
  height: 338px;
  background: url("../../assets/images/monitor/left_2.png") no-repeat;
  background-size: contain;
  margin-top: 8px;
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    padding-top: 5px;
  }
  .top {
    display: flex;
    align-items: center;
    padding: 0 26px 0 36px;
    margin-top: 40px;
    .top_item {
      flex: 1;
      .top_item_text {
        text-align: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #cfdbe9;
        margin-bottom: 10px;
      }
      .top_item_num {
        text-align: left;
        span {
          &:last-child {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a4afbc;
          }
          &:first-child {
            font-size: 26px;
            font-family: Impact;
            font-weight: 400;
            color: #cfdbe9;
          }
        }
      }
    }
  }
}
</style>