<template>
  <div class="forecast">
    <div class="title">天气预报</div>
    <div class="forecast-info">
      <div class="item" v-for="(item, idx) in list" :key="idx">
        <div class="day">{{item.day}}</div>
        <div class="date">{{item.date}}</div>
        <div class="img">
          <img :src="item.img" alt='天气情况' />
        </div>
        <div class="weather">{{item.tempMin}}~{{item.tempMax}}℃</div>
        <div class="description">{{item.textDay}}</div>
        <div class="wind">{{item.windDirDay}}{{item.windScaleDay}}级</div>
      </div>
    </div>
  </div>
</template>
<script>
import getDate from '../../utils/getDate'
import cloudPng from '../../assets/images/weather/cloud@2x.png'
import sunPng from '../../assets/images/weather/sun@2x.png'
import minRainPng from '../../assets/images/weather/min_rain@2x.png'

export default {
  data() {
    return {
      imgMap: {
        'cloud': cloudPng,
        'sun': sunPng,
        'min_rain': minRainPng,
      },
      list: [],
      weather: '',
      imgPngList: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/weather/weather3d')
      .then(({ daily = [] }) => {
        this.list = daily.map(item => {
          const month = new Date(item.fxDate).getMonth() + 1
          const date = new Date(item.fxDate).getDate()
          const fullDate = `${month}月${date}日`
          return ({
            day: getDate(item.fxDate),
            date: fullDate,
            description: item.textDay,
            tempMin: item.tempMin,
            tempMax: item.tempMax,
            windDirDay: item.windDirDay,
            windScaleDay: item.windScaleDay,
            img: require(`../../assets/images/color-128/${item.iconDay}.png`)
          })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.forecast {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 32px;
  }
  background: url("../../assets/images/weather/forecast_bg@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 316px;
  .forecast-info {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
    font-weight: 400;
    font-family: Microsoft YaHei;
    .img {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      @keyframes piao {
        0% {
          margin-right: 0px;
        }
        50% {
          margin-right: 10px;
        }
        100% {
          margin-right: 0px;
        }
      }
      // animation: piao 50s linear infinite;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .day {
      font-size: 16px;
      font-weight: bold;
      color: #CFDBE9;
    }
    .date {
      font-size: 14px;
      color: #97A4B4;
      margin: 10px 0;
    }
    .weather {
      font-size: 18px;
      color: #CFDBE9;
    }
    .description {
      margin: 15px 0;
      font-size: 14px;
      color: #9BAAC5;
    }
    .wind {
      font-size: 14px;
      color: #9CA9B9;
    }
  }
}


</style>
