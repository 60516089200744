import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/home.vue'
import Monitor from '../views/Monitor'
import videoDemo from '../views/videoDemo'
// import CarMonitor from "../views/CarMonitor";
import VideoMonitor from '../views/video-monitor'
import VideoBeta from '../views/video-beta'
import CarMonitor from '../views/park'
import Homestay from '../views/homestay' // 民宿
import Weather from '../views/weather' // 气象
import Housekeeper from '../views/housekeeper' // 管家
import Abortion from '../views/abortion' // 人流
import Login from '../views/login' //  登录

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: Monitor
  },
  {
    path: '/videoDemo',
    name: 'videoDemo',
    component: videoDemo
  },
  {
    path: "/car-monitor",
    name: "CarMonitor",
    component: CarMonitor,
  },
  {
    path: '/home-stay',
    name: 'Homestay',
    component: Homestay
  },
  {
    path: '/video-monitor',
    name: 'VideoMonitor',
    component: VideoMonitor
  },
  {
    path: '/video-beta',
    name: 'VideoBeta',
    component: VideoBeta
  },
  {
    path: '/weather',
    name: 'Weather',
    component: Weather
  },
  {
    path: '/housekeeper',
    name: 'Housekeeper',
    component: Housekeeper
  },
  {
    path: '/abortion',
    name: 'Abortion',
    component: Abortion
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {

//   next();
//   return;
//   let islogin = window.localStorage.token
//   console.log(window.localStorage.token, 'window.localStorage.token');
//   console.log(to.path, 'to.path');
//   if (to.path === '/login') {
//     if (islogin) {
//       next('/');
//     } else {
//       next();
//     }
//   } else if (islogin) {
//     next();
//   } else {
//     next("/login");
//   }
// })

export default router;
