<template>
  <div class="checkIn bgImg">
    <div class="title">入住数据</div>
    <div class="today_info">
      <div class="today_in item">
        <div class="title">今日入住</div>
        <div class="num" style="color:rgba(50, 243, 250, 1)">
          <countTo ref="cnum" :startVal='0' :endVal='todayCheckIn' :duration='4000' style="min-width: 45px"></countTo>
          <span class="unit" style="color:rgba(50, 243, 250, 1)">间</span>
        </div>
      </div>
      <div class="today_remain item">
        <div class="title">空余房间</div>
        <div class="num">
          <countTo ref="cnumOne" :startVal='0' :endVal='emptyHouse' :duration='4000' style="min-width: 60px"></countTo>
          <span class="unit">间</span>
        </div>
      </div>
      <div class="remain_rate item">
        <div class="title">空房率</div>
        <div class="num">
          <countTo ref="cnumTwo" :decimals="2" :startVal='0' :endVal='emptyPercent' :duration='4000' style="min-width: 65px"></countTo>
          <span class="unit">%</span>
        </div>
      </div>
    </div>
    <div id="yearChart" style="height: 230px"/>
    <div class="legend year_legend_left" />
    <div class="legend year_legend_right" />
  </div>
</template>
<script>
import countTo from 'vue-count-to';

export default {
  data() {
    return {
      chart: null,
      option: {},
      todayCheckIn: 20,
      emptyHouse: 20,
      emptyPercent: 1,
      yearList: [],
      lastYearList: [],
      addNum: 0,
      dateMap: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dece: '12月',
      }
    }
  },
  props: ['isChecked', 'refreshNum'],
  mounted() {
    this.getDataLine();
    this.getData();
  },
  components: {
    countTo
  },
  watch: {
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) {
        this.getData();
        this.getAddNum();
        this.restart();
      }
    },
    addNum: function () {
      this.todayCheckIn = this.todayCheckIn + this.addNum
      this.emptyHouse = this.emptyHouse - this.addNum
      this.emptyPercent = Math.round((this.emptyHouse) / (this.emptyHouse + this.todayCheckIn) * 100)
    }
  },
  methods: {
    getAddNum() {
      if (this.emptyHouse <= 5) {
        this.addNum = this.addNum - Math.ceil(Math.random()*10)
      } else if (this.emptyHouse >= 200) {
        this.addNum = this.addNum + Math.ceil(Math.random()*10)
      } else {
        this.addNum = this.addNum + Math.ceil(Math.random()*(20) - 10)
      }
    },
    restart() {
      this.$refs.cnum.start()
      this.$refs.cnumOne.start()
      this.$refs.cnumTwo.start()
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$trueApi.post('/LuciHomestay/getHomeStayData')
      .then(({ data }) => {
        const { roomTotalCount, spareRoomCount, homestayStorTotalCount, fullRate, spareRoomRate, checkInRate } = data
        this.todayCheckIn = roomTotalCount - spareRoomCount
        this.emptyHouse = spareRoomCount
        this.emptyPercent = spareRoomRate * 100
      })
    },
    getDataLine() {
      this.$trueApi.post('/LuciHomestay/getRoomStaticByMonth')
      .then(({ data }) => {
        const { todayCheckIn, emptyHouse, emptyPercent } = data
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        this.yearList = months.map(month => {
          const list = data.filter(item => +(item.date.substr(0, 4)) === new Date().getFullYear()).map(item => ({ ...item, date: item.date.substr(4, 2)}))
          return (list.find(item => item.date === month) || {}).count || 0
        })
        this.lastYearList = months.map(month => {
          const list = data.filter(item => +(item.date.substr(0, 4)) !== new Date().getFullYear()).map(item => ({ ...item, date: item.date.substr(4, 2)}))
          return (list.find(item => item.date === month) || {}).count || 0
        })
        this.drawYearLine()
      })
    },
    drawYearLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("yearChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          legend: {
            data: ['去年', '今年'],
            textStyle: {
                color: '#83A5E1',
                fontSize: 10,
                width: 40
            },
            icon: 'none',
            right: 30,
            top: 25
          },
          grid: {
            bottom: 15,
            top: 55,
            left: 15,
            containLabel: true,
          },
          xAxis: {
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                width: 20,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
              data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
          },
          yAxis: {
              type: 'value',
              name: '单位/间',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 10,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.lastYearList,
              type: 'line',
              name: '今年',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: this.lastYearList.length <= 1 ? 'rgba(32, 184, 237, 1)' : 'transparent',
              },
              lineStyle: {
                color: 'rgba(32, 184, 237, 1)'
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0.5, color: 'rgba(32, 184, 237, 0.4)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
                // color: 'rgba(55, 93, 158, 0.5)'
              },
              // animationDelay: 2 * 1000
          },{
              data: this.yearList ,
              type: 'line',
              smooth: this.yearList.length > 1,
              name: '去年',
              symbol: "circle",
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: this.yearList.length <= 1 ? 'rgba(32, 184, 237, 1)' : 'transparent',
              },
              lineStyle: {
                color: 'rgba(0, 148, 255, 1)'
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0.5, color: 'rgba(32, 184, 237, 0.4)' // 0% 处的颜色
                    },{
                        offset: 1, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
                // color: 'rgba(32, 184, 237, 0.5)'
              },
              // animationDelay: 2 * 1000
          }]
      }
      this.chart.setOption(this.option)
    }
  }
};
</script>
<style lang="scss" scoped>

.checkIn {
  background: url("../../assets/images/homestay/checkIn_bg@2x.png") no-repeat;
  width: 409px;
  height: 356px;
  margin-top: 11px;
  padding-left: 10px;
  position: relative;
  .legend {
    position: absolute;
    width: 9px;
    height: 1px;
    border-radius: 1px;
  }
  .year_legend_left {
    position: absolute;
    right: 130px;
    top: 156px;
    background: rgba(32, 184, 237, 1)
  }
  .year_legend_right {
    position: absolute;
    right: 65px;
    top: 156px;
    background: rgba(0, 148, 255, 1)
  }
  .title {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    //font-weight: normal;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 32px;
    margin-right: 5px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
  }
  .today_info {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    .item {
      .title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #CFDBE9;
      }
      .num {
        font-size: 26px;
        font-family: Impact;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 10px;
        display: flex;
        align-items: baseline;
        .unit {
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          margin-left: 3px;
        }
      }
    }
  }
}




</style>
