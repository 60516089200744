<template>
  <div class="homeStay">
    <div class="card-title title"> <router-link to="/home-stay">芦茨民宿</router-link></div>
    <div class="top">
      <div class="item">
        <div class="name" style="color: #fff">民宿总数</div>
        <div class="num" style="color: rgba(50, 243, 250, 1)">
          <countTo ref="num" :startVal='0' :endVal='188' :duration='4000' style="min-width: 45px;"></countTo>
          <span class="unit">个</span>
        </div>
      </div>
      <div class="item">
        <div class="name">总房间数</div>
        <div class="num">
          <countTo ref="numOne" :startVal='0' :endVal='2256' :duration='4000' style="min-width: 55px;"></countTo>
          <span class="unit">间</span>
        </div>
      </div>
      <div class="item">
        <div class="name">空余房间</div>
        <div class="num">
          <countTo ref="numTwo" :startVal='0' :endVal='1634' :duration='4000' style="min-width: 45px;"></countTo>
          <span class="unit">间</span>
        </div>
      </div>
    </div>
    <div class="top">
      <div class="item">
        <div class="name" style="color: #fff">昨日收益</div>
        <div class="num" style="color: rgba(50, 243, 250, 1)">
          <countTo ref="num" :startVal='0' :endVal='67434' :duration='4000' style="min-width: 60px;"></countTo>
          <span class="unit">元</span>
        </div>
      </div>
      <div class="item">
        <div class="name">本月收益</div>
        <div class="num">
          <countTo ref="numOne" :startVal='0' :endVal='2098327' :duration='4000' style="min-width: 70px;"></countTo>
          <span class="unit">元</span>
        </div>
      </div>
      <div class="item">
        <div class="name">本年收益</div>
        <div class="num">
          <countTo ref="numTwo" :startVal='0' :endVal='27253976' :duration='4000' style="min-width: 75px;"></countTo>
          <span class="unit">元</span>
        </div>
      </div>
    </div>
    <div class="legend legend_left" />
    <div class="legend legend_right" />
    <div id="twoChart" style="height: 190px" />
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import toThousands from '../../utils/toThousands'

export default {
  data() {
    return {
      chart: null,
      option: {},
      homeCount: 0,
      emptyHouse: 0,
      houseCount: 0,
      todayEarn: ((new Date().getHours() + 1) * 5 + 44) * 200,
      monthEarn: ((new Date().getHours() + 1) * 5 + new Date().getDate() * 152) * 200,
      yearEarn: ((new Date().getHours() + 1) * 5 + new Date().getDate() * 152 + 178) * 200,
      lastyearList: [],
      yearList: [],
      dateMap: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dece: '12月',
      }

    }
  },
  props: ['homestayInfo', 'isChecked', 'refreshNum'],
  mounted() {
    this.getData()
    this.getDataLine()
  },
  components: {
    countTo
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    restart() {
      if (this.isChecked && this.chart) {
        this.$refs.num && this.$refs.num.start()
        this.$refs.numOne && this.$refs.numOne.start()
        this.$refs.numTwo&& this.$refs.numTwo.start()
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$trueApi.post('/LuciHomestay/getHomeStayData')
      .then(({ data }) => {
        const { roomTotalCount, spareRoomCount, homestayStorTotalCount, fullPercent, emptyPercent, checkInPercent } = data
        this.homeCount = roomTotalCount
        this.emptyHouse = spareRoomCount
        this.houseCount = homestayStorTotalCount
      })
      this.$trueApi.post('/LuciHomestay/income')
      .then(({ data }) => {
        const { incomeToday, incomeYear, incomeMonth, lastYear, thisYear} = data
        this.monthEarn = incomeMonth / 10000
        this.todayEarn = incomeToday / 10000
        this.yearEarn = incomeYear / 10000
        this.lastyearList = (lastYear || []).map(item => item.income)
        this.yearList = (thisYear || []).map(item => item.income)
        this.drawLine();
      })
    },
    getDataLine() {
      // this.$trueApi.post('/LuciHomestay/income')
      // .then(({ data }) => {
      //   const { incomeToday, incomeYear, incomeMonth, lastYear, thisYear } = data
      //   this.monthEarn = incomeMonth
      //   this.todayEarn = incomeToday
      //   this.yearEarn = incomeYear
      //   this.lastyearList = (lastYear || []).map(item => item.income)
      //   this.yearList = (thisYear || []).map(item => item.income)
      //   this.drawLine();
      // })
      // this.$api.get('/jeecg-boot/house/houseEarnChange')
      // .then(({ result }) => {
      //   this.lastyearList = result[1] || {}
      //   this.yearList = result[0] || {}
      //
      //   this.lastyearList = Object.keys(this.lastyearList)
      //     .filter((item, index) => this.dateMap[item] && index <= new Date().getMonth() + 1)
      //     .map((item, index) => [this.dateMap[item], this.lastyearList[item] / 1000])
      //   this.yearList = Object.keys(this.yearList)
      //     .filter((item, index) => this.dateMap[item])
      //     .map((item, index) => [this.dateMap[item], this.yearList[item] / 1000])
      //   this.drawLine();
      // })
    },
    formatNum(num) {
      return toThousands(num)
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("twoChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          legend: {
            data: ['今年', '去年'],
            textStyle: {
                color: '#83A5E1',
                fontSize: 13,
                width: 40
            },
            right: 35,
            top: 15,
            icon: 'none',
          },
          grid: {
            top: 50,
            bottom: 20,
          },
          xAxis: {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontWeight: 'bold',
                fontSize: 12,
                padding: [0,0,0,30],
                width: 20,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
              splitNumber: 5,
              data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
          },
          yAxis: {
              type: 'value',
              name: '单位/万元',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 13,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontWeight: 'bold',
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.yearList,
              type: 'line',
              name: '今年',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(50, 183, 233, 1)'
              },
              areaStyle: {
                color: 'rgba(50, 183, 233, 0.2)'
              },
              animationDelay: 2 * 1000
          },{
              data: this.lastyearList,
              type: 'line',
              smooth: true,
              name: '去年',
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(255, 241, 0, 1)'
              },
              areaStyle: {
                color: 'rgba(255, 241, 0, 0.2)'
              },
              animationDelay: 2 * 1000
          }]
      }
      this.chart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.homeStay {
  padding: 30px 10px 0;
  position: relative;
  .title {
    top: 9px;
    cursor: pointer;
    font-weight: bold;
  }
  background: url("../../assets/images/home/homestay@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 385px;
  margin-top: 12px;
  .top {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    .item {
      min-width: 110px;
      width: 33%;
      text-align: center;
    }
    .name {
      font-size: 16px;
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      color: #CFDBE9;
      margin-bottom: 5px;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      min-width: 100px;
      display: flex;
      justify-content: center;
      align-items: baseline;
      .unit {
        font-size: 14px;
        font-family: 'Microsoft YaHei';
        font-weight: 400;
        color: #B5C0CD;
        margin-left: 5px;
      }
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
    border-radius: 1px;
  }
  .legend_left {
    right: 150px;
    top: 200px;
    background: rgba(249, 243, 3, 1)
  }
  .legend_right {
    right: 80px;
    top: 200px;
    background: rgba(39, 205, 255, 1);
  }
}


</style>
