<template>
  <div class="portrait bgImg">
    <div class="title">游客画像</div>
    <div class="content">
      <div class="left">
        <div class="process" v-for="item in ageList" :key="item.age">
          <div class="age">{{ item.age }}</div>
          <div class="percent">{{ item.percent }}%</div>
          <div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background': item.color, 'width': item.percent + '%'}" />
        </div>
        <div class="description">年龄百分比</div>
      </div>
      <div class="right">
        <div class="top">
          <div class="gender">
            <div class="boy">
              <div class="sex">男</div>
              <div class="num-name">
                <div class="num">{{malePercent}}%</div>
                <div class="name">{{male}}</div>
              </div>
            </div>
            <div class="girl">
              <div class="num-name">
                <div class="num">{{femalePercent}}%</div>
                <div class="name">{{female}}</div>
              </div>
              <div class="sex">女</div>
            </div>
          </div>
          <div class="process_line">
            <div class="process_boy_line" style="width: 54%"/>
            <div class="process_girl_line" style="width: 46%" />
          </div>
        </div>
        <div class="bottom">
          <div class="item" v-for="item in consumptionList" :key="item.name">
            <div class="num">{{ item.percent }}%</div>
            <div class="name">{{ item.name }}</div>
            <!-- <img src="../../assets/images/homestay/consumption_bg@2x.png"> -->
            <div class="line"><div class="process" /></div>
            <div class="line-left"><div class="process" /></div>
            <div class="line-bottom"><div class="process" /></div>
            <div class="line-right"><div class="process" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['portraitInfo', 'refreshNum'],
  data() {
    return {
      active: false,
      male: null,
      female: null,
      femalePercent: null,
      malePercent: null,
      midConsume: null,
      lowConsume: null,
      heighConsume: null,
      sixty:null,
      sixtyPercent:null,
      thirty:null,
      thirtyPercent :null,
      ten:null,
      tenPercent:null,
      zero:null,
      zeroPercent: null
    }
  },
  computed: {
    consumptionList: function () {
      return [
        { name: '低消费', percent: this.lowConsume },
        { name: '中消费', percent: this.midConsume  },
        { name: '高消费', percent: this.heighConsume  }
      ]
    },
    ageList: function () {
      return [
        { age: '60岁', percent: this.sixtyPercent, color:"linear-gradient(90deg, rgba(70, 193, 228, 0.1) 0%, #46C1E4 100%)" },
        { age: '40～60岁', percent: this.fortyPercent, color:"linear-gradient(90deg, rgba(77, 115, 255, 0.1) 0%, rgba(77, 115, 255, 1) 100%)" },
        { age: '30～40岁', percent: this.thirtyPercent, color:"linear-gradient(90deg, rgba(78, 205, 78, 0.1) 0%, #4ECD4E 100%)"  },
        { age: '10~30岁', percent: this.tenPercent, color:"linear-gradient(90deg, rgba(251, 205, 95, 0.1) 0%, #FBCD5F 100%)" },
        { age: '0～10岁', percent: this.zeroPercent, color:"linear-gradient(90deg, rgba(248, 108, 108, 0.1) 0%, #F86C6C 100%)" }
      ]
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    refreshNum: function () {
      if (this.refreshNum > 0) {
        this.getData();
        this.active = true;
        setTimeout(() => {
          this.active = false;
        }, 6000)
      }
    },
  },
  methods: {
    getData() {
      this.$trueApi.post('/LuciHomestay/portrait')
      .then(({ data }) => {
        const { agePercent = [], consumePercent = [], sexPercent = [] } = data
        this.sixtyPercent = +(agePercent[0].percent * 100).toFixed(2)
        // this.forty = forty
        this.fortyPercent = +(agePercent[1].percent * 100).toFixed(2)
        // this.thirty = thirty
        this.thirtyPercent = +(agePercent[2].percent * 100).toFixed(2)
        // this.ten = ten
        this.tenPercent = +(agePercent[3].percent * 100).toFixed(2)
        this.zeroPercent = +(agePercent[4].percent * 100).toFixed(2)

        console.log(this.sixtyPercent, 'this.sixtyPercent');

        this.lowConsume = +(consumePercent[2].percent * 100).toFixed(2)
        this.midConsume = +(consumePercent[1].percent * 100).toFixed(2)
        this.heighConsume = +(consumePercent[0].percent * 100).toFixed(2)
        this.femalePercent = +(sexPercent[0].percent * 100).toFixed(2)
        this.malePercent = +(sexPercent[1].percent * 100).toFixed(2)
      })
    }
  }
};
</script>
<style lang="scss" scoped>

.portrait {
  background-image: url("../../assets/images/weather/oxygen_bg@2x.png");
  background-repeat: no-repeat;
  width: 1024px;
  height: 266px;
  .content {
    display: flex;
  }
  .title {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    //font-weight: normal;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 32px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
  }
  .left {
    margin-left: 50px;
    margin-top: 10px;
    width: 480px;
    .description {
      text-align: left;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #83A5E1;
      line-height: 24px;
      margin-top: -8px;
    }
    .process {
      width: 300px;
      height: 26px;
      border: 1px solid #2052A1;
      border-radius: 0px 13px 13px 0px;
      margin-bottom: 11px;
      position: relative;
      .age {
        position: absolute;
        top: 0;
        left: 8px;
        z-index: 9;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FEFEFF;
        line-height: 26px;
      }
      .percent {
        position: absolute;
        top: 0;
        left: 258px;
        font-size: 16px;
        font-family: Impact;
        font-weight: 400;
        color: #FEFEFF;
        line-height: 26px;
      }
      @keyframes progressIn {
        0% {
          width: 0
        }
      }
      .process_bar {
        position: absolute;
        margin-top: 2px;
        height: 20px;
        z-index: 8;
        border-radius: 0px 10px 10px 0px;
        &.process_bar_active {
          animation: progressIn 5s linear;
        }
      }
    }
  }
  .right {
    width: 400px;
    .top {
      padding: 0 10px;
      .gender {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 19px;
        .boy {
          display: flex;
          .sex {
            width: 50px;
            height: 50px;
            line-height: 44px;
            border-radius: 50%;
            border: 1px solid #00AEFF;
            background: rgba(0, 211, 193, 0.1);
            color: #00AFCF;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 20px;
          }
          .num-name {
            margin-left: 9px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .num {
              font-size: 26px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #005FFF;
              background: linear-gradient(0deg, #00D8BF 0%, #009CD6 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .name {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
        .girl {
          display: flex;
          .sex {
            width: 50px;
            height: 50px;
            line-height: 44px;
            border-radius: 50%;
            border: 1px solid #E17D83;
            background: rgba(255, 37, 37, 0.1);
            color: #FF8686;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 20px;
          }
          .num-name {
            text-align: right;
            margin-right: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .name {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
            }
            .num {
              font-size: 26px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #005FFF;
              background: linear-gradient(0deg, #FF1B1B 2.05078125%, #FF8989 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .process_line {
        display: flex;
        margin-bottom: 9px;
        .process_boy_line {
          border-width: 0px 6px 6px 0px;
          border-style: none solid solid;
          border-color: transparent transparent rgba(1, 159, 233, 1);
        }
        .process_girl_line {
          border-width: 6px 0px 6px 6px;
          border-style: solid solid none;
          border-color: rgba(255, 57, 100, 1) transparent transparent;
        }
      }
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 13px;
        .item {
          background: url('../../assets/images/homestay/consumption_bg@2x.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 83px;
          height: 98px;
          padding-top: 20px;
          position: relative;
          // img {
          //   position: absolute;
          //   width: 83px;
          //   height: 98px;
          //   top: 0;
          //   left: -20px;
          // }
          .line {
            @keyframes topProcessIn {
              0% {
                left: 0px;
              }
              100% {
                left: 100%;
              }
            }
            position: absolute;
            top: 0;
            left: 0;
            width: 48px;
            height: 2px;
            overflow: hidden;
            .process {
              position: absolute;
              left: 0;
              width: 50%;
              height: 100%;
              background-image: linear-gradient(to right, #175b79, rgba(255, 255, 255, 0));
              animation: topProcessIn 2s linear infinite;
            }
          }
          .line-left {
            @keyframes processIn {
              0% {
                top: 12px;
                height: 50%;
              }
              100% {
                top: 100%;
                height: 50%;
              }
            }
            position: absolute;
            top: 0px;
            left: 80px;
            width: 2px;
            height: 83px;
            overflow: hidden;
            .process {
              position: absolute;
              left: 0;
              width: 100%;
              height: 0;
              background-image: linear-gradient(to bottom, #175b79, rgba(255, 255, 255, 0));
              animation: processIn 2s 2s infinite;
            }
          }
          .line-bottom {
            @keyframes bottomProcessIn {
              0% {
                right: 0px;
                width: 50%;
              }
              100% {
                right: 90%;
                width: 50%;
              }
            }
            position: absolute;
            top: 95px;
            right: 0;
            width: 48px;
            height: 2px;
            overflow: hidden;
            .process {
              position: absolute;
              right: 0;
              width: 0;
              height: 100%;
              background-image: linear-gradient(to right, #175b79, rgba(255, 255, 255, 0));
              animation: bottomProcessIn 2s 4s infinite;
            }
          }
          .line-right {
            @keyframes rightProcessIn {
              0% {
                bottom: 12px;
                height: 50%;
              }
              100% {
                bottom: 100%;
                height: 50%;
              }
            }
            position: absolute;
            bottom: 5px;
            left: 0px;
            width: 2px;
            height: 59px;
            overflow: hidden;
            .process {
              position: absolute;
              left: 0;
              width: 100%;
              height: 0;
              background-image: linear-gradient(to bottom, #175b79, rgba(255, 255, 255, 0));
              animation: rightProcessIn 2s 6s infinite;
            }
          }
          .name {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #83A5E1;
            padding-top: 4px;
          }
          .num {
            font-size: 26px;
            font-family: Impact;
            font-weight: bold;
            color: #019FE9;

          }
        }
    }
  }
}




</style>
