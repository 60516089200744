<template>
  <div class="content">
    <div class="card-title">流量统计</div>
    <div class="wrap">
      <div class="item">
        <div class="item-title">今日游客</div>
        <div class="item-data">{{ 132 }}</div>
      </div>
      <div class="item">
        <div class="item-title">人流量</div>
        <div class="item-data">
          {{ flowStatistics.peopleFlow }}<span>/小时</span>
        </div>
      </div>
      <div class="item">
        <div class="item-title">累计人数</div>
        <div class="item-data">{{ flowStatistics.totalPeople }}</div>
      </div>
    </div>

    <div style="height: 180px">
      <div id="myChart" style="height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['refreshNum'],
  data() {
    return {
    };
  },
  computed: {
    flowStatistics() {
      return this.$store.state.videoData.flowStatistics;
    },
  },
  watch: {
    flowStatistics: function() {
      this.drawLine(this.flowStatistics);
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.drawLine(this.flowStatistics);
    },
  },
  methods: {
    drawLine(val) {
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      const option = {
        grid: {
          left: 0,
          top: 40,
          bottom: 0,
          right: 0,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: val.charts.xData,
          axisLabel: {
            color: "#83A5E1",
            fontSize: 12,
            fontFamily: "MicrosoftYaHei",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#375D9E",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#83A5E1",
            fontSize: 12,
            fontFamily: "MicrosoftYaHei",
          },

          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#375D9E",
            },
          },
        },
        series: [
          {
            data: val.charts.yData,
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "#428AF9",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(47, 94, 177, 0.1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(41, 74, 144, 0.9)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 340px;
  padding: 50px 25px 15px;
  background: {
    image: url("../../assets/images/videoBeta/statistics.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }

  .card-title {
    top: 8px;
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item {
      flex: 1;
      .item-title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #cfdbe9;
        margin-bottom: 10px;
      }

      .item-data {
        font-size: 26px;
        font-family: Impact;
        font-weight: 400;
        color: #cfdbe9;

        span {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
