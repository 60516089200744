<template>
  <div class="content">
    <div class="card-title">车辆流量</div>
    <div class="flow-wrap">
      <div
        class="flow-item"
        v-for="(item, idx) in carFlow"
        :key="idx"
        :class="{
          'f-green': item.statusName === '畅通',
          'f-yellow': item.statusName === '拥堵',
          'f-red': item.statusName === '堵塞',
        }"
      >
        <div class="f-text">
          <span class="f-t1">{{ item.crossName }}</span>
          <span class="f-status">{{
            item.statusName
          }}</span>
          <span class="car"
            ><span class="car-count">{{ item.carNum }}</span
            >辆</span
          >
        </div>
        <div :class="['f-progress', { 'process_bar_active': active }]" :style="{ width: `${item.progress}%` }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['refreshNum'],
  computed: {
    carFlow() {
      return this.$store.state.videoData.carFlow.slice(0, 3);
    },
  },
  data() {
    return {
      active: false
    }
  },
  watch: {
    refreshNum: function () {
      if (this.refreshNum > 0) {
        this.active = true
        setTimeout(() => {
          this.active = false
        }, 6000)
      }
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 333px;
  padding: 45px 25px;
  background: {
    image: url("~@/assets/images/videoBeta/car_flow.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  .card-title {
    top: 11px;
  }
}

.flow-item {
  padding: 15px 0;

  .f-text {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #cfdbe9;
  }

  .f-t1 {
    margin-right: 19px;
  }

  .car {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .car-count {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #cfdbe9;
      margin-right: 7px;
    }
  }
}
@keyframes progressIn {
  0% {
    width: 0
  }
}
.f-green {
  .f-progress {
    height: 8px;
    background: linear-gradient(90deg, rgba(0, 243, 141, 0) 0%, #00f38d 100%);
    border-radius: 0px 4px 4px 0px;
    margin-top: 14px;
    &.process_bar_active {
      animation: progressIn 4s linear;
    }
  }
  .f-status {
    color: #01ed8c;
  }
}

.f-yellow {
  .f-progress {
    height: 8px;
    background: linear-gradient(90deg, rgba(251, 199, 112, 0) 0%, #fbc770 100%);
    border-radius: 0px 4px 4px 0px;
    margin-top: 14px;
    &.process_bar_active {
      animation: progressIn 4s linear;
    }
  }
  .f-status {
    color: #fac670;
  }
}

.f-red {
  .f-progress {
    height: 8px;
    background: linear-gradient(90deg, rgba(255, 77, 77, 0) 0%, #ff4d4d 100%);
    border-radius: 0px 4px 4px 0px;
    margin-top: 14px;
    &.process_bar_active {
      animation: progressIn 4s linear;
    }
  }
  .f-status {
    color: #ff4e4d;
  }
}
</style>
