<template>
  <div class="source">
    <div class="title">车辆来源</div>
    <!-- <img class="map" src="../../assets/images/park/map@2x.png"> -->
    <div id="sourceChart" style="width: 100%; height: 50%"></div>
    <div class="rank">
      <div class="rank_title">
        <div>排</div><div>行</div><div>榜</div>
       </div>
      <div class="rank_list">
        <div class="rank_sub_title">
          <div class="item">排名</div>
          <div class="item">内外</div>
          <div class="item">地区</div>
          <div class="item">百分比</div>
        </div>
        <div class="rank_item" v-for="(item, idx) in rankList" :key="item.rank">
            <div class="item rank_num">
              <img v-if="idx === 0" src="../../assets/images/park/rank_one@2x.png" />
              <img v-if="idx === 1" src="../../assets/images/park/rank_two@2x.png" />
              <img v-if="idx === 2" src="../../assets/images/park/rank_three@2x.png" />
              <span v-if="idx > 2">{{ item.rank }}</span>
            </div>
            <div class="item">{{ item.name }}</div>
            <div class="item">{{ item.area }}</div>
            <div class="item">{{ item.percent }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chinaJson from "./china.json";
import getMapOption from "./mapOption.js";

export default {
  props: ['refreshNum'],
  data() {
    return {
      rankList: [
      ],
      geoCoordMap : {
         '新疆': [86.61 , 40.79],
         '西藏':[89.13 , 30.66],
         '黑龙江':[128.34 , 47.05],
         '吉林':[126.32 , 43.38],
         '辽宁':[123.42 , 41.29],
         '内蒙古':[112.17 , 42.81],
         '北京':[116.40 , 40.40 ],
         '宁夏':[106.27 , 36.76],
         '山西':[111.95,37.65],
         '河北':[115.21 , 38.44],
         '天津':[117.04 , 39.52],
         '青海':[97.07 , 35.62],
         '甘肃':[103.82 , 36.05],
         '山东':[118.01 , 36.37],
         '陕西':[108.94 , 34.46],
         '河南':[113.46 , 34.25],
         '安徽':[117.28 , 31.86],
         '江苏':[120.26 , 32.54],
         '上海':[121.46 , 31.28],
         '四川':[103.36 , 30.65],
         '湖北':[112.29 , 30.98],
         '浙江':[120.15 , 29.28],
         '重庆':[107.51 , 29.63],
         '湖南':[112.08 , 27.79],
         '江西':[115.89 , 27.97],
         '贵州':[106.91 , 26.67],
         '福建':[118.31 , 26.07],
         '云南':[101.71 , 24.84],
         '台湾':[121.01 , 23.54],
         '广西':[108.67 , 23.68],
         '广东':[113.98 , 22.82],
         '海南':[110.03 , 19.33],
         '澳门':[113.54 , 22.19],
         '香港':[114.17 , 22.32],
      },
      chinaMap:[],
      chinaColor:[]
    };
  },
  mounted() {
    // this.renderMap();
    this.getData()
  },
  watch: {
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/carFlow/getSourceCar')
      .then((data) => {
          let listVlaue=data.list
          for(let i=0;i<listVlaue.length;i++){
            for(let j in this.geoCoordMap){
              if(listVlaue[i]==j){
                //console.log(j)
                let item=[
                  { name: j, coord: this.geoCoordMap[j] },
				          { name: "浙江省", coord: [120.153576,30.287459] }
                ]
                let itemColor={ color: this.rgb(), value: this.geoCoordMap[j] }
                this.chinaMap.push(item)
                this.chinaColor.push(itemColor)
              }
            }
          }
          console.log(this.chinaMap, 'this.chinaMap');
          //console.log(this.chinaMap)
          this.chinaColor.unshift({ color: 'rgba(45, 140, 248, 0.5)', value: [120.153576, 30.287459] })
          console.log(this.chinaColor)
          this.renderMap()
          const { sources = [], list = [] } = data || {}
          this.rankList = sources.map((item, index) => ({
          rank: index + 1,
          name: item.inOrOut,
          area: item.city,
          percent: item.rate,
        }))

      })
    },
    renderMap() {
      let myChart = this.$echarts.init(document.getElementById("sourceChart"));
      console.log(chinaJson,33333)
      this.$echarts.registerMap("china", chinaJson);
      myChart.setOption(getMapOption(this.chinaMap,this.chinaColor));
    },
     rgb(){//rgb颜色随机
			var r = Math.floor(Math.random()*256);
			var g = Math.floor(Math.random()*256);
			var b = Math.floor(Math.random()*256);
			var rgb = 'rgba('+r+','+g+','+b+',0.5)';
			return rgb;
		}
  }
};
</script>
<style lang="scss" scoped>
  .source {
    margin-right: 10px;
    background-image: url("../../assets/images/park/source_bg@2x.png");
    background-size: contain;
    width: 409px;
    height: 650px;
    padding-top: 8px;
    position: relative;
    .map {
      width: 334px;
      height: 275px;
      position: absolute;
      left: 33px;
      top: 64px;
    }
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      font-family: 'MicrosoftYaHei-Bold';
    }
    .rank {
      background-size: contain;
      background-image: url("../../assets/images/park/rank_bg@2x.png");
      background-repeat: no-repeat;
      width: 376px;
      display: flex;
      margin-left: 15px;
      height: 262px;
        .rank_title {
            font-size: 15px;
            font-family: 'MicrosoftYaHei-Bold';
            color: #D4E7FF;
            height: 262px;
            width: 40px;
            padding-top: 80px;
            font-weight: bold;
            div {
              margin-top: 10px;
            }
        }
        .rank_list {
          width: 356px;
          .rank_sub_title {
            display: flex;
            font-size: 13px;
            color: #2B88E7;
            justify-content: space-around;
            align-items: center;
            height: 30px;
            font-weight: 400;
          }
          .rank_item {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 29px;
            font-size: 13px;
            color: #B8D0F5;
            font-weight: 400;
            .rank_num {
              font-weight: bold;
            }
            img {
              width: 17px;
              height: 22px;
              margin-top: 4px;
            }
          }
        }
       .item {
         width: 25%
       }
    }
  }

</style>
