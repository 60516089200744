<template>
  <div class="people-num">
    <div class="title">人口数量</div>
    <div class="total">
      <span class="title">总增长</span>
      <span class="num">
        <countTo ref="num" :startVal='0' :endVal='totalIncrement' :duration='4000' style="min-width: 70px;display: inline-block;"></countTo>
      </span>
      <span class="unit">人口</span>
    </div>
    <div id="myChart" style="height: 75%;margin-left: 10px;"></div>
    <div class="legend legend_left" />
    <div class="legend legend_right" />
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import toThousands from '../../utils/toThousands'

export default {
data() {
    return {
      chart: null,
      option: {},
      lastYearList: [],
      yearList: [],
      dateMap: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dece: '12月',
      }
    }
  },
  components: {
    countTo
  },
  props: ['totalIncrement', 'isChecked', 'refreshNum'],
  mounted() {
    this.getData();    //执行下面的函数
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) {
        this.restart();
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/village/populationChange')
      .then(({ result }) => {
        this.lastYearList = result[1] || {}
        this.yearList = result[0] || {}
        this.lastYearList = Object.keys(this.lastYearList)
          .filter((item, index) => this.dateMap[item] && index <= new Date().getMonth() + 1)
          .map((item, index) => [this.dateMap[item], this.lastYearList[item]])
        this.yearList = Object.keys(this.yearList)
          .filter((item, index) => this.dateMap[item])
          .map((item, index) => [this.dateMap[item], this.yearList[item]])
        this.drawLine();
      })
    },
    restart() {
      this.$refs.num && this.$refs.num.start()
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    formatNum(num) {
      return toThousands(num)
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("myChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          legend: {
            data: ['去年', '本年'],
            textStyle: {
                color: '#83A5E1',
                fontSize: 10,
                width: 40
            },
            icon: 'none',
            right: 30,
            top: 25
          },
          xAxis: {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                padding: [0,0,0,30],
                width: 20,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
              splitNumber: 5,
          },
          yAxis: {
              type: 'value',
              name: '单位/个',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 10,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.yearList,
              type: 'line',
              name: '本年',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(32, 184, 237, 1)'
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0.5, color: 'rgba(32, 184, 237, 0.4)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
                // color: 'rgba(55, 93, 158, 0.5)'
              }
          },{
              data: this.lastYearList,
              type: 'line',
              smooth: true,
              name: '去年',
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(0, 148, 255, 1)'
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0.5, color: 'rgba(32, 184, 237, 0.4)' // 0% 处的颜色
                    },{
                        offset: 1, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
                // color: 'rgba(32, 184, 237, 0.5)'
              }
          }]
      };
      this.chart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.people-num {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #FFFFFF;
    line-height: 32px;
  }
  background: url("../../assets/images/housekeeper/people_num@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 357px;
  margin-top: 10px;
  .total {
     margin-top: 29px;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #CFDBE9;
    }
    .unit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8995A4;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      margin: 0 8px;
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
  }
  .legend_left {
    right: 130px;
    top: 133px;
    background: rgba(0, 148, 255, 1)
  }
  .legend_right {
    right: 70px;
    top: 133px;
    background: rgba(32, 184, 237, 1)
  }
}


</style>
