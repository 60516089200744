<template>
  <div class="content">
    <div class="title">客流量满载预警</div>
    <div class="wrap">
      <vue-seamless-scroll
        :data="busyRoad"
        :class-option="classOption"
        class="listWrap"
      >
        <div
          :class="['item', item.loadFactor === '堵塞' ? 'item-busy' : '', item.loadFactor === '拥堵' ? 'item-busy-1' : '',item.loadFactor === '畅通' ? 'item-busy-2' : '']"
          v-for="(item, idx) in busyRoad"
          :key="idx"
        >
          <span class="item-name">{{ item.areaName }}</span>
          <span class="item-status">{{ item.loadFactor }}</span>
          <div v-if="item.loadFactor === '堵塞'" class="item-icon"></div>
          <div v-if="item.loadFactor === '拥堵'" class="item-yd-icon"></div>
          <div v-if="item.loadFactor === '畅通'" class="item-yd-icon2"></div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  mounted() {
    this.getData()
  },
  data() {
    return {
      classOption: {
        singleHeight: 50,
      },
      busyRoad: [
      ]
    };
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/passenger/flow/warn')
      .then(({ data }) => {
        this.busyRoad = data
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 285px;
  padding: 0 25px 15px;
  overflow: hidden;
  margin-top: 18px;
  background: {
    image: url("../../assets/images/abortion/distribute.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }

  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 40px;
  }

  .listWrap {
    overflow: hidden;
  }

  .wrap {
    overflow: hidden;
    height: 200px;
    margin-top: 10px;
    margin-left: 15px;
    .item {
      display: flex;
      align-items: center;
      width: 318px;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #cfdbe9;
      margin-bottom: 10px;
      background: linear-gradient(
        90deg,
        rgba(47, 130, 236, 0.1) 0%,
        rgba(6, 65, 140, 0) 100%
      );

      .item-name {
        margin-right: 29px;
        text-align: left;
        width: 170px;
      }
    }

    .item-busy {
      background: linear-gradient(
        90deg,
        rgba(255, 77, 77, 0.69) 0%,
        rgba(255, 77, 77, 0.35) 33%,
        rgba(6, 65, 140, 0) 100%
      );

      .item-icon {
        width: 28px;
        height: 28px;
        background: rgba($color: #ff4d4d, $alpha: 0.4);
        border-radius: 50%;
        position: relative;
        margin-left: 38px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: rgba($color: #ff4d4d, $alpha: 0.5);
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: #ff4d4d;
          border-radius: 50%;
        }
      }
    }
    .item-busy-1 {
      background: linear-gradient(
        90deg,
        rgba(229, 233, 14, 0.69) 0%,
        rgba(229, 233, 14, 0.35) 33%,
        rgba(6, 65, 140, 0) 100%
      );
      .item-yd-icon {
        width: 28px;
        height: 28px;
        background: rgba($color: rgb(229, 233, 14), $alpha: 0.4);
        border-radius: 50%;
        position: relative;
        margin-left: 38px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: rgba($color: rgb(229, 233, 14), $alpha: 0.5);
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: rgb(229, 233, 14);
          border-radius: 50%;
        }
      }
    }
    .item-busy-2 {
      background: linear-gradient(
        90deg,
        rgba(138, 233, 14, 0.69) 0%,
        rgba(14, 233, 14, 0.35) 33%,
        rgba(6, 65, 140, 0) 100%
      );
      .item-yd-icon2 {
        width: 28px;
        height: 28px;
        background: rgba($color: rgb(14, 233, 25), $alpha: 0.4);
        border-radius: 50%;
        position: relative;
        margin-left: 38px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: rgba($color: rgb(14, 233, 25), $alpha: 0.5);
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: rgb(14, 233, 25);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
