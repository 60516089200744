<template>
  <div :class="['left_main', { hide: isChecked }]">
    <FlowInfo />
    <FlowTotal />
    <Traffic />
  </div>
</template>

<script>
import FlowInfo from "./flow-info";
import FlowTotal from "./flow-total";
import Traffic from "./traffic";

export default {
  props: ["isChecked"],
  components: {
    FlowInfo,
    FlowTotal,
    Traffic
  },
};
</script>

<style lang="scss" scoped>
.left_main {
  width: 408px;
  position: relative;
  animation: bounceIn 4s;
  @keyframes bounceIn {
    0% {
      left: -500px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes bounceOut {
    0% {
      left: 0;
    }
    100% {
      left: -500px;
    }
  }
  &.hide {
    animation: bounceOut 4s;
    left: -500px;
  }
}
</style>
