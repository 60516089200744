<template>
  <div class="container">
    <!-- <div class="mark" /> -->
    <head-top :isChecked="isChecked" title="芦茨民宿" @changeChecked="handleChecked" />
    <div :class="['left', { 'hide': isChecked }]">
        <base-info :isChecked="isChecked" :refreshNum="refreshNum" />
        <checkIn :isChecked="isChecked" :refreshNum="refreshNum" />
        <earnings :isChecked="isChecked" :refreshNum="refreshNum" />
        <div class="back" @click="back">返回</div>
    </div>
    <div :class="['bottom', { 'hide': isChecked }]">
        <portrait  :portraitInfo="allDatas.portraitInfo" :isChecked="isChecked" :refreshNum="refreshNum" />
    </div>
    <div :class="['right', { 'hide': isChecked }]">
       <base-info-income :isChecked="isChecked" :refreshNum="refreshNum" />
        <rank :rankInfo="allDatas.rankInfo" :refreshNum="refreshNum"/>

        <passenger-flow :isChecked="isChecked" :refreshNum="refreshNum" />
    </div>
    <!-- <icons /> -->
  </div>
</template>

<script>
import moment from "moment";
// import request from 'superagent';
import headTop from '@/components/park/menu';
import baseInfo from '@/components/homestay/baseInfo';
import baseInfoIncome from '@/components/homestay/baseInfoIncome';
import checkIn from '@/components/homestay/checkIn';
import earnings from '@/components/homestay/earnings';
import rank from '@/components/homestay/rank';
import passengerFlow from '@/components/homestay/passenger-flow';
import portrait from '@/components/homestay/portrait';
// import comparison from '@/components/homestay/comparison';
// import icons from '@/components/homestay/icon';
var times2=null;
export default {
  components: {
    headTop,
    baseInfo,
    baseInfoIncome,
    checkIn,
    earnings,
    rank,
    portrait,
    passengerFlow,
    // icons,
  },
  data() {
    return {
      refreshNum: 0,
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
      isChecked: false,
      allDatas: {
        baseInfo: { },
        checkInInfo: {
          remainRate: 67,
          remain: 301,
          checkIn: 145
        },
        earningsInfo: {

        },
        portraitInfo: {
          sexInfo: { boyInfo: {}, girlInfo: {} },
          consumptionList: [],
          ageList: []
        },
        comparisonInfo: {
          list: []
        },
        rankInfo: {
          rankList: []
        }
      }
    };
  },
  mounted() {
       times2 = setInterval(() => {
          if (window.iframe && window.iframe.contentWindow.isLoading) {
              window.iframe.contentWindow.unityInstance.SendMessage("Main Camera", "SetSence", "view04, 270.6,-284.3,136.7");
              window.iframe.contentWindow.$(".Tip").fadeOut(200);
              clearInterval(times2)
          }

      }, 1000)

    this.getData()
    setInterval(() => {
      setTimeout(() => {
        this.getData()
      }, 0)
    }, 15000)
    setInterval(() => {
      this.time = moment().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
    this.getData()
  },
  methods: {
    getData() {
      // this.$api.get('/admin/mock/json/anchu_1_homestay')
      // .then(({ data }) => {
      //   this.allDatas = data
      // })
      this.refreshNum = this.refreshNum + 1
    },
    handleChecked() {
      this.isChecked = !this.isChecked;
    },
    back() {
      this.$router.back(-1)
    }
  },
};
</script>

<style lang="scss" scoped>

 .container {
   width: 1920px;
   height: 100%;
   background: #011326;
   margin: 0 auto;
   padding: 0;
   position: relative;
   /*background: url("../assets/images/park/bg.png") no-repeat;*/
   background-size: contain;
   .bgImg {
     background-size: contain;
   }
   .mark {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background: rgba(0, 9, 18, 0.5);
   }
}
.back {
  background: url("../assets/images/park/back@2x.png") no-repeat;
  position: absolute;
  left: 440px;
  top: 10px;
  z-index: 10000;
  width: 75px;
  height: 45px;
  font-size: 15px;
  background-size: contain;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 37px;
  letter-spacing: 2px;
  transform: none;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.left {
    top: 74px;
  left: 18px;
  @keyframes bounceLeftIn {
    0% {
      left:  -520px
    }
    100% {
      left: 18px
    }
  }
  @keyframes bounceLeftOut {
    0% {
      left: 18px
    }
    100% {
      left: -520px
    }
  }
  &.hide {
    animation: bounceLeftOut 1s linear;
    left: -520px
  }
  position: absolute;
  z-index: 999;
  animation: bounceLeftIn 1s linear;
}

.right {
  position: absolute;
  right: 18px;
  top: 54px;
  z-index: 9999;
  @keyframes bounceRightIn {
    0% {
      right: -400px
    }
    100% {
      right: 18px;
    }
  }
  @keyframes bounceRightOut {
    0% {
      right: 18px;
    }
    100% {
      right: -420px
    }
  }
  &.hide {
    animation: bounceRightOut 1s linear;
    right: -420px
  }
  animation: bounceRightIn 1s linear;
}

.bottom {
  position: absolute;
  bottom: 15px;
  left: 446px;
  display: flex;
  z-index: 9999;
  @keyframes bounceBottomIn {
    0% {
      bottom: -400px
    }
    100% {
      bottom: 15px
    }
  }
  @keyframes bounceBottomOut {
    0% {
      bottom: 15px
    }
    100% {
      bottom: -400px
    }
  }
  &.hide {
    animation: bounceBottomOut 1s linear;
    bottom: -400px
  }
  animation: bounceBottomIn 1s linear;

}

</style>
