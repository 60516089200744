<template>
  <div class="content">
    <div class="card-title" style="font-weight: bold">
      热点区域监控
      <!-- <router-link to="/video-beta"></router-link> -->
    </div>
    <div class="grid">
      <div class="col">
        <!-- <div class="head">
          <img src="../../assets/images/home/jkxs.png" alt="" />
          <span>12.23.34</span>
        </div> -->
        <video-player
          ref="videoContainer0"
          class="monitor-preview vjs-custom-skin"
          style="margin-left: 4px"
          :events="events"
          @play="onPlayerPlay0($event)"
          @pause="onPlayerPause0($event)"
          @playing="onPlayerPlaying0($event)"
          @fullscreenchange="onPlayerFullScreenchange($event)"
          :options="playerOptions[0] || {}"
        >
        </video-player>
        <!-- <img class="monitor-pic" src="" /> -->
        <div class="monitor-name">{{ (data[0] || {}).name }}</div>
      </div>
      <div class="col">
        <!-- <div class="head">
          <img src="../../assets/images/home/jkxs.png" alt="" />
          <span>12.23.34</span>
        </div> -->
        <video-player
          ref="videoContainer1"
          class="monitor-preview vjs-custom-skin"
          :events="events"
          @fullscreenchange="onPlayerFullScreenchange($event)"
          @play="onPlayerPlay1($event)"
          @pause="onPlayerPause1($event)"
          @playing="onPlayerPlaying1($event)"
          @ready="playerReadied($event)"
          @loadeddata="onPlayerLoadeddata1($event)"
          @waiting="onPlayerWaiting1($event)"
          @timeupdate="onPlayerTimeupdate1($event)"
          @canplay="onPlayerCanplay1($event)"
          @canplaythrough="onPlayerCanplaythrough1($event)"
          @statechanged="playerStateChanged1($event)"
          :options="playerOptions[1] || {}"
        >
        </video-player>
        <!-- <img class="monitor-pic" src="" /> -->
        <div class="monitor-name">{{ (data[1] || {}).name }}</div>
      </div>
      <div class="col">
        <!-- <div class="head">
          <img src="../../assets/images/home/jkxs.png" alt="" />
          <span>12.23.34</span>
        </div> -->
        <video-player
          ref="videoContainer2"
          class="monitor-preview vjs-custom-skin"
          :events="events"
          :options="playerOptions[2] || {}"
          @play="onPlayerPlay2($event)"
          @pause="onPlayerPause2($event)"
          @playing="onPlayerPlaying2($event)"
          @fullscreenchange="onPlayerFullScreenchange($event)"
        >
        </video-player>
        <!-- <img class="monitor-pic" src="" /> -->
        <div class="monitor-name">{{ (data[2] || {}).name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import { videoPlayer } from "vue-video-player"; //导入组件
import "videojs-contrib-hls";

export default {
  props: ["mList"],
  components: {
    videoPlayer,
  },
  data() {
    return {
      playerOptions: [],
      Playing0: true,
      Playing2: true,
      data: [],
      events: ["fullscreenchange"],
      defaultOptions: {
        overNative: true,
        autoplay: false, // 自动播放
        muted: true, // 静音
        controls: true, // 控制条
        loop: false,
        height: 170,
        techOrder: ["html5"],
        sourceOrder: true,
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            withCredentials: false,
            type: "application/x-mpegURL",
            src: "",
          },
        ],
        poster: "/vue-videojs-demo/static/images/logo.png",
        // controlBar: {
        //   timeDivider: false, // 时间分割线
        //   durationDisplay: false, // 总时间
        //   progressControl: true, // 进度条
        //   customControlSpacer: true, // 未知
        //   fullscreenToggle: true // 全屏
        // },
        notSupportedMessage: `当前无可播放视频源`,
      },
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    player0() {
      return this.$refs.videoContainer0.player;
    },
    player1() {
      return this.$refs.videoContainer1.player;
    },
    player2() {
      return this.$refs.videoContainer2.player;
    },
  },
  methods: {
    onPlayerFullScreenchange(player) {
     // console.log(player, "111");
      // player.exitFullscreen()
    },
    onPlayerPlay0(e) {
      //if (!this.Playing0) {
        this.player1.pause();
        this.player2.pause();
        //this.player0.play();
        console.log("暂停0");
      //}
    },
    onPlayerPlay1(e) {

      this.player0.pause();
      this.player2.pause();
      //this.player1.play();
      console.log("暂停1");
    },
    onPlayerPlay2(e) {
      // if (!this.player2) {
        this.player1.pause();
        this.player0.pause();
        //this.player2.play();
        console.log("暂停2");
      //}
    },
    onPlayerPlaying0(e) {
      // console.log("开始0");
      // if (this.Playing0) {
      //   this.player0.pause();
      //   this.player0 = false;
      // }
    },
    onPlayerPlaying1(e) {
      //console.log("开始1");
    },
    onPlayerPlaying2(e) {
      // console.log("开始2");
      // if (this.Playing2) {
      //   this.player2.pause();
      //   this.player2 = false;
      // }
    },
    onPlayerLoadeddata1(e){
      //console.log("测试1")
    },
    onPlayerWaiting1(e){
      //console.log("测试2")
    },
    onPlayerTimeupdate1(e){

    },
    onPlayerCanplay1(e){

    },
    onPlayerCanplaythrough1(){

    },
    playerStateChanged1(e){

    },
    onPlayerPause2(e) {},
    onPlayerPause1(e) {},
    onPlayerPause0(e) {},
    playerReadied(e) {
      console.log(e);
    },
    async initData() {
      var that = this;
      try {
        const { data } = await api.device.queryMainCamera();
        // const { data } = {
        //   code: "0",
        //   msg: "success",
        //   data: {
        //     treeDataList: [
        //       {
        //         hlsUrl: "http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8"
        //       },
        //       {
        //         hlsUrl: "http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8"
        //       },
        //       {
        //         hlsUrl: "http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8"
        //       },
        //     ],
        //   },
        // };
        // http://live.hkstv.hk.lxdns.com/live/hks/playlist.m3u8
        // http://10.1.219.55:83/openUrl/N9p7lde/live.m3u8
        // http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8
        setTimeout(() => {
          this.playerOptions = [];
          this.data = data.treeDataList;
          data.treeDataList.map((item, index) => {
            this.playerOptions.push({
              ...this.defaultOptions,
              autoplay: false,
              sources: [
                {
                  withCredentials: false,
                  type: "application/x-mpegURL",
                  src: item.hlsUrl || "",
                },
              ],
            });
          });
           this.playerOptions[1].autoplay=true
          console.log(this.playerOptions);
          //console.log(this.playerOptions)
          //  setTimeout(()=>{
          //      this.playerOptions[0].autoplay=false
          //  this.playerOptions[2].autoplay=false
          //  },20000)
        }, 100);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  width: 1031px;
  height: 278px;
  padding: 36px 20px 20px 20px;
  margin: 0 auto;
  align-self: flex-end;
  background: {
    image: url("../../assets/images/abortion/safe-monitor.png");
    repeat: no-repat;
    size: contain;
    position: center;
  }
}
.grid {
  display: grid;
  width: 100%;
  height: 184px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 34px;
}
.head {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b8d0f5;
  img {
    flex: 1;
  }
  span {
    margin-left: 10px;
  }
}
.monitor-preview {
  width: 300px;
  height: 170px;
  margin-top: 20px;
}
.monitor-pic {
  width: 300px;
  height: 170px;
  border: 2px solid #1c97ff;
  background: {
    image: url("https://img-bss.csdnimg.cn/1606271766185.jpg");
    size: cover;
    repeat: no-repat;
    position: center;
  }
}
.monitor-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b8d0f5;
}
</style>
