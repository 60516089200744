import axios from 'axios';

export var page = { index:null };
export async function getReq(timeout, code){

  
    return await new Promise((resolve, reject)=>{
      //联网
        //console.log(webURL + url);
        setTimeout(() => {
            
            axios({
                url: "https://hik.wanma2020.com/hik/getPreviewURLs",
                data: { cameraIndexCode: code },
                method: "POST"
              }).then((res)=>{
                let code = res.data.code || 200;
                if(code == 200){
                    let data = res.data.data;
                    data = JSON.parse(data);
                    data.data.url = data.data.url.replace('ws://218.108.194.138:559/', 'wss://hk.wanma2020.com:443/');
                    resolve({ code :200, data: data });
                }else{
                  //this.$alert(res, '系统提示');
                  resolve({code: 400});
                }
              }).catch((err=>{
                resolve({code: 400});
        
              }));

        }, timeout);
        
  
    });
  
  };