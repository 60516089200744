<template>
  <div class="main">
      <div class="header">
          <div class="header-center fl">
              <div class="header-title">
                  芦茨数字驾驶舱登录界面
              </div>
              <div class="header-img"></div>
          </div>
          <div class="header-bottom fl"></div>

      </div>

      <div class="content">
          <div class="content-left">
              <!--<img src="images/d.png" alt="">-->
          </div>
          <div class="content-right">
              <div class="right-infp">
                  <div class="right-infp-name">
                      <input
                        type="text"
                        name="username"
                        placeholder="请输入用户名"
                        v-model="username"
                        autocomplete="off"
                      >
                  </div>
                  <div class="right-infp-name">
                      <input
                        type="password"
                        name="name"
                        placeholder="请输入密码"
                        autocomplete="off"
                        v-model="password"
                      >
                  </div>

                  <div class="right-infp-btn">
                      <button class="btn" v-on:click="login">登录</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    login() {
      this.$api.post('/jeecg-boot/sys/login',{
        username: this.username,
        password: this.password
      })
      .then(({ result, message, code }) => {
        if (code === 200) {
          const token = result.token
          window.localStorage.token = token
          window.location.href = '/'
        } else {
          window.alert(message)
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.main {
  background: url("../assets/images/login/bj.jpg") no-repeat;
  background-size: cover;
  font-size: 8.5px;
  color: #ffffff;
  position: relative;
  z-index: 99999;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

/*头部*/
.header{
    width: 100%;
    height: 79.5px;
    transform: scale(1.5);
    /*background: red;*/

}

.header .header-center{
    width: 50%;
    height: 63.5px;
    position: relative;
    margin: 0 auto;
}
.header .header-center .header-title{
    text-align: center;
    color: #ffffff;
    font-size: 21.5px;
    text-shadow: 0 0 16px #00d8ff;
    line-height: 76px;
}
.header .header-img{
    background: url("../assets/images/login/head.gif") no-repeat center center;
    background-size: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 42.4px;
}

.content{
    transform: scale(1.5);
    width: 636px;
    height: 371px;
    /*background: green;*/
    margin: 10% auto 0 auto;
    /*border: 1px solid green;*/
}
.content .content-left{
    width: 329px;
    height: 307.5px;
    /*border: 1px solid red;*/
    background: url("../assets/images/login/d.png") no-repeat;
    background-size: 100% 100%;
    padding: 22px 0;
    box-sizing: border-box;
    margin-right: 2%;
    float: left;
}
@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.content .content-left {
    -webkit-transform: rotate(360deg);
    animation: rotation 15s linear infinite;
    -moz-animation: rotation 15s linear infinite;
    -webkit-animation: rotation 15s linear infinite;
    -o-animation: rotation 15s linear infinite;
}

.content .content-right{
    width:286.5px ;
    height: 307.5px;
    background: url("../assets/images/login/e.png") no-repeat;
    background-size: 100% 100%;
    float: right;
    position: relative;

}
.right-infp{
    width: 212px;
    height: 160px;
    margin: auto auto;
   /* border: 1px solid red;*/
    vertical-align: center;
    position: absolute;
    top:85px;
    left: 47.7px;
}
.right-infp-name{
    background: url("../assets/images/login/b.png") no-repeat;
    width: 100%;
    height: 26.5px;
    background-size: 100% 100%;
    line-height:24px;
    margin-bottom: 22px;
    position: relative;
}
.right-infp-name input{
    width: 85%;
    border: none;
    box-sizing: border-box;
    height: 26.5px;
    line-height: 22px;
    background: none !important;
    color: #00eaff;
    outline: none;
    -webkit-tap-highlight-color:rgba(255,0,0,0);
    position: absolute;
    left: 10px;
}
input::-webkit-input-placeholder {
    color: #ccc;
}
.right-infp-btn{
    background: url("../assets/images/login/a.png") no-repeat;
    width: 100%;
    height: 26.5px;
    background-size: 100% 100%;
    line-height: 26.5px;
    margin-bottom: 22px;
    position: relative;
}
.right-infp-btn .btn{
    width: 95%;
    border: none;
    box-sizing: border-box;
    height: 24px;
    margin-left: 5.3px;
    font-size: 11.7px;
    cursor: pointer;
    background: none;
    outline: none;
    color: #000;
    position: absolute;
    left: 0
}
.right-infp-btn .btn:hover{

}

</style>
