<template>
  <div class="content">
    <div class="card-title">设备情况</div>
    <div class="wrap">
      <vue-seamless-scroll
        :data="device"
        :class-option="classOption"
        class="listWrap"
      >
        <div
          :class="['item', item.statusName === '故障' ? 'item-busy' : '']"
          v-for="(item, idx) in device"
          :key="idx"
        >
          <span class="item-name">{{ item.name }}</span>
          <span class="item-status" >{{ item.statusName }}</span>
          <div v-if="item.statusName === '故障'" class="item-icon"></div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  computed: {
    device() {
      return this.$store.state.videoData.device;
    },
  },
  data() {
    return {
      classOption: {
        singleHeight: 50,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 436px;
  padding: 55px 25px 15px;
  overflow: hidden;
  background: {
    image: url("../../assets/images/videoBeta/device.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }

  .card-title {
    top: 8px;
  }

  .listWrap {
    overflow: hidden;
  }

  .wrap {
    overflow: hidden;
    height: 340px;
    .item {
      display: flex;
      align-items: center;
      width: 318px;
      height: 40px;
      line-height: 40px;
      padding-left: 31px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #cfdbe9;
      margin-bottom: 10px;
      background: linear-gradient(
        90deg,
        rgba(47, 130, 236, 0.1) 0%,
        rgba(6, 65, 140, 0) 100%
      );

      .item-name {
        width: 100px;
        text-align: left;
        margin-right: 29px;
      }
    }

    .item-busy {
      background: linear-gradient(
        90deg,
        rgba(255, 77, 77, 0.69) 0%,
        rgba(255, 77, 77, 0.35) 33%,
        rgba(6, 65, 140, 0) 100%
      );

      .item-icon {
        width: 28px;
        height: 28px;
        background: rgba($color: #ff4d4d, $alpha: 0.4);
        border-radius: 50%;
        position: relative;
        margin-left: 70px;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: rgba($color: #ff4d4d, $alpha: 0.5);
          border-radius: 50%;
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: #ff4d4d;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
