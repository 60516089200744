<template>
  <div class="container">
    <!-- <div class="mark"></div> -->
    <Menu :isChecked="isChecked" title="芦茨热点区域监控" @changeChecked="handleChecked" />
    <div class="main-content">
      <Left :isChecked="isChecked" />
      <VideoMonitor />
      <Right :isChecked="isChecked" />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/park/menu";
import Left from "@/components/video-monitor/left";
import Right from "@/components/video-monitor/right";
import VideoMonitor from "@/components/video-monitor/video-monitor";

export default {
  components: {
    Menu,
    VideoMonitor,
    Left,
    Right,
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    handleChecked() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<style lang="scss" scoped>
.mark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
.container {
  width: 1920px;
  height: 100%;
  background: #011326;
  margin: 0 auto;
  padding: 0;
  position: relative;
  background: url("../assets/images/main_bg.png") no-repeat;
  background-size: contain;
}
.main-content {
  position: absolute;
  display: flex;
  width: 1920px;
  height: 1065px;
  overflow: hidden;
  padding: 60px 16px 20px;
  justify-content: space-around;
  align-items: flex-end;
}
</style>
