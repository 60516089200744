<template>
  <div class="honour">
    <div class="title">光荣榜</div>
    <div class="list">
      <div class="item" v-for="(item, idx) in list" :key="item.excellent">
        <div class="rank rank_num" :style="{'background': idx > 2 ? '#1C4997' : 'none'}">
          <img v-if="idx === 0" src="../../assets/images/homestay/rank_one@2x.png" />
          <img v-if="idx === 1" src="../../assets/images/homestay/rank_two@2x.png" />
          <img v-if="idx === 2" src="../../assets/images/homestay/rank_three@2x.png" />
          <span v-if="idx > 2">{{ idx + 1 }}</span>
        </div>
        <div class="name">{{ item.name }}</div>
        <!-- <div class="property">{{ item.name }}</div>
        <div class="description">{{ item.name }}</div> -->
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['refreshNum'],
  mounted() {
    this.getData()
  },
  data() {
    return {
      list: []
    }
  },
  watch: {
    refreshNum: function () {
      if (this.refreshNum > 0) this.getData();
    },
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/village/gloryList')
      .then(({ result }) => {
         this.list = result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.honour {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #FFFFFF;
    line-height: 32px;
  }
  background: url("../../assets/images/housekeeper/honour@2x.png") no-repeat;
  background-size: contain;
  width: 504px;
  height: 266px;
  margin-top: 10px;
  .list {
    margin-top: 13px;
    .item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #B8D0F5;
      line-height: 28px;
      width: 446px;
      height: 30px;
      margin: 0 auto;
      margin-bottom: 10px;
      background: rgba(12, 38, 82, 0.6);
      border: 1px solid #1E4586;
      display: flex;
      justify-content: space-around;
      .description {
        text-align: left;
        min-width: 200px;
      }
      .rank_num {
        font-weight: bold;
        width: 18px;
        height: 18px;
        line-height: 18px;
        background: #1C4997;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #B8D0F5;
        margin-top: 4px;
      }
      img {
        width: 17px;
        height: 22px;
      }
      .item {
        text-align: center;
      }
    }
  }
}


</style>
