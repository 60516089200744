import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import request from "./utils/request";
import { getReq, page } from './utils/req.js'
import requesttrue from "./utils/requesttrue";
import mock from "./utils/mock";
import ECharts from "vue-echarts";
import * as echarts from "echarts";
import ElementUI from "element-ui";
import VueAwesomeProgress from "vue-awesome-progress";
import "element-ui/lib/theme-chalk/index.css";

import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;



Vue.config.productionTip = false;

Vue.prototype.$echarts = echarts;
Vue.prototype.$api = request;
Vue.prototype.$page = page;
Vue.prototype.$getReq = getReq;
Vue.prototype.$trueApi = requesttrue;
Vue.prototype.$mockApi = mock;

Vue.use(ElementUI);
Vue.use(VueAwesomeProgress);

Vue.prototype.$echarts = echarts;
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/axis";
import "echarts/lib/component/axisPointer";

Vue.component("v-chart", ECharts);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
