<template>
  <div class="oxygen">
    <div class="title">乡村氧吧</div>
    <div class="content">
      <div class="left">
        <div class="top-title">芦茨村<span class="tip"></span></div>
        <div class="info">
          <div class="process">
            <div class="num">{{ now.aqi }}</div>
            <div id="processChart" style="height: 120px;"/>
          </div>
          <div class="column">
            <div class="grade" :style="{'background': getColor(now.category)}">{{now.category}}</div>
            <div class="oxygen-title">负氧离子</div>
            <div class="oxygen-num">{{coMath}}<span class="unit">个/cm³</span></div>
          </div>
          <div class="column" style="margin-left: 38px;">
            <div class="pm-title">PM2.5</div>
            <div class="pm-num">{{now.pm2p5}}<span class="unit">ug/cm³</span></div>
            <div class="weather-title">温度</div>
            <div class="weather-num">{{temp}}<span class="unit">℃</span></div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top-title">未来空气质量</div>
        <div class="list">
          <div class="item" v-if="item.num !=neek" v-for="(item, idx) in list" :key="idx" >
            <div class="day">{{item.day}}</div>
            <div class="date">{{item.date}}</div>
            <div class="grade" :style="{'background': getColor(item.category)}">{{item.category}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getDate from '../../utils/getDate'

export default {
  data() {
    return {
      chart: null,
      option: {},
      list: [],
      temp: '',
      img: '',
      coMath:'',
      neek:new Date().getDay()
    }
  },

  props: ['isChecked', 'refreshNum', 'now'],
  mounted() {
    var self=this;
    self.coMath= parseInt(Math.random().toFixed(2)*900 + 1200);
    setInterval(function (){
      var num =parseInt(Math.random().toFixed(2)*900 + 1200) ;
      self.coMath=num

    },60*1000)
    this.getData();
    this.getWeather()
    window.setInterval(() => {
      setTimeout(() => {
        this.restart()
      }, 0)
    }, 15000 )
  },
  computed: {
    aqi: function () {
      return this.now.aqi;
    }
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
    aqi: function () {
      this.drawLine();
    }
  },
  methods: {
    restart() {
      this.getWeather()
      this.getData();
      if (this.chart) {
        this.chart.clear();
        this.chart.setOption(this.option, true);
      }
    },
    getWeather() {
      this.$api.get('/jeecg-boot/weather/weatherNow')
      .then(({ now = {} }) => {
        this.temp = now.temp
      })
    },
    getData() {
      this.$api.get('/jeecg-boot/weather/futureQuality5d')
      .then(({ daily = [] }) => {
        this.list = daily.map(item => {
          const month = new Date(item.fxDate).getMonth() + 1
          const date = new Date(item.fxDate).getDate()
          const fullDate = `${month}月${date}日`
          return ({ ...item, date: fullDate, day: getDate(item.fxDate) ,num:new Date(item.fxDate).getDay()})
        })
      })
    },
    getColor(grade) {
      let color = 'rgba(4, 194, 124, 1)'
      if (grade === '良') color = 'rgba(105, 189, 48, 1)'
      if (grade === '差') color = 'rgba(197, 66, 26, 1)'
      return color
    },
    drawLine() {
      this.chart = this.$echarts.init(document.getElementById("processChart"));
      this.option = {
        tooltip: {
          show: true,
        },
        angleAxis: {
          max: 100,
          clockwise: true, // 逆时针
          // 隐藏刻度线
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
        },
        polar: {
          center: ['50%', '50%'],
          radius: 112 //图形大小
        },
        series: [{
          type: 'bar',
          data: [this.aqi],
        	showBackground: true,
        	backgroundStyle: {
      		   color: '#BDEBFF',
      	  },
          coordinateSystem: 'polar',
          roundCap: true,
          barWidth: 8,
          animationDelay: 800,
          animationDuration: 4000,
          itemStyle: {
            normal: {
              opacity: 1,
              color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0, color: '#25BFFF' // 0% 处的颜色
                  }, {
                      offset: 1, color: '#5284DE' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
              }
            }
          }
        }]
      };
      this.chart.setOption(this.option)
    }
  }
};
</script>
<style lang="scss" scoped>
.oxygen {
  margin-right: 10px;
  background: url("../../assets/images/weather/oxygen_bg@2x.png") no-repeat;
  background-size: contain;
  width: 1024px;
  height: 266px;
  position: relative;
  padding: 0 40px;
  margin-top: 20px;
  .title {
    font-size: 16px;
    margin-right: 15px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 32px;
  }
  .content {
    display: flex;
    margin-top: 10px;
    .left, .right {
      .tip {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #A1AFBE;
        line-height: 22px;
        margin-left: 16px;
      }
      .top-title {
        background: linear-gradient(90deg, #2C63B1 0%, transparent 100%);
        border-radius: 4px;
        height: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        white-space: pre;
        color: #D3D7ED;
        padding-left: 20px;
      }
    }
    .left {
      .top-title {
        width: 112px;
        margin-bottom: 23px;
      }
      .info {
        display: flex;
        .process {
          width: 120px;
          height: 120px;
          margin-right: 30px;
          position: relative;
          #processChart {
            position: absolute;
            height: 120px;
            width: 120px;
          }
          .num {
            font-size: 40px;
            font-family: Impact;
            font-weight: 400;
            color: #EBF1F3;
            position: absolute;
            top: 35px;
            left: 40px;
          }
        }
        .column {
          .grade {
            width: 52px;
            max-width: 52px;
            height: 25px;
            line-height: 25px;
            background: #04C27C;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: #FEFEFE;
            font-weight: bold;
            text-align: center;
            margin: 26px 0;
          }
          .oxygen-title, .pm-title, .weather-title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #CFDBE9;
          }
          .oxygen-num, .pm-num, .weather-num {
            font-size: 26px;
            font-family: DIN;
            font-weight: bold;
            color: #C9DCE5;
          }
          .oxygen-title, .weather-title {
            margin-top: 18px;
          }
          .unit {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #9EA9B5;
            margin-left: 8px;
          }
          text-align: left;
        }
      }
      width: 440px;
    }
    .right {
      width: 460px;
      .top-title {
        width: 153px;
      }
      .list {
        display: flex;
        justify-content: space-around;
        margin-top: 36px;
        .item {
          .day {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #CFDBE9;
          }
          .date {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #97A4B4;
            margin: 21px 0 25px 0;
          }
          .grade {
            //width: 52px;
            min-width: 52px;
            height: 25px;
            line-height: 25px;
            background: #04C27C;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FEFEFE;
          }
        }
      }
    }
  }
}
</style>
