<template>
  <div :class="['content', { hide: isChecked }]">
    <div class="grid">
      <div class="grid-item" v-for="(item, idx) in videoList" :key="idx">
        <div class="play-wrap">
          <div class="play-btn"></div>
          <div>{{ idx + 1 }}号节点</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isChecked"],
  data() {
    return {
      videoList: new Array(29),
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 275px;
  padding: 30px 27px 27px;
  background: {
    image: url("../../assets/images/videoBeta/video_sub.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  animation: bounceIn 4s;
  @keyframes bounceIn {
    0% {
      bottom: -275px;
    }
    100% {
      bottom: 0;
    }
  }
  @keyframes bounceOut {
    0% {
      bottom: 0;
    }
    100% {
      bottom: -275px;
    }
  }
  &.hide {
    animation: bounceOut 4s;
    bottom: -275px;
  }
}

.grid {
  display: grid;
  width: 100%;
  height: 215px;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 10px;

  .grid-item {
    border: 1px solid #1c97ff;
    border-radius: 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cfd6e6;
    display: grid;
    align-items: center;
    justify-content: center;

    .play-wrap {
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cfd6e6;
    }

    .play-btn {
      width: 24px;
      height: 23px;
      display: inline-block;
      background: {
        image: url("../../assets/images/videoBeta/play.png");
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }
  }
}
</style>
