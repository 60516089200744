const getDate = (time) => {
  const date = new Date(time).getDay()
  const dateMap = {
    0: '周日',
    1: '周一',
    2: '周二',
    3: '周三',
    4: '周四',
    5: '周五',
    6: '周六',
  }
  return dateMap[date]
}

export default getDate;
