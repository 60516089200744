import axios from "axios";

const REQUEST_SUCCESS = "0",
  REFRESH_BY_HEADER = "pleaseRefreshByHeader";

const baseURL = {
  // production: `/${process.env.VUE_APP_CONTEXT}/`,
  // development: `/api/${process.env.VUE_APP_CONTEXT}/`,
  production: `http://47.110.128.108:8081/jeecg-boot/`,
  development: `/api/jeecg-boot/`,
}[process.env.NODE_ENV];
const http = axios.create({
  timeout: 20000,
  withCredentials: true,
  headers: { "X-Requested-With": "XMLHttpRequest" },
  baseURL: baseURL,
});

// 相应拦截器
http.interceptors.response.use(
  function(response) {
    // 请求多语言的json文件
    if (/.*\.json$/.test(response.config.url)) {
      return response;
    }

    // 根据响应数据判断是否登录过期
    if (response.data.errorCode === REFRESH_BY_HEADER) {
      let refreshUrl = response.headers["refresh-url"].split("?")[0];
      refreshUrl =
        refreshUrl +
        "?service=" +
        location.protocol +
        "//" +
        location.host +
        location.pathname +
        encodeURIComponent(location.search);
      location.href = refreshUrl;
      return;
    }

    // 对错误进行统一处理
    if (response.data.code !== REQUEST_SUCCESS) {
      if (!response.config.noMsg && response.data.msg) {
        alert(response.data.msg);
      }
      return Promise.reject(response);
    } else if (
      response.data.code === REQUEST_SUCCESS &&
      response.config.successNotify
    ) {
      // 弹出成功提示
      alert(response.data.msg || "success !");
    }
    return Promise.resolve({
      code: response.data.code,
      msg: response.data.msg,
      data: response.data.data,
    });
  },
  function(error) {
    if (error.message && error.message.indexOf("timeout") > -1) {
      // 多语言需要自己在项目中配置
      alert("请求超时，请重试！");
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default http;
