<template>
  <div class="trend">
    <div class="title">车辆总概</div>
    <div class="legend legend_left" />
    <div class="legend legend_right" />
    <div class="chart" id="myChart" style="height: 96%"></div>
  </div>
</template>
<script>
export default {
  props: ['refreshNum'],
  data() {
    return {
      chart: null,
      option: {},
      list: [],
    };
  },
  mounted() {
    this.getData()
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    }
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$api.get('/jeecg-boot/carFlow/getVehicleBroken')
      .then((list) => {
        this.list = list;    //执行下面的函数
        this.drawLine();
      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("myChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          legend: {
            data: ['本月', '上月'],
            textStyle: {
                color: '#83A5E1',
                fontSize: 13,
                width: 40
            },
            icon: 'none',
            right: 30,
            top: 25
          },
          grid: {
            left: 10,
            containLabel: true,
          },
          xAxis: {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                color: 'rgba(192, 192, 192, 1)',
                fontWeight: 'bold',
                fontSize: 12,
                padding: [0,0,0,30],
                width: 20,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
              splitNumber: 5,
              data: this.list.map(item => item.mouth)
          },
          yAxis: {
              type: 'value',
              name: '单位(辆)',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 13,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              min: 0,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLine: {
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(192, 192, 192, 1)',
                fontSize: 12,
                fontWeight: 'bold',
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.list.map(item => item.mouths),
              type: 'line',
              name: '本月',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(94, 214, 255, 1)'
              },
              areaStyle: {
                color: 'rgba(94, 214, 255, 0.5)'
              },
              animationDelay: 2 * 1000
          },{
              data: this.list.map(item => item.lastMouths),
              type: 'line',
              smooth: true,
              name: '上月',
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(0, 148, 255, 1)'
              },
              areaStyle: {
                color: 'rgba(0, 148, 255, 0.5)'
              },
              animationDelay: 2 * 1000
          }]
      }
      this.chart.setOption(this.option)
    }
  }
};
</script>
<style lang="scss" scoped>

.trend {
  margin-right: 10px;
  background: url("../../assets/images/park/trend_bg@2x.png") no-repeat;
  background-size: contain;
  width: 410px;
  padding-top: 9px;
  height: 310px;
  padding-left: 10px;
  position: relative;
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 14px;
    height: 2px;
    border-radius: 1px;
  }
  .chart {
    width: 100%;
  }
  .legend_left {
    right: 135px;
    top: 67px;
    background: rgba(0, 148, 255, 1)
  }
  .legend_right {
    right: 70px;
    top: 67px;
    background: rgba(32, 184, 237, 1);
  }
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    font-family: 'MicrosoftYaHei-Bold';
  }
}




</style>
