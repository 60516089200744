<template>
  <div class="content">
    <div class="card-title">车辆流量</div>
    <div class="lukou lukou-one">
      <div class="car-icon"></div>
      <div>
        <div class="car-lukou">1号路口</div>
        <div class="car-count">300<span>辆</span></div>
      </div>
      <div class="car-status">畅通</div>
    </div>
    <div class="lukou lukou-two">
      <div class="car-icon"></div>
      <div>
        <div class="car-lukou">2号路口</div>
        <div class="car-count">1200<span>辆</span></div>
      </div>
      <div class="car-status">畅通</div>
    </div>
    <div class="lukou lukou-three">
      <div class="car-icon"></div>
      <div>
        <div class="car-lukou">3号路口</div>
        <div class="car-count">300<span>辆</span></div>
      </div>
      <div class="car-status">畅通</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 334px;
  padding: 30px 43px 0 32px;
  background: {
    image: url("../../assets/images/carMonitor/liu_liang_bg.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }
}

.lukou {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  & + .lukou {
    border-top: 1px solid;
    border-color: rgba($color: #fff, $alpha: 0.33);
  }

  .car-icon {
    width: 53px;
    height: 45px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 10px;
  }

  .car-lukou {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    width: 63px;
    height: 23px;
    line-height: 23px;
    background: rgba($color: #fff, $alpha: 0.17);
  }

  .car-count {
    font-size: 26px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    > span {
      font-size: 16px;
      margin-left: 5px;
    }
  }
  .car-status {
    flex: 1;
    text-align: right;
    font-size: 26px;
  }
}

.lukou-one {
  .car-icon {
    background-image: url("../../assets/images/home/che.png");
  }
  .car-status {
    color: #12da7e;
  }
}

.lukou-two {
  .car-icon {
    background-image: url("../../assets/images/home/che3.png");
  }
  .car-status {
    color: #da115d;
  }
}

.lukou-three {
  .car-icon {
    background-image: url("../../assets/images/home/che2.png");
  }
  .car-status {
    color: #8c4405;
  }
}
</style>
