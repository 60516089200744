import http from "../httpInstance";

// 获取主页展示的监控点信息
export const queryMainCamera = (params) => {
  return http({
    url: "device/queryMainCamera",
    method: "get",
    params,
  });
};
