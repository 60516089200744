<template>
  <div class="container">
    <head-top :isChecked="isChecked" title="芦茨天眼通" @changeChecked="handleChecked"/>

   <!-- <div :class="['left', { hide: isChecked }]">
      <vehicleTrafficLeft></vehicleTrafficLeft>
      <trafficStatistics></trafficStatistics>
      <trafficJam></trafficJam>
    </div>-->
    <div :class="['bottom', { hide: isChecked }]">
      <div class="back" @click="back">返回</div>
      <div class="t1">芦茨村路口监控</div>
      <villageMonitoring :type="type"></villageMonitoring>
    </div>
  <!--  <div :class="['right', { hide: isChecked }]">
      <vehicleTrafficRight></vehicleTrafficRight>
      <equipmentSituation></equipmentSituation>
    </div>-->
  </div>
</template>

<script>
import moment from 'moment';
import headTop from '@/components/park/menu';
import vehicleTrafficLeft from '@/components/monitor/vehicleTrafficLeft';
import trafficStatistics from '@/components/monitor/trafficStatistics';
import trafficJam from '@/components/monitor/trafficJam';
import villageMonitoring from '@/components/monitor/villageMonitoring';
import vehicleTrafficRight from '@/components/monitor/vehicleTrafficRight';
import equipmentSituation from '@/components/monitor/equipmentSituation';

export default {
  components: {
    headTop,
    vehicleTrafficLeft,
    trafficStatistics,
    trafficJam,
    villageMonitoring,
    vehicleTrafficRight,
    equipmentSituation
  },
  data() {
    return {
      type: 0,
      refreshNum: 0,
      time: moment().format('YYYY-MM-DD HH:mm:ss'),
      isChecked: false
    };
  },
  beforeRouteLeave(to, form, next) {
    this.type = 1
    this.$nextTick(function() {
      next();
    })

  },
  mounted() {
    this.type = 0
  },
  methods: {
    getData() {
    },
    handleChecked() {
      this.isChecked = !this.isChecked;
    },
    back() {
      this.$router.back(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.t1 {
  text-align: center;
  font-size: 20px;
  position: absolute;
  left: 0;
  letter-spacing: 2px;
  font-weight: bold;
  top: 16px;
  font-family: Microsoft YaHei;
  color: #ffffff;
  text-shadow: 0px 1px 1px rgb(0 0 0 / 31%);
  padding-top: 5px;
  width: 100%;
}

.container {
  width: 1920px;
  height: 100%;
  background: #011326;
  margin: 0 auto;
  padding: 0;
  position: relative;
  /*background: url("../assets/images/park/bg.png") no-repeat;*/
  background-size: contain;

  .bgImg {
    background-size: contain;
  }

  .mark {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 9, 18, 0.5);
  }
}

.back {
  background: url('../assets/images/park/back@2x.png') no-repeat;
  position: absolute;
  left: 13px;
  top: 20px;
  z-index: 10000;
  width: 75px;
  height: 45px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  transform: none;
  color: #ffffff;
  line-height: 37px;
  letter-spacing: 2px;
  background-size: contain;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.left {
  top: 54px;
  left: 18px;
  @keyframes bounceLeftIn {
    0% {
      left: -500px;
    }
    100% {
      left: 18px;
    }
  }
  @keyframes bounceLeftOut {
    0% {
      left: 18px;
    }
    100% {
      left: -500px;
    }
  }

  &.hide {
    animation: bounceLeftOut 1s;
    left: -500px;
  }

  position: absolute;
  z-index: 999;
  animation: bounceLeftIn 1s;
}

.right {
  position: absolute;
  right: 18px;
  top: 54px;
  z-index: 9999;
  @keyframes bounceRightIn {
    0% {
      right: -400px;
    }
    100% {
      right: 18px;
    }
  }
  @keyframes bounceRightOut {
    0% {
      right: 18px;
    }
    100% {
      right: -400px;
    }
  }

  &.hide {
    animation: bounceRightOut 1s;
    right: -400px;
  }

  animation: bounceRightIn 1s;
}

.bottom {
  position: absolute;
  top: 70px;
  left: 20px;
  display: flex;
  z-index: 9998;
  @keyframes bounceBottomIn {
    0% {
      bottom: -1000px;
    }
    100% {
      bottom: 15px;
    }
  }
  @keyframes bounceBottomOut {
    0% {
      bottom: 15px;
    }
    100% {
      bottom: -1000px;
    }
  }

  &.hide {
    animation: bounceBottomOut 1s;
    bottom: -1000px;
  }

  animation: bounceBottomIn 1s;
}
</style>
