<template>
  <div :class="['center_main', { hide: isChecked }]">
    <VideoPlay :isChecked="isChecked" />
    <VideoSub :isChecked="isChecked" />
  </div>
</template>

<script>
import VideoPlay from "./video-play";
import VideoSub from "./video-sub";

export default {
  props: ["isChecked"],
  components: {
    VideoPlay,
    VideoSub,
  },
};
</script>

<style lang="scss" scoped>
.center_main {
  width: 1036px;
  position: relative;
}
</style>
