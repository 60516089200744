<template>
  <div :class="['content', { hide: isChecked }]">
    <div class="header">
      <div class="back">
        <router-link to="/">返 回</router-link>
      </div>
      <div class="title">芦茨村路口监控</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isChecked"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 667px;
  padding: 35px 25px 15px;
  background: {
    image: url("../../assets/images/videoBeta/video.png");
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  animation: bounceIn 4s;
  @keyframes bounceIn {
    0% {
      top: -750px;
    }
    100% {
      top: 0;
    }
  }
  @keyframes bounceOut {
    0% {
      top: 0;
    }
    100% {
      top: -750px;
    }
  }
  &.hide {
    animation: bounceOut 4s;
    top: -750px;
  }

  .header {
    text-align: center;
    position: relative;

    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff
    }
  }

  .back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 85px;
    height: 45px;
    line-height: 40px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    a {
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      text-decoration: none;
    }
    background: {
      image: url("../../assets/images/videoBeta/back.png");
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  }
}
</style>
