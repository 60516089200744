const getMapOption = (chinaMap,chinaColor) => ({
	geo: {
		map: "china",
		itemStyle: {
			borderType: "dashed",
			borderColor: "transparent",
			areaColor: "transparent"
		}
	},
	series: [
		{
			type: "map",
			mapType: "china", // 自定义扩展图表类型
			backgroundColor: 'transparent',
			itemStyle: {
				borderColor: "#0c6091",
				areaColor: "#022350",
				shadowColor: '#000',
				borderWidth: 2,
				shadowBlur: 10
			},
			emphasis: {
				itemStyle: {
					borderColor: "transparent",
					areaColor: "transparent",
				}
			}
		},
		{
			name: "射线",
			type: "lines",
			zlevel: 3,
			coordinateSystem: "geo",
			effect: {
				// show: false,
				show: true,
				period: 3,
				delay: 0,
				constantSpeed: 0,
				trailLength: 0.6,
				symbol: "circle", // 标记类型
				symbolSize: 2
			},
			lineStyle: {
				color: {
					type: "linear",
					x: 0,
					y: 0,
					x2: 0,
					y2: 1,
					// colorStops: [{
					//    offset: 0, color: "rgba(0, 255, 247, 0)" // 100% 处的颜色
					// }, {
					//    offset: 1, color: "rgba(0, 200, 255, 0.8)" // 0% 处的颜色
					// }]
					colorStops: [{
						offset: 0, color: "rgba(255, 165, 0, 1)" // 100% 处的颜色
					}, {
						offset: 1, color: "rgba(255, 165, 0, 1)" // 0% 处的颜色
					}]
				},
				// color: "transparent",
				// shadowColor: "rgba(0, 255, 247, 0.8)",
				// shadowOffsetX: 1,
				// shadowBlur: 1,
				width: 0,
				curveness: 0.2
			},
			data: chinaMap
			// [[
			// 	{ name: "山西省", coord: [112.549248, 37.857014] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "青海省", coord: [101.778916, 36.623178] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "贵州省", coord: [106.713478, 26.578343] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "广东省", coord: [113.280637, 23.125178] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "西藏", coord: [91.132212, 29.660361] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "四川省", coord: [104.065735, 30.659462] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "福建省", coord: [119.306239, 26.075302] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "新疆", coord: [87.617733, 43.792818] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ], [
			// 	{ name: "内蒙古", coord: [111.670801, 40.818311] },
			// 	{ name: "浙江省", coord: [120.153576, 30.287459] }
			// ]]
		},
		// ...[
		// 	{ color: 'rgba(45, 140, 248, 0.5)', value: [120.153576, 30.287459] },
		// 	{ color: 'rgba(210, 165, 71, 0.5)', value: [111.670801, 40.818311] },
		// 	{ color: 'rgba(217, 7, 200, 0.5)', value: [87.617733, 43.792818] },
		// 	{ color: 'rgba(66, 210, 212, 0.5)', value: [119.306239, 26.075302] },
		// 	{ color: 'rgba(0, 154, 218, 0.5)', value: [104.065735, 30.659462] },
		// 	{ color: 'rgba(0, 154, 218, 0.5)', value: [91.132212, 29.660361] },
		// 	{ color: 'rgba(66, 210, 212, 0.5)', value: [113.280637, 23.125178] },
		// 	{ color: 'rgba(217, 7, 200, 0.5)', value: [112.549248, 37.857014] },
		// 	{ color: 'rgba(217, 7, 200, 0.5)', value: [106.713478, 26.578343] },
		// 	{ color: 'rgba(210, 165, 71, 0.5)', value: [101.778916, 36.623178] },
		// 	{ color: 'rgba(66, 210, 212, 0.5)', value: [112.549248, 37.857014] }
		// ].map(({ color, value }, index) => ({
		// 	type: "effectScatter",
		// 	coordinateSystem: "geo",
		// 	zlevel: 2,
		// 	// 波纹效果
		// 	rippleEffect: {
		// 		period: 8,
		// 		brushType: "fill",
		// 		color,
		// 		scale: 8
		// 	},
		// 	label: {
		// 		show: false
		// 	},
		// 	// 终点形象
		// 	symbol: "circle",
		// 	// 圆点大小
		// 	symbolSize: index === 0 ? 7 : 3,
		// 	itemStyle: {
		// 		opacity: 0.4,
		// 		normal: {
		// 			show: true
		// 		}
		// 	},
		// 	data: [{ value }]
		// }))
		...chinaColor.map(({ color, value }, index) => ({
			type: "effectScatter",
			coordinateSystem: "geo",
			zlevel: 2,
			// 波纹效果
			rippleEffect: {
				period: 8,
				brushType: "fill",
				color,
				scale: 8
			},
			label: {
				show: false
			},
			// 终点形象
			symbol: "circle",
			// 圆点大小
			symbolSize: index === 0 ? 7 : 3,
			itemStyle: {
				opacity: 0.4,
				normal: {
					show: true
				}
			},
			data: [{ value }]
		}))
	]
});

export default getMapOption;
