<template>
  <div class="earnings bgImg">
    <div class="title">民宿收益</div>
    <div class="today_info">
      <div class="today_in item">
        <div class="title">昨日收益</div>
        <div class="num" style="color:rgba(50, 243, 250, 1)">
          <countTo ref="enum" :startVal='0' :endVal='21' :duration='4000' style="min-width: 50px"></countTo>
          <span class="unit" style="color:rgba(50, 243, 250, 1)">万元</span>
        </div>
      </div>
      <div class="today_remain item">
        <div class="title">本月收益</div>
        <div class="num">
          <countTo ref="enumOne" :startVal='0' :endVal='625' :duration='4000' style="min-width: 80px"></countTo>
        <span class="unit">万元</span></div>
      </div>
      <div class="remain_rate item">
        <div class="title">本年收益</div>
        <div class="num">
          <countTo ref="enumTwo" :startVal='0' :endVal='7501' :duration='4000' style="min-width: 80px"></countTo>
          <span class="unit">万元</span>
        </div>
      </div>
    </div>
    <div id="monthChart" style="height: 220px;margin-top: 20px;"/>
    <div class="legend month_legend_left" />
    <div class="legend month_legend_right" />
  </div>
</template>
<script>
import countTo from 'vue-count-to';
import toThousands from '../../utils/toThousands'

export default {
  data() {
    return {
      chart: null,
      option: {},
      todayEarn: ((new Date().getHours() + 1) * 5 + 44) * 200,
      monthEarn: ((new Date().getHours() + 1) * 5 + new Date().getDate() * 152) * 200,
      yearEarn: ((new Date().getHours() + 1) * 5 + new Date().getDate() * 152 + 178) * 200,

      lastyearEarn: 10,
      // monthEarn: 10,
      // todayEarn: 10,
      // yearEarn: 10,
      lastyearList: [],
      yearList: [],
      dateMap: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dece: '12月',
      }
    }
  },
  props: ['isChecked', 'refreshNum'],
  mounted() {
    this.getData()
  },
  components: {
    countTo
  },
  watch: {
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) {
        this.restart();
        this.getData();
      }
    },
  },
  methods: {
    restart() {
      this.$refs.enum && this.$refs.enum.start()
      this.$refs.enumOne && this.$refs.enumOne.start()
      this.$refs.enumTwo && this.$refs.enumTwo.start()
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    getData() {
      this.$trueApi.post('/LuciHomestay/income')
      .then(({ data }) => {
        const { incomeToday, incomeYear, incomeMonth, lastYear, thisYear } = data
        this.monthEarn = incomeMonth / 10000
        this.todayEarn = incomeToday / 10000
        this.yearEarn = incomeYear / 10000
        this.lastyearList = (lastYear || []).map(item => item.income)
        this.yearList = (thisYear || []).map(item => item.income)
        this.drawLine();
      })
    },
    formatNum(num) {
      return toThousands(num)
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("monthChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          legend: {
            data: ['今年', '去年'],
            textStyle: {
                color: '#83A5E1',
                fontSize: 13,
                width: 40
            },
            right: 35,
            top: 15,
            icon: 'none',
          },
          grid: {
            top: 50,
            bottom: 20,
          },
          xAxis: {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontWeight: 'bold',
                fontSize: 12,
                padding: [0,0,0,30],
                width: 20,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
              splitNumber: 5,
              data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
          },
          yAxis: {
              type: 'value',
              name: '单位/万元',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 13,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontWeight: 'bold',
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.yearList,
              type: 'line',
              name: '今年',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(50, 183, 233, 1)'
              },
              areaStyle: {
                color: 'rgba(50, 183, 233, 0.2)'
              },
              animationDelay: 2 * 1000
          },{
              data: this.lastyearList,
              type: 'line',
              smooth: true,
              name: '去年',
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(255, 241, 0, 1)'
              },
              areaStyle: {
                color: 'rgba(255, 241, 0, 0.2)'
              },
              animationDelay: 2 * 1000
          }]
      }
      this.chart.setOption(this.option)
    }
  }
};
</script>
<style lang="scss" scoped>

.earnings {
  background: url("../../assets/images/homestay/earnings_bg@2x.png") no-repeat;
  width: 409px;
  height: 384px;
  margin-top: 14px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    //font-weight: normal;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 32px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
  }
  .today_info {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0 40px;
    text-align: left;
    .item {
      min-width: 90px;
      .title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #CFDBE9;
      }
      .num {
        font-size: 26px;
        font-family: Impact;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 10px;
        display: flex;
        align-items: baseline;
        .unit {
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          margin-left: 3px;
        }
        text-align: left;
      }
    }
  }
  .legend {
    position: absolute;
    width: 9px;
    height: 1px;
    border-radius: 1px;
  }
  .month_legend_left {
    position: absolute;
    right: 145px;
    top: 166px;
    background: rgba(50, 183, 233, 1)
  }
  .month_legend_right {
    position: absolute;
    right: 80px;
    top: 166px;
    background: rgba(255, 241, 0, 1)
  }
}




</style>
