<template>
  <div :class="['left_main', { hide: isChecked }]">
    <CarFlow :refreshNum='refreshNum'/>
    <FlowStatistics :refreshNum='refreshNum'/>
    <BusyRoad :refreshNum='refreshNum'/>
  </div>
</template>

<script>
import CarFlow from "./car-flow";
import FlowStatistics from "./flow-statistics";
import BusyRoad from "./busy-road";

export default {
  props: ["isChecked", 'refreshNum'],
  components: {
    CarFlow,
    FlowStatistics,
    BusyRoad,
  },
};
</script>

<style lang="scss" scoped>
.left_main {
  width: 408px;
  position: relative;
  animation: bounceIn 4s;
  @keyframes bounceIn {
    0% {
      left: -500px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes bounceOut {
    0% {
      left: 0;
    }
    100% {
      left: -500px;
    }
  }
  &.hide {
    animation: bounceOut 4s;
    left: -500px;
  }
}
</style>
