<template>
  <div class="content">
    <div class="card-title">公共治理</div>
    <div class="grid">
      <div class="flex-column border-bottom">
        <img src="../../assets/images/home/jmzs.png" />
        <div class="title">居民总数 (人)</div>
        <div class="num" style="color: #04e9fe">
          {{ mData.people }}
        </div>
      </div>
      <div class="flex-column border-bottom">
        <img src="../../assets/images/home/dbh.png" />
        <div class="title">低保户 (人)</div>
        <div class="num" style="color: #00f38d">{{ mData.lowPeople }}</div>
      </div>
      <div class="flex-column border-bottom">
        <img src="../../assets/images/home/zyz.png" />
        <div class="title">志愿者之家 (人)</div>
        <div class="num" style="color: #aa6dff">{{ mData.volumteer }}</div>
      </div>
      <div class="flex-column">
        <img src="../../assets/images/home/dyzj.png" />
        <div class="title">党员之家 (人)</div>
        <div class="num" style="color: #ffe743">{{ mData.cpm }}</div>
      </div>
      <div class="flex-column">
        <img src="../../assets/images/home/ljcl.png" />
        <div class="title">今日垃圾处理 (kg)</div>
        <div class="num" style="color: #ff5656">{{ mData.rubbish }}</div>
      </div>
      <div class="flex-column">
        <img src="../../assets/images/home/ljtf.png" />
        <div class="title">垃圾投放合格率 (%)</div>
        <div class="num" style="color: #b7ff09">{{ mData.rubbishRate }}</div>
      </div>
    </div>
    <div class="glory-wrap">
      <div class="glory-txt-wrap">
        <span class="glory-txt">光荣榜</span>
      </div>
      <div class="category-table">
        <div class="table-header">
          <div>月度排行</div>
          <div>姓名</div>
          <div>属性1</div>
          <div>属性2</div>
        </div>
        <div class="table-row" v-for="(item, idx) in (mData.honorList || [])" :key="item.id">
          <div class="rank-cell">
            <span
              :class="{ rank1: idx === 0, rank2: idx === 1, rank3: idx === 2 }"
            ></span>
          </div>
          <div>{{ item.name }}</div>
          <div>{{ item.score }}</div>
          <div>属性2</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['mData']
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 396px;
  padding: 44px 20px 20px 20px;
  background: {
    image: url("../../assets/images/home/bg_ggzl.png");
    repeat: no-repeat;
    size: cover;
    position: bottom;
  }
}
.rank-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rank1 {
  background-image: url("../../assets/images/home/num1.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 22px;
  background-size: cover;
}

.rank2 {
  background-image: url("../../assets/images/home/num2.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 22px;
  background-size: cover;
}

.rank3 {
  background-image: url("../../assets/images/home/num3.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 22px;
  background-size: cover;
}
.grid {
  display: grid;
  width: 100%;
  height: 190px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .flex-column {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8d0f5;
    > div {
      margin-top: 4px;
    }
  }
  .title {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  .num {
    font-weight: bold;
    font-size: 16px;
  }
  .flex-column img {
    width: 36px;
  }
}

.category-table {
  flex: 1;
  color: #b8d0f5;
  border: 1px solid #1e4586;

  > div {
    border-bottom: 1px solid #1e4586;
    &:last-child {
      border-bottom: none;
    }
  }

  .table-header {
    display: flex;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2b88e7;
    height: 30px;
    line-height: 30px;
    div {
      flex: 1;
    }
  }
  .table-row {
    display: flex;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    > div {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }

  .no {
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #1c4997;
    border-radius: 50%;
  }
}

.glory-wrap {
  display: flex;
  margin-top: 16px;
}

.glory-txt-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  background: rgba(11, 87, 172, 0.6);
  border-radius: 5px 0 0 5px;
}

.glory-txt {
  width: 15px;
  font-size: 15px;
  font-weight: bold;
  color: #d4e7ff;
}
</style>