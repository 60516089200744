<template>
  <div class="people-num">
    <div class="title">芦茨之家</div>
    <div id="homeChart" style="height: 280px"></div>
    <div class="legend legend_left" />
    <div class="legend legend_right" />
  </div>
</template>
<script>

export default {
  data() {
    return {
      chart: null,
      zhiyuanList: [],
      dangyuanList: [],
      option: {},
      dateMap: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dece: '12月',
      }
    }
  },
  props: ['numInfo', 'isChecked', 'refreshNum'],
  mounted() {
    this.getData()
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/village/luciHome')
      .then(({ result }) => {
        this.zhiyuanList = result[1] || {}
        this.dangyuanList = result[0] || {}
        this.zhiyuanList = Object.keys(this.zhiyuanList)
          .filter((item, index) => this.dateMap[item])
          .map((item, index) => [this.dateMap[item], this.zhiyuanList[item]])
        this.dangyuanList = Object.keys(this.dangyuanList)
          .filter((item, index) => this.dateMap[item])
          .map((item, index) => [this.dateMap[item], this.dangyuanList[item]])
        this.drawLine();
      })
    },
    restart() {
      if (this.isChecked) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("homeChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          legend: {
            data: ['党员之家', '志愿之家'],
            textStyle: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 10,
                width: 40
            },
            icon: 'none',
            right: 30,
            top: 25
          },
          xAxis: {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.3)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                padding: [0,0,0,30],
                width: 20,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
              splitNumber: 5,
          },
          yAxis: {
              type: 'value',
              name: '单位/个',
              nameTextStyle: {
                padding: [0, 0, 0, 40],
                fontSize: 10,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
                width: 50
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.5)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.zhiyuanList,
              type: 'line',
              name: '志愿之家',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(0, 88, 196, 1)'
              },
              areaStyle: {
                // color: 'rgba(0, 88, 196, 0.1)'
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(0, 88, 196, 0.4)' // 0% 处的颜色
                    }, {
                        offset: 0.5, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              },
              animationDelay: 2000
          },{
              data: this.dangyuanList,
              type: 'line',
              smooth: true,
              name: '党员之家',
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(226, 63, 160, 1)'
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(226, 63, 160, 0.4)' // 0% 处的颜色
                    }, {
                        offset: 0.5, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
                // color: 'rgba(226, 63, 160, 0.1)'
              },
              animationDelay: 2000
          }]
      };
      this.chart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.people-num {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #FFFFFF;
    line-height: 32px;
  }
  background: url("../../assets/images/housekeeper/home_bg@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 357px;
  margin-top: 12px;
  .total {
     margin-top: 29px;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #CFDBE9;
    }
    .unit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8995A4;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      margin: 0 8px;
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
  }
  .legend_left {
    right: 90px;
    top: 68px;
    background: rgba(0, 88, 196, 1)
  }
  .legend_right {
    right: 172px;
    top: 68px;
    background: rgba(226, 63, 160, 1)
  }
}


</style>
