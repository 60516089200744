<template>
  <div class="content">
    <div class="card-title">车辆流量</div>
    <div>
      <div class="lukou lukou-one">
        <div class="car-icon"></div>
        <div>
          <div class="car-lukou">1号路口</div>
          <div class="car-count">300<span>辆</span></div>
        </div>
        <div class="car-status">畅通</div>
      </div>
      <div class="lukou lukou-two">
        <div class="car-icon"></div>
        <div>
          <div class="car-lukou">2号路口</div>
          <div class="car-count">1200<span>辆</span></div>
        </div>
        <div class="car-status">畅通</div>
      </div>
      <div class="lukou lukou-three">
        <div class="car-icon"></div>
        <div>
          <div class="car-lukou">3号路口</div>
          <div class="car-count">300<span>辆</span></div>
        </div>
        <div class="car-status">畅通</div>
      </div>
      <div class="lukou lukou-three">
        <div class="car-icon"></div>
        <div>
          <div class="car-lukou">3号路口</div>
          <div class="car-count">300<span>辆</span></div>
        </div>
        <div class="car-status">畅通</div>
      </div>
      <div class="lukou lukou-three">
        <div class="car-icon"></div>
        <div>
          <div class="car-lukou">3号路口</div>
          <div class="car-count">300<span>辆</span></div>
        </div>
        <div class="car-status">畅通</div>
      </div>
    </div>
    <div class="monitor">
      <div class="monitor-item" v-for="(item, idx) in monitorList" :key="idx">
        <div class="m-icon"></div>
        <div class="m-name">1号路口监控</div>
        <div class="m-status">正常</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      monitorList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  width: 409px;
  height: 971px;
  padding: 60px 32px 0;
  background: {
    image: url("../../assets/images/home/car_flow.png");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}
.card-title {
  top: 5px;
}
.lukou {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  & + .lukou {
    padding-top: 20px;
    border-top: 1px solid;
    border-color: rgba($color: #fff, $alpha: 0.33);
  }

  .car-icon {
    width: 53px;
    height: 45px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 10px;
  }

  .car-lukou {
    font-size: 16px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    width: 63px;
    height: 23px;
    background: rgba($color: #fff, $alpha: 0.17);
  }

  .car-count {
    font-size: 26px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    > span {
      font-size: 16px;
      margin-left: 5px;
    }
  }
  .car-status {
    flex: 1;
    text-align: right;
    font-size: 26px;
  }
}

.lukou-one {
  .car-icon {
    background-image: url("../../assets/images/home/che.png");
  }
  .car-status {
    color: #12da7e;
  }
}

.lukou-two {
  .car-icon {
    background-image: url("../../assets/images/home/che3.png");
  }
  .car-status {
    color: #da115d;
  }
}

.lukou-three {
  .car-icon {
    background-image: url("../../assets/images/home/che2.png");
  }
  .car-status {
    color: #8c4405;
  }
}

.monitor {
  margin-top: 50px;
}

.monitor-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .m-icon {
    width: 16px;
    height: 16px;
    background: #11da7e;
    border-radius: 50%;
    margin-right: 34px;
  }

  .m-name {
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    flex: 1;
    text-align: left;
  }

  .m-status {
    font-size: 26px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #12da7e;
  }
}
</style>
