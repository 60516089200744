<template>
  <div class="wind">
    <div class="title">风力趋势图</div>
    <div id="windChart" style="height: 270px"></div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      chart: null,
      option: {}
    }
  },
  props: ['isChecked', 'refreshNum', 'windList'],
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
    windList: function () {
      this.drawLine();
    }
  },
  methods: {
    restart() {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("windChart"));
      this.option = {
          tooltip: {
            show: true,
            triggerOn: 'mousemove|click'
          },
          xAxis: {
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.5)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'MicrosoftYaHei',
              },
              type: 'category',
          },
          yAxis: {
              type: 'value',
              nameTextStyle: {
                fontSize: 10,
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                color: 'rgba(131, 165, 225, 1)',
              },
              min: 0,
              max: 40,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(56, 89, 130, 0.5)'
                }
              },
              axisLabel: {
                color: 'rgba(131, 165, 225, 1)',
                fontSize: 12,
                fontFamily: 'ArialMT',
              }
          },
          series: [{
              data: this.windList,
              type: 'line',
              smooth: true,
              symbol:'circle',
              symbolSize: 10,
              itemStyle: {
                color: 'transparent'
              },
              lineStyle: {
                color: 'rgba(252, 65, 168, 1)'
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(252, 65, 168, 0.6)' // 0% 处的颜色
                    }, {
                        offset: 0.7, color: 'transparent' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              },
              // animationDelay: 2000
          }]
      };
      this.chart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.wind {
  padding: 0 10px;
  position: relative;
  .title {
    font-size: 16px;
    font-family: 'Adobe Heiti Std';
    //font-weight: normal;
    font-weight: bold;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    text-align: center;
    color: #fff;
    line-height: 32px;
  }
  background: url("../../assets/images/weather/wind_bg@2x.png") no-repeat;
  background-size: contain;
  width: 409px;
  height: 315px;
  margin-top: 14px;
  .total {
     margin-top: 29px;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #CFDBE9;
    }
    .unit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8995A4;
    }
    .num {
      font-size: 26px;
      font-family: Impact;
      font-weight: 400;
      color: #CFDBE9;
      margin: 0 8px;
    }
  }
  .legend {
    position: absolute;
    right: 255px;
    top: 30px;
    width: 9px;
    height: 1px;
  }
  .legend_left {
    right: 130px;
    top: 68px;
    background: rgba(232, 229, 12, 1)
  }
  .legend_right {
    right: 72px;
    top: 68px;
    background: rgba(47, 159, 193, 1)
  }
}


</style>
