<template>
  <div :class="['monitor', { 'hide': isChecked }]">
    <div class="title">停车场监控</div>
    <div style="display: flex">
      <div class="left">
        <div class="parks">
          <div
          :class="[
            'park_name',
            { 'park_name_active': activeKey === idx },
            { 'park_name_one': idx === 0 && activeKey !== 0  },
            { 'park_name_one_active': idx === 0 && activeKey === 0 },
            { 'park_name_two': idx === 1 && activeKey !== 1 },
            { 'park_name_two_active': idx === 1 && activeKey === 1 },
            { 'park_name_three': idx === 2 }
           ]"
          v-for="(item, idx) in monitorInfo.parks"
          :key="idx"
          v-on:click="activeKey = idx !== 2 ? idx : activeKey"
          >
          {{ item.name }}
        </div>
      </div>
      <div class="park">
        <div class="types">
          <div class="item">
            <div class="name">车辆总数</div>
            <div class="num" style="color: rgba(193, 252, 5, 1)">{{ parkInfo.sumCars }}<span class="unit">辆</span></div>
            <div class="process"><div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background':'linear-gradient(90deg, rgba(193, 252, 5, 0) 0%, rgba(193, 252, 5, 1)', 'width': `${parkInfo.saturationRate}` }"/></div>
          </div>
          <div class="item">
            <div class="name">饱和率</div>
            <div class="num" style="color: rgba(193, 252, 5, 1)">{{String(parkInfo.saturationRate).replace('%', '')}}<span class="unit">%</span></div>
            <div class="process"><div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background':'linear-gradient(90deg, rgba(193, 252, 5, 0) 0%, rgba(193, 252, 5, 1) 100%)', 'width': `${parkInfo.saturationRate}` }"/></div>
          </div>
          <div class="item">
            <div class="name">空闲车位</div>
            <div class="num" style="color: rgba(50, 243, 250, 1)">{{parkInfo.remainSpace}}<span class="unit">辆</span></div>
            <div class="process"><div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background':'linear-gradient(90deg, rgba(47, 224, 233, 0) 0%, #32F3FA 100%)', 'width': `${parkInfo.idleRate}` }"/></div>
          </div>
          <div class="item">
            <div class="name">空闲率</div>
            <div class="num" style="color: rgba(50, 243, 250, 1)">{{String(parkInfo.idleRate).replace('%', '')}}<span class="unit">%</span></div>
            <div class="process"><div :class="['process_bar', { 'process_bar_active': active }]" :style="{'background':'linear-gradient(90deg, rgba(47, 224, 233, 0) 0%, #32F3FA 100%)', 'width': `${parkInfo.idleRate}` }"/></div>
          </div>
        </div>
        <div class="park_last">
          <div class="average_duration">
            <div class="num">{{ parkInfo.minutes }}</div>
            <div class="unit">时</div>
          </div>
          <div class="park_last_title">平均停车时长</div>
        </div>
      </div>
    </div>
      <div class="right">
        <div class="legend legend_left" />
        <div class="legend legend_right" />
        <div id="monitorChart" style="margin-top: 20px; height: 80%"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeKey: 0,
      active: false,
      chart: null,
      option: {},
      park1: {
        minutes: 0,
        remainSpace: 9999,
        saturationRate: '',
        sumCars: 1,
        idleRate: 0,
        list: [],
      },
      park2: {
        minutes: 0,
        remainSpace: 9999,
        saturationRate: '',
        sumCars: 1,
        idleRate: 0,
        list: [],
      },
      addNum: 0
    }
  },
  props: ['isChecked', 'monitorInfo', 'refreshNum'],
  computed: {
    parkInfo: function() {
      if (this.activeKey === 0) {
        return this.park1
      }
      if (this.activeKey === 1) {
        return this.park2
      }
    },
  },
  mounted() {
    this.getData();
    setInterval(() => {
      setTimeout(() => {
        this.getAddNum();
      }, 0)
    }, 10000)
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    isChecked: function () {
      this.restart();
    },
    refreshNum: function () {
      if (this.refreshNum > 0) this.restart();
    },
  },
  methods: {
    getData() {
      this.$api.get('/jeecg-boot/carFlow/parkingLotMonitoring')
      .then((info) => {

        this.park1 = info[0]
        this.park2 = info[1]
        this.drawLine()
      })
    },
    getAddNum() {
      this.addNum = this.addNum + Math.ceil(Math.random()*20 - 10)
    },
    restart() {
      this.active = true
      setTimeout(() => {
        this.active = false
      }, 6000)
      if(this.chart) {
        this.chart.clear()
        this.chart.setOption(this.option, true)
      }
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("monitorChart"));
      this.option = {
          tooltip: {
            show: true,
          },
          legend: {
            data: ['芦茨村停车场', '时光码头停车场'],
            textStyle: {
                fontSize: 13,
                fontWeight: 400,
                color: '#637699',
            },
            icon: 'none',
            right: 30
          },
          grid: {
            bottom: 20,
          },
          xAxis: {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#375D9E',
                  width: 1
                }
              },
              axisLabel: {
                color: '#83A5E1',
                fontFamily: 'MicrosoftYaHei',
                fontWeight: 'bold'
              },
              type: 'category',
              data:  this.park1.list.map(item => item.time),
          },
          yAxis: {
              type: 'value',
              min: 0,
              name: '单位(辆)',
              nameTextStyle: {
                  color: '#83A5E1',
                  fontSize: 13,
                  fontFamily: 'MicrosoftYaHei',
                  padding: [0, 0, 0, 60],
                  fontWeight: 400
              },
              splitLine: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#375D9E',
                  width: 1
                }
              },
              axisLabel: {
                show: true,
                textStyle: {
                fontFamily: 'Microsoft YaHei',
                fontWeight: 'bold',
                color: '#83A5E1'
                }
              }
          },
          series: [{
              data: this.park1.list.map(item => item.cars),
              type: 'line',
              name: '芦茨村停车场',
              smooth: true,
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(255, 241, 0, 1)'
              },
              areaStyle: {
                color: 'rgba(255, 241, 0, 0.3)'
              },
              animationDelay: 2 * 1000
          },{
              data: (this.park2.list || []).map(item => item.cars),
              type: 'line',
              smooth: true,
              name: '时光码头停车场',
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: 'transparent',
              },
              lineStyle: {
                color: 'rgba(0, 148, 255, 1)'
              },
              areaStyle: {
                color: 'rgba(0, 148, 255, 0.5)'
              },
              animationDelay: 2 * 1000
          }]
      }
      this.chart.setOption(this.option)
    },
    // 向购物车添加的动画
    beforeDrop (el) {
      inner.style.width = 0
    },
    dropping (el, done) {
      inner.style.width = 0
    },
    afterDrop (el) {
      this.ball.show = false
    }
  }
};
</script>
<style lang="scss" scoped>

.monitor {
  @keyframes bounceIn {
    0% {
      bottom: -300px
    }
    100% {
      bottom: 20px
    }
  }
  @keyframes bounceOut {
    0% {
      bottom: 20px
    }
    100% {
      bottom: -300px
    }
  }
  &.hide {
    animation: bounceOut 2s;
    bottom: -500px
  }
  animation: bounceIn 2s;
  margin-right: 10px;
  background: url("../../assets/images/park/monitor_bg@2x.png") no-repeat;
  background-size: contain;
  width: 1038px;
  height: 281px;
  position: absolute;
  z-index: 9999;
  left: 440px;
  bottom: 20px;
  padding-top: 18px;
  padding-left: 20px;
  .title {
    padding-right: 18px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    font-family: 'MicrosoftYaHei-Bold'
  }
  .park_name {
    cursor: pointer;
    width: 101px;
    height: 51px;
    line-height: 51px;
    font-family: 'MicrosoftYaHei';
    font-size: 12px;
    color: #83A5E1;
    background-size: contain;
    background-image: url("../../assets/images/park/park_num@2x.png");
  }
  .park_name_one {
    width: 102px;
    margin: 0 8px 0 9px;
    font-weight: bold;
    background-position-y: center;
    padding-right: 7px;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/park/luci@2x.png");
  }
  .park_name_two {
    padding-left: 15px;
    width: 128px;
    background-position-y: center;
    color: rgba(255,255,255,0.4);
    font-weight: bold;
    background-repeat: no-repeat;
    padding-right: 20px;
    background-image: url("../../assets/images/park/timeing@2x.png");
  }
  .park_name_one_active {
    width: 128px;
    font-weight: bold;
    margin-right: 0px;
    padding-right: 15px;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/park/active@2x.png");
  }
  .park_name_two_active {
    padding-left: 15px;
    width: 146px;
    background-position-y: center;
    color: rgba(255,255,255,0.4);
    font-weight: bold;
    background-repeat: no-repeat;
    padding-right: 20px;
    margin-right: -9px;
    background-image: url("../../assets/images/park/timeing_active@2x.png");
  }
  .park_name_three {
    width: 82px;
    margin-left: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255,255,255,0.4);
    background-position-y: center;
    font-weight: bold;
    background-repeat: no-repeat;
    padding-right: 5px;
    background-image: url("../../assets/images/park/building@2x.png");
  }
  .park_name_active {
    font-family: 'MicrosoftYaHei-Bold';
    color: #fff;
    font-size: 12px;
  }

  .parks, .park {
    display: flex;
  }
  .types {
    margin-left: 10px;
    justify-content: space-between;
    .item {
      display: flex;
      align-items: center;
      margin-top: 12px;
      .name {
        font-size: 16px;
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: #CFDBE9;
        width: 90px;
        text-align: left;
      }
      .num {
        font-family: 'DIN';
        font-weight: 400;
        width: 60px;
        text-align: left;
        .unit {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #9AAABE;
          margin-left: 5px;
        }
      }
      .process {
        width: 188px;
        height: 4px;
        background: #1E4374;
        border-radius: 2px;
        @keyframes progressIn {
          0% {
            width: 0
          }
        }
        .process_bar {
          height: 4px;
          border-radius: 2px;
          &.process_bar_active {
            animation: progressIn 5s linear;
          }
        }
      }
    }
  }
 .park_last {
   .average_duration {
     height: 117px;
     background-size: contain;
     background-repeat: no-repeat;
     width: 131px;
     padding-top: 25px;
     background-image: url("../../assets/images/park/average_duration@2x.png");
     @keyframes fadeIn {
       0% {
         opacity: 1
       }
       60% {
         opacity: 0.6
       }
       100% {
         opacity: 1
       }
     };
     opacity: 0.6;
     animation: fadeIn 6s linear infinite;
     .num {
       font-size: 24px;
       font-family: 'MicrosoftYaHei-Bold';
       font-weight: bold;
       color: #fff;
     }
     .unit {
       font-size: 16px;
       font-family: 'Microsoft YaHei';
       font-weight: 400;
       color: #fff;
     }
   }
   .park_last_title {
     font-family: 'MicrosoftYaHei';
     font-size: 16px;
     color: #B8D0F5;
   }
 }

 .right {
    width: 50%;
    position: relative;
    height: 211px;
    .legend {
      position: absolute;
      right: 255px;
      top: 30px;
      width: 14px;
      height: 2px;
      border-radius: 1px;
    }
    .legend_left {
      right: 255px;
      top: 30px;
      background: rgba(255, 241, 0, 1)
    }
    .legend_right {
      right: 140px;
      top: 30px;
      background: rgba(32, 184, 237, 1);
    }
 }
}


</style>
