<template>
  <div id="app">
    <!-- <div class="mark"/> -->
        <iframe class="main-bg" v-if="isOpen" ref="iframe" src="OverallViewWeb/"/>
    <router-view/>
  </div>
</template>
<script>
export default {
   data() {
    return {
      isOpen: true,
    };
  },
  mounted() {
    this.$page.index = this;
    var times = setInterval(() => {
      if (this.$refs.iframe.contentWindow.isLoading) {
        clearInterval(times)
        window.iframe = this.$refs.iframe
      }

    }, 1000)

  }
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
}

#app {
  font-family: Microsoft YaHei, 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  min-height: 1040px;
  width: 1920px;
  position: relative;
  background: rgba(136, 179, 255, 0.6);
}

#nav {
  padding: 30px;
  font-size: 16px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.container {
  position: relative;
  width: 1920px;
  margin: 0 auto;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  // padding-left: 16px;
  padding-right: 16px;
}

.card-title {
  font-size: 16px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 16px;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.mark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10;
  background: rgba(0, 9, 18, 0.5);
}

.main-bg {
  position: absolute;
  height: 100%;
  min-height: 1040px;
  width: 1920px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  border: none;
}

</style>
